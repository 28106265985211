import React, { createContext, useContext, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import { v4 as uuidv4 } from 'uuid'

import { TicketOffice } from '../interfaces/ticketOffice'
import { WebSocketResponse } from '../Pages/PainelDeSenhas/types/ws'
import toastMessage from '../utils/handleToastMessage'

interface WebSocketContextProps {
  wsResponse: WebSocketResponse | null
  setWsResponseToNull: () => void
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(
  undefined,
)

export interface Props {
  children: React.ReactNode
}

const url = process.env.REACT_APP_WS_URL || ''
const hostname = `painel-de-senhas-${uuidv4()}`

export const WebSocketProvider: React.FC<Props> = ({ children }) => {
  const [wsResponse, setMessage] = useState<WebSocketResponse | null>(null)

  let guicheParsed: TicketOffice
  const guiche = sessionStorage.getItem('gov_ticket_office')
  // eslint-disable-next-line prefer-const
  if (guiche) {
    guicheParsed = JSON.parse(guiche)
  } else {
    guicheParsed = {} as TicketOffice
  }

  useWebSocket(url, {
    queryParams: {
      hostname,
      room_name: guicheParsed.setor
        ? guicheParsed.setor.unidade.slug_unidade
        : guicheParsed.unidade
        ? guicheParsed.unidade.slug_unidade
        : '',
    },
    onOpen() {
      console.log('WebSocket: Painel de senhas conectado!')
    },
    onClose: () => {
      console.log('WebSocket: Painel de senhas desconectado!')
    },
    onError: (event) => {
      console.error('onError:', event)
      toastMessage({
        type: 'warning',
        message: 'Problemas na conexão com WebSocket.',
      })
    },
    onMessage: (event) => {
      const wsResponse: WebSocketResponse = JSON.parse(event.data)
      setMessage(wsResponse)
      console.log('WebSocket: Painel de senhas: ', wsResponse)
    },
    shouldReconnect: (closeEvent) => {
      console.log('WebSocket: Painel de senhas: ', closeEvent)
      return true
    },
    reconnectAttempts: 99,
    reconnectInterval: (attemptNumber) => {
      toastMessage({
        type: 'info',
        message: 'WebSocket: Reconectando painel',
      })
      return Math.min(Math.pow(2, attemptNumber) * 7000, 10000)
    },
    share: false,
  })

  const setWsResponseToNull = () => setMessage(null)

  return (
    <WebSocketContext.Provider value={{ wsResponse, setWsResponseToNull }}>
      {children}
    </WebSocketContext.Provider>
  )
}

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext)
  if (context === undefined) {
    throw new Error(
      'useWebSocketContext must be used within a WebSocketProvider',
    )
  }
  return context
}
