export const abreviarNome = (nome: string, limite = 20): string => {
  if (nome.length <= limite) {
    return nome.toUpperCase()
  }

  const nomes = nome.split(' ')
  if (nomes.length === 1) {
    return nome.toUpperCase()
  }

  const primeiroNome = nomes[0]
  const ultimoNome = nomes[nomes.length - 1]
  const nomesDoMeio = nomes
    .slice(1, -1)
    .map((n) => `${n.charAt(0)}.`)
    .join(' ')

  const nomeAbreviado = `${primeiroNome} ${nomesDoMeio} ${ultimoNome}`

  return nomeAbreviado.length <= limite
    ? nomeAbreviado.toUpperCase()
    : `${primeiroNome} ${ultimoNome}`.toUpperCase()
}
