import DateFnsUtils from '@date-io/date-fns'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Link from '@material-ui/core/Link'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import ptBR from 'date-fns/locale/pt-BR'
import { useCallback, useEffect, useState } from 'react'
import React from 'react'

import { Schedule, ScheduleUser } from '../../../../interfaces/agendamento'
import { Atendimento } from '../../../../interfaces/atendimento'
import { ServicoInterno } from '../../../../interfaces/servico'
import {
  agendarInterno,
  checarHorariosInternos,
} from '../../../../services/agendamento'
import { incluirDependente } from '../../../../services/agendamentoInterno'
import { sendReply } from '../../../../services/atendimento'
import {
  getInterService,
  getUnitInterService,
} from '../../../../services/servicos'
import { dateFormat } from '../../../../utils/dateFormat'
import useStyles from './style'

interface Props {
  openModal: boolean
  setOpenModal: (openModal: boolean) => void
  attendence: undefined | Atendimento
}

export default function AttendanceNewScheduleInternal({
  openModal,
  setOpenModal,
  attendence,
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()
  const [interServices, setInterServices] = useState<
    undefined | ServicoInterno[]
  >([])

  const [sectorUnits, setSectorUnits] = useState<
    undefined | { nome: string; slug_unidade: string }[]
  >([])
  const [attendenceSelected, setScheduleSelected] = useState<string>('')

  const [serviceSelected, setServiceSelected] = useState<
    undefined | ServicoInterno
  >()
  const [loadingUnidade, setLoadingUnidade] = useState<boolean>(false)
  const [selectedDate, handleDateChange] = useState<Date>(new Date())
  const [unitySelected, setUnitySelected] = useState<string>('')

  const [attendenceService, setScheduleService] = useState<
    string | Schedule | undefined
  >()
  const [attendenceServiceUser, setScheduleServiceUser] = useState<
    ScheduleUser | string | undefined
  >()

  const disableWeekends = (date: MaterialUiPickersDate): boolean =>
    date
      ? date.getDay() === 0 ||
        date.getDay() === 6 ||
        format(date, 'yyyy-MM-dd') === '2021-02-16'
      : false
  const handleClose = () => {
    setOpenModal(false)
  }

  const interServiceToService = useCallback(async (id) => {
    try {
      setLoading(true)
      const { data } = await getInterService(
        process.env.REACT_APP_API_SISECI_KEY ?? '',
        id,
      )
      if (data) {
        setInterServices(data || [])
      }
      console.log('inter services', data)
    } catch (error) {
      console.log('Erro', error)
    } finally {
      setLoading(false)
    }
  }, [])

  const getUnitLists = async (id: number) => {
    try {
      setLoadingUnidade(true)
      setLoading(true)
      const { data } = await getUnitInterService(
        process.env.REACT_APP_API_SISECI_KEY ?? '',
        id,
      )

      setSectorUnits(data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingUnidade(false)
      setLoading(false)
    }
  }

  const handleChangeService = async (event: any) => {
    setSectorUnits(undefined)

    const serviceFilter: any = interServices?.filter(
      ({ id }) => id === parseInt(event.target.value),
    )

    if (serviceFilter[0]) {
      setServiceSelected(serviceFilter[0])
      await getUnitLists(serviceFilter[0].id)
    }
  }

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined)
    setScheduleServiceUser(undefined)
    if (isValid(date) && serviceSelected) {
      try {
        const { data } = await checarHorariosInternos(
          date,
          unity,
          serviceSelected.id,
        )
        setScheduleService(data)
      } catch (err: any) {
        if (err?.response) {
          setScheduleService(err.response.data.message)
        } else {
          setScheduleService('Algo inesperado ocorreu')
        }
      }
    } else {
      setScheduleService(undefined)
    }
  }

  const handleSchedulerService = async () => {
    setLoading(true)
    const token: string | null = sessionStorage.getItem('gov_access_token_sso')
    if (token && attendence && serviceSelected) {
      try {
        const { data } = await agendarInterno(
          token,
          attendence.user.username,
          attendence.user.id,
          selectedDate,
          unitySelected,
          serviceSelected.id,
          attendenceSelected,
        )
        if (data) {
          await sendReply(
            token,
            attendence.id,
            `
              <h4>Novo Agendamento</h4>
              <strong>SERVIÇO: </strong> ${data.servico_titulo}<br/>
              <strong>CPF: </strong> ${data.cpf}<br/>
              <strong>Data: </strong> ${dateFormat(data.data)}<br/>
              <strong>Hora: </strong> ${data.hora}<br/>
          `,
            false,
          )

          if (attendence.para_dependente && attendence?.dependentes) {
            await incluirDependente(
              data.id,
              attendence.user.username,
              attendence.dependentes.nome,
              new Date(attendence.dependentes.data_nascimento),
              attendence.dependentes.cpf,
              attendence.dependentes.grau_parentesco,
              token,
            )
          }
          setOpenModal(false)
        }
      } catch (err: any) {
        if (err?.response?.data?.message) {
          setScheduleServiceUser(err.response.data.message)
        } else {
          setScheduleServiceUser('Algo inesperado ocorreu.')
        }
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (attendence?.servico && attendence.servico.id) {
      interServiceToService(attendence.servico.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendence])

  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Novo agendamento interno
        </Typography>
      </DialogTitle>
      {attendence && (
        <DialogContent>
          {interServices && interServices.length && (
            <>
              <div id="input-servico">
                <FormControl
                  classes={{ root: classes.root }}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Selecione o serviço
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={handleChangeService}
                    label="Selecione o serviço"
                    classes={{
                      select: classes.menuItem,
                    }}
                  >
                    {interServices &&
                      interServices.map((service) => (
                        <MenuItem
                          key={service.slug}
                          className={classes.menuItem}
                          value={service.id}
                        >
                          {service.titulo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div id="input-unidade">
                <FormControl
                  classes={{ root: classes.root }}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Selecione a unidade
                  </InputLabel>
                  {loadingUnidade ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(event) => {
                          setUnitySelected(event.target.value as string)
                          getSchedulers(
                            selectedDate,
                            event.target.value as string,
                          )
                        }}
                        label="Selecione a unidade"
                        classes={{
                          select: classes.menuItem,
                        }}
                        disabled={!sectorUnits}
                      >
                        {sectorUnits &&
                          sectorUnits.map((unidade) => (
                            <MenuItem
                              key={unidade.slug_unidade}
                              className={classes.menuItem}
                              value={unidade.slug_unidade}
                            >
                              {unidade.nome}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </div>

              <div id="input-data">
                <FormControl
                  classes={{ root: classes.root }}
                  error={
                    typeof attendenceService === 'string' ||
                    typeof attendenceServiceUser === 'string'
                  }
                  disabled={!unitySelected && !sectorUnits}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture={false}
                      error={
                        typeof attendenceService === 'string' ||
                        typeof attendenceServiceUser === 'string'
                      }
                      shouldDisableDate={disableWeekends}
                      minDateMessage="Você não pode agendar em dias anteriores"
                      invalidDateMessage="A data informada é inválida"
                      minDate={new Date()}
                      InputProps={{
                        className: classes.menuItem,
                        classes: { adornedEnd: classes.adornedEnd },
                      }}
                      autoOk
                      disabled={loading}
                      variant="inline"
                      inputVariant="outlined"
                      label="Selecione o dia"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputAdornmentProps={{ position: 'end', color: '#000' }}
                      onChange={(date) => {
                        if (date && isValid(date)) {
                          handleDateChange(date)
                          if (unitySelected) {
                            getSchedulers(date, unitySelected)
                          }
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {typeof attendenceService === 'string' && (
                    <FormHelperText>{attendenceService}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <div id="input-horario">
                <FormControl
                  classes={{ root: classes.root }}
                  disabled={
                    !unitySelected ||
                    !selectedDate ||
                    typeof attendenceService === 'string'
                  }
                  variant="outlined"
                  className={classes.formControl}
                  error={typeof attendenceServiceUser === 'string'}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Escolha o horário
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    classes={{
                      select: classes.menuItem,
                    }}
                    onChange={(event): void => {
                      setScheduleSelected(event.target.value as string)
                    }}
                    label="Escolha o horário"
                  >
                    {typeof attendenceService !== 'string' &&
                      attendenceService?.horarios.map((attendence) => (
                        <MenuItem
                          key={attendence}
                          className={classes.menuItem}
                          value={attendence}
                        >
                          {attendence}
                        </MenuItem>
                      ))}
                  </Select>
                  {typeof attendenceServiceUser === 'string' && (
                    <FormHelperText>{attendenceServiceUser}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <Divider light />
              <div className={classes.buttonCloseModal}>
                {!loading && (
                  <Link className={classes.link} onClick={handleClose}>
                    fechar
                  </Link>
                )}
                <Button
                  style={{ color: '#FFF', fontSize: 14 }}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={handleSchedulerService}
                  disabled={
                    loading ||
                    !unitySelected ||
                    !selectedDate ||
                    typeof attendenceService === 'string' ||
                    typeof attendenceService === 'undefined' ||
                    !attendenceSelected
                  }
                >
                  {!loading ? 'AGENDAR' : <CircularProgress />}
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      )}
    </Dialog>
  )
}
