/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AlarmOff from '@material-ui/icons/AlarmOff'
import ArrowRight from '@material-ui/icons/ArrowRight'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import ListAlt from '@material-ui/icons/ListAlt'
import LockOpen from '@material-ui/icons/LockOpen'
import Menu from '@material-ui/icons/Menu'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import People from '@material-ui/icons/People'
import PermPhoneMsg from '@material-ui/icons/PermPhoneMsg'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Print from '@material-ui/icons/Print'
import Visibility from '@material-ui/icons/Visibility'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import { v4 as uuidv4 } from 'uuid'

import { TableRowCheckTime } from '../../../../Components'
import PaginationService from '../../../../Components/PaginationService'
import { Agendamento } from '../../../../interfaces/agendamento'
import { useStateValue } from '../../../../providers/StateProvider'
import { cpfMask } from '../../../../utils/cpfFormat'
import dateFormat from '../../../../utils/dateFormat'
import toastMessage from '../../../../utils/handleToastMessage'
import { WebSocketResponse } from '../../../PainelDeSenhas/types/ws'
import { StyledTableCell } from '../../style'
import { useSchedules } from '../../useSchedules'
import AttendanceDialog from '../AttendanceDialog'
import AttendanceDialogCancel from '../AttendanceDialogCancel'
import AttendanceDialogStop from '../AttendanceDialogCancelStop'
import DependentsDialog from '../DependentsDialog'
import Modal156 from '../Modal156'
import Modal156ListDemand from '../Modal156ListDemand'
import ModalFormFlow from '../ModalFormFlow'
import ModalFormFlowDetails from '../ModalFormFlowDetails'
import ModalFreeze from '../ModalFreeze'
import ModalPause from '../ModalPause'
import SchedulingFilter from '../SchedulingFilter'
import TabAllAttendance from '../TabAllAttendance'
import TabAttendanceToday from '../TabAttendanceToday'
import TabOldsAttendance from '../TabOldsAttendance'
import Timer from '../Timer'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: any
  value: any
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const url = process.env.REACT_APP_WS_URL || ''
const hostname = `guiche-painel-atendimento-${uuidv4()}`

export default function SchedulesContent(): JSX.Element {
  const [{ currentTicketOffice }] = useStateValue()

  const {
    classes,
    currentSchedule,
    setSchedulesDependentCurrent,
    setOpenDependentsDialog,
    setCurrentSchedule,
    setOpenAttendanceDialog,
    openAttendanceCancel,
    setOpenAttendanceCancel,
    openAttendanceStop,
    setOpenAttendanceStop,
    history,
    openDependentsDialog,
    schedulesDependentCurrent,
    openAttendanceDialog,
    matchesMobile,
    value,
    handleChange,
    anchorElMenu,
    handleCloseMenu,
    handleChangeIndex,
    handleClick,
    dataSchedulesNext,
    getHrefComprovante,
    handleCallPass,
    callingPass,
    remainingTime,
    handleUnlockPass,
    callingUnlockPass,
    setCallingPass,
    setRemainingTime,
    // agendamentosHoje,
    agendamentosDaVezHoje,
    addUrlSearchParams,
    loadingSchedulesNext,
    passId,
    inPanel,
    setInPanel,
    setPassId,
  } = useSchedules()
  const searchParams = new URLSearchParams(window.location.search)

  const tipo_prioridade = searchParams.get('tipo_prioridade')!
  const cpf = searchParams.get('cpf')!
  const nome = searchParams.get('nome')!
  const senha = searchParams.get('senha')!
  const status = searchParams.get('status')!
  const tipo = searchParams.get('tipo')!
  const page = searchParams.get('page')!

  const [open156, setOpen156] = useState(false)
  const [open156Demand, setOpen156Demand] = useState(false)
  const [openFormFlow, setOpenFormFlow] = useState(false)
  const [openFormFlowDetails, setOpenFormFlowDetails] = useState(false)
  const [openFreeze, setOpenFreeze] = useState(false)

  const [protocolSelected, setProtocolSelected] = useState('')
  const [protocolFormSelected, setProtocolFormSelected] = useState('')
  const [scheduleSelected, setScheduleSelected] = useState<
    undefined | Agendamento
  >()
  const [typeSelected, setTypeSelected] = useState('')
  const [cpfSelected, setCpfSelected] = useState('')
  const [cpfAtendenteSelected, setCpfAtendenteSelected] = useState('')
  const [slugServicoSelected, setSlugServicoSelected] = useState('')
  const [passInPanel, setPassInPanel] = useState<WebSocketResponse | null>(null)
  const [timerKey, setTimerKey] = useState(0)
  const [showTabs, setShowTabs] = useState<boolean>(false)

  // NÃO DELETAR ESSE EFFECT!
  // useEffect(() => {
  //   if (passInPanel && passInPanel.payload.agendamento_id === passId) {
  //     setInPanel(true)

  //     toastMessage({
  //       type: 'info',
  //       message: 'Chamando a senha!',
  //     })

  //     setTimeout(async () => {
  //       setTimerKey(timerKey + 1)
  //       await agendamentosDaVezHoje()
  //       setPassId('')
  //     }, 15000)

  //     let remainingTime = 15
  //     const interval = setInterval(() => {
  //       if (remainingTime > 0) {
  //         setRemainingTime(remainingTime - 1)
  //         remainingTime = remainingTime - 1
  //       } else {
  //         clearInterval(interval)
  //         setCallingPass(false)
  //         setInPanel(false)
  //       }
  //     }, 1000) // 1s
  //   }
  // }, [passInPanel])

  // DESATIVAR/APAGAR ESSE, CASO O DE CIMA SEJA ATIVADO
  useEffect(() => {
    if (callingPass) {
      let remainingTime = 15
      const interval = setInterval(async () => {
        if (remainingTime > 0) {
          setTimerKey(timerKey + 1)
          setRemainingTime(remainingTime - 1)
          remainingTime = remainingTime - 1
        } else {
          clearInterval(interval)
          await agendamentosDaVezHoje()
          setCallingPass(false)
          setInPanel(false)
          setTimerKey(0)
        }
      }, 1000) // 1s
      return () => clearInterval(interval)
    }
  }, [callingPass])

  const handleOpen156 = (protocol: string, cpf: string, type: string) => {
    setOpen156(true)
    setProtocolSelected(protocol)
    setCpfSelected(cpf)
    setTypeSelected(type)
  }

  const handleOpenFormFlow = (schedule: Agendamento) => {
    setProtocolSelected(schedule.protocolo || '')
    setSlugServicoSelected(schedule.servico.slug || '')
    setCpfSelected(schedule.user?.username || schedule.cpf || '')
    setOpenFormFlow(true)
  }

  const handleOpenFormFlowDetails = (schedule: Agendamento) => {
    setProtocolSelected(schedule.protocolo || '')
    setProtocolFormSelected(schedule.protocolo_formflow || '')
    setOpenFormFlowDetails(true)
  }

  const handleOpen156Demanda = (schedule: Agendamento) => {
    setProtocolSelected(schedule.protocolo || '')
    setCpfAtendenteSelected(schedule.atendente_id || '')
    setOpen156Demand(true)
  }

  const handleOpenFreeze = (schedule: Agendamento) => {
    setOpenFreeze(true)
    setScheduleSelected(schedule)
  }

  useWebSocket(url, {
    queryParams: {
      hostname,
      room_name: 'confirmacao-chamada-senha',
    },
    onOpen() {
      console.log('WebSocket de Confirmação: Conectado')
    },
    onClose: () => {
      console.log('Painel desconectado!')
    },
    onError: (event) => {
      console.error('WebSocket de Confirmação:', event)
    },
    onMessage: (event) => {
      const wsResponse: WebSocketResponse = JSON.parse(event.data)
      console.log('WebSocket de Confirmação:', wsResponse)
      setPassInPanel(wsResponse)
    },
    shouldReconnect: (closeEvent) => {
      console.log('WebSocket de Confirmação: ', closeEvent)
      return true
    },
    reconnectAttempts: 99,
    reconnectInterval: (attemptNumber) => {
      console.log('WebSocket de Confirmação: Reconectando painel')
      return Math.min(Math.pow(2, attemptNumber) * 1000, 10000)
    },
    share: false,
  })

  const setNewPage = async (newPage: number) => {
    await agendamentosDaVezHoje({
      page: Number(newPage),
      tipo_prioridade,
      cpf,
      nome,
      senha,
      status,
      tipo,
    })
  }

  useEffect(() => {
    if (!dataSchedulesNext) {
      const interval = setInterval(() => {
        agendamentosDaVezHoje({
          page: Number(page) || 1,
          tipo_prioridade,
          cpf,
          nome,
          senha,
          status,
          tipo,
        })
      }, 120000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [
    agendamentosDaVezHoje,
    cpf,
    dataSchedulesNext,
    nome,
    page,
    searchParams,
    senha,
    status,
    tipo,
    tipo_prioridade,
  ])

  useEffect(() => {
    agendamentosDaVezHoje()
  }, [])

  return (
    <Box className={classes.main}>
      <Box
        style={{
          overflow: 'auto',
        }}
      >
        <Paper className={classes.root}>
          <ModalPause />
          {/* SE O PRIMEIRO EFFET FOR DESCOMENTADO E O SEGUNDO DELETADO */}
          {/* DESCOMENTAR ESSE TRECHO DE CÓDIGO */}
          {/* {callingPass && inPanel && ( */}
          {callingPass && (
            <Alert color="warning">
              <Box>
                Aguarde{' '}
                <strong>
                  <Timer key={timerKey} startTimer={callingPass} />s
                </strong>
                , Para chamar novamente ou liberar a senha!
              </Box>
            </Alert>
          )}

          {/* SE O PRIMEIRO EFFET FOR DESCOMENTADO E O SEGUNDO DELETADO */}
          {/* DESCOMENTAR ESSE TRECHO DE CÓDIGO */}
          {/* {callingPass && !inPanel && (
            <Alert color="success">
              <Box>Aguarde a senha ser exibida no painel.</Box>
            </Alert>
          )} */}

          <Box className={classes.tableDatail}>Agendamento da vez </Box>
          <Box style={{ overflow: 'auto', paddingBottom: 10 }}>
            <SchedulingFilter />

            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>CIDADÃO/CPF</StyledTableCell>
                  <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                  <StyledTableCell align="left">TIPO</StyledTableCell>
                  <StyledTableCell align="left">STATUS</StyledTableCell>
                  <StyledTableCell align="left">SERVIÇO</StyledTableCell>
                  <StyledTableCell align="left">
                    SENHA / PRIORIDADE
                  </StyledTableCell>

                  <StyledTableCell align="left">AÇÕES</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingSchedulesNext && (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {dataSchedulesNext &&
                  dataSchedulesNext.results.length > 0 &&
                  dataSchedulesNext.results.map(
                    (currentSchedule: Agendamento) => (
                      <TableRowCheckTime
                        data={currentSchedule.data}
                        hora={currentSchedule.hora}
                        status={currentSchedule.status}
                        key={currentSchedule.cpf}
                      >
                        <StyledTableCell component="th" scope="row">
                          {currentSchedule?.user?.first_name ||
                            currentSchedule?.nome}
                          <Box>
                            <strong>
                              {cpfMask(
                                currentSchedule?.user?.username ||
                                  currentSchedule.cpf,
                              )}
                            </strong>
                          </Box>
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {`${dateFormat(currentSchedule.data)} ${
                            currentSchedule.hora
                          }`}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {currentSchedule.tipo}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.status}
                          align="left"
                        >
                          {currentSchedule.status}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {currentSchedule.servico.titulo}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {currentSchedule.senha_formatada} (
                          {!currentSchedule?.prioridade &&
                          !currentSchedule.prioridade_alta
                            ? 'Normal'
                            : currentSchedule.prioridade_alta
                            ? 'Alta Prioridade'
                            : 'Prioridade'}
                          )
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {currentSchedule.para_dependente &&
                            currentSchedule.dependentes && (
                              <Tooltip
                                title={`${
                                  callingPass && inPanel
                                    ? `Ativação em ${remainingTime}s`
                                    : callingPass
                                    ? 'Chamando senha'
                                    : 'Dependentes'
                                }`}
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    className={classes.buttonIcon}
                                    onClick={() => {
                                      setSchedulesDependentCurrent(
                                        currentSchedule,
                                      )
                                      setOpenDependentsDialog(true)
                                    }}
                                    disabled={
                                      (callingPass && inPanel) || callingPass
                                    }
                                  >
                                    <People />
                                  </Button>
                                </span>
                              </Tooltip>
                            )}

                          {!currentSchedule.para_dependente && (
                            <Tooltip
                              title={`${
                                callingPass && inPanel
                                  ? `Ativação em ${remainingTime}s`
                                  : callingPass
                                  ? 'Chamando senha'
                                  : 'Sem dependentes'
                              }`}
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={classes.buttonIcon}
                                  disabled
                                >
                                  <People />
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                          {(currentSchedule.status === 'Aguardando' ||
                            currentSchedule.status === 'Encaminhado' ||
                            currentSchedule.status === 'Compareceu') &&
                            (currentSchedule.senha_formatada ||
                              currentSchedule.tipo === 'Online') && (
                              <>
                                <Tooltip
                                  title={`${
                                    callingPass && inPanel
                                      ? `Ativação em ${remainingTime}s`
                                      : callingPass
                                      ? 'Chamando senha'
                                      : 'Iniciar atendimento'
                                  }`}
                                >
                                  <span>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      className={classes.buttonIcon}
                                      onClick={() => {
                                        setCurrentSchedule(currentSchedule)
                                        setOpenAttendanceDialog(true)
                                      }}
                                      disabled={
                                        (callingPass && inPanel) || callingPass
                                      }
                                    >
                                      <PlayArrow />
                                    </Button>
                                  </span>
                                </Tooltip>
                              </>
                            )}

                          {currentSchedule.status === 'Em Andamento' &&
                            currentSchedule.tipo !== 'Online' &&
                            !currentSchedule?.congelado && (
                              <>
                                <Tooltip
                                  title={`${
                                    callingPass && inPanel
                                      ? `Ativação em ${remainingTime}s`
                                      : callingPass
                                      ? 'Chamando senha'
                                      : 'Finalizar atendimento'
                                  }`}
                                >
                                  <span>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      className={classes.buttonIcon}
                                      onClick={() => {
                                        setCurrentSchedule(currentSchedule)
                                        setOpenAttendanceStop(true)
                                      }}
                                      disabled={
                                        (callingPass && inPanel) || callingPass
                                      }
                                    >
                                      <CheckCircleOutline />
                                    </Button>
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          {currentSchedule.status === 'Em Andamento' &&
                            currentSchedule.tipo === 'Online' && (
                              <>
                                <Tooltip
                                  title={`${
                                    callingPass && inPanel
                                      ? `Ativação em ${remainingTime}s`
                                      : callingPass
                                      ? 'Chamando senha'
                                      : 'Voltar para atendimento'
                                  }`}
                                >
                                  <span>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      className={classes.buttonIcon}
                                      onClick={() =>
                                        history.push(
                                          `/atendimento/${currentSchedule.id}`,
                                        )
                                      }
                                      disabled={
                                        (callingPass && inPanel) || callingPass
                                      }
                                    >
                                      <ArrowRight />
                                    </Button>
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          <Tooltip
                            title={`${
                              callingPass && inPanel
                                ? `Ativação em ${remainingTime}s`
                                : callingPass
                                ? 'Chamando senha'
                                : 'Comprovante Agendamento'
                            }`}
                          >
                            <span>
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                component="a"
                                href={getHrefComprovante(
                                  currentSchedule.ticket,
                                )}
                                target="_blank"
                                className={classes.buttonIcon}
                                disabled={
                                  (callingPass && inPanel) || callingPass
                                }
                              >
                                <Print />
                              </Button>
                            </span>
                          </Tooltip>
                          {(currentSchedule.status === 'Compareceu' ||
                            currentSchedule.status === 'Encaminhado') &&
                            currentSchedule.tipo !== 'Online' &&
                            (!currentSchedule?.guiche ||
                              currentSchedule?.guiche?.id ===
                                currentTicketOffice?.id) && (
                              <Tooltip
                                title={`${
                                  callingPass && inPanel
                                    ? `Ativação em ${remainingTime}s`
                                    : callingPass
                                    ? 'Chamando senha'
                                    : 'Chamar Senha'
                                }`}
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      handleCallPass(
                                        currentSchedule.id as string,
                                      )
                                    }
                                    className={classes.buttonIcon}
                                    disabled={
                                      (callingPass && inPanel) || callingPass
                                    }
                                  >
                                    <NotificationsActiveIcon
                                      style={{ fontSize: 25 }}
                                    />
                                  </Button>
                                </span>
                              </Tooltip>
                            )}
                          {(currentSchedule.status === 'Compareceu' ||
                            currentSchedule.status === 'Encaminhado') &&
                            currentSchedule.tipo !== 'Online' &&
                            currentSchedule?.guiche?.id ===
                              currentTicketOffice?.id && (
                              <Tooltip
                                title={`${
                                  callingPass && inPanel
                                    ? `Ativação em ${remainingTime}s`
                                    : callingPass
                                    ? 'Chamando senha'
                                    : 'Liberar Senha!'
                                }`}
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      handleUnlockPass(
                                        currentSchedule.id as string,
                                      )
                                    }
                                    className={classes.buttonIcon}
                                    disabled={
                                      (callingPass && inPanel) ||
                                      callingUnlockPass ||
                                      callingPass
                                    }
                                  >
                                    <LockOpen />
                                  </Button>
                                </span>
                              </Tooltip>
                            )}

                          {currentSchedule.status === 'Em Andamento' &&
                            currentSchedule.tipo !== 'Online' &&
                            !currentSchedule?.congelado && (
                              <Tooltip
                                title={`${
                                  callingPass && inPanel
                                    ? `Ativação em ${remainingTime}s`
                                    : callingPass
                                    ? 'Chamando senha'
                                    : 'Congelar Atendimento'
                                }`}
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    style={{
                                      backgroundColor: '#90caf9',
                                      color: 'rgb(255, 255, 255)',
                                    }}
                                    onClick={() =>
                                      handleOpenFreeze(currentSchedule)
                                    }
                                    className={classes.buttonIcon}
                                    disabled={
                                      (callingPass && inPanel) ||
                                      callingUnlockPass
                                    }
                                  >
                                    <AlarmOff />
                                  </Button>
                                </span>
                              </Tooltip>
                            )}
                          {currentSchedule.status === 'Em Andamento' && (
                            <Tooltip
                              title={`${
                                callingPass && inPanel
                                  ? `Ativação em ${remainingTime}s`
                                  : callingPass
                                  ? 'Chamando senha'
                                  : 'Nova demanda'
                              }`}
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    color: 'rgb(255, 255, 255)',
                                  }}
                                  onClick={() =>
                                    handleOpen156(
                                      currentSchedule.protocolo as string,
                                      currentSchedule.user?.username ||
                                        (currentSchedule.cpf as string),
                                      currentSchedule.tipo as string,
                                    )
                                  }
                                  className={classes.buttonIcon}
                                  disabled={
                                    (callingPass && inPanel) ||
                                    callingUnlockPass
                                  }
                                >
                                  <PermPhoneMsg />
                                </Button>
                              </span>
                            </Tooltip>
                          )}

                          {currentSchedule.status === 'Em Andamento' &&
                            currentSchedule.habilitar_formflow &&
                            !currentSchedule.protocolo_formflow && (
                              <Tooltip
                                title={`${
                                  callingPass && inPanel
                                    ? `Ativação em ${remainingTime}s`
                                    : callingPass
                                    ? 'Chamando senha'
                                    : 'Novo Formulário'
                                }`}
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    style={{
                                      color: 'rgb(255, 255, 255)',
                                    }}
                                    onClick={() =>
                                      handleOpenFormFlow(currentSchedule)
                                    }
                                    className={classes.buttonIcon}
                                    disabled={
                                      (callingPass && inPanel) ||
                                      callingUnlockPass
                                    }
                                  >
                                    <NoteAddIcon style={{ fontSize: 20 }} />
                                  </Button>
                                </span>
                              </Tooltip>
                            )}
                          {currentSchedule.status === 'Em Andamento' && (
                            <Tooltip title={`Não compareceu`}>
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  className={classes.buttonIconAttention}
                                  onClick={() => {
                                    setCurrentSchedule(currentSchedule)
                                    setOpenAttendanceCancel(true)
                                  }}
                                >
                                  <Icon className="material-icons-outlined">
                                    person_off
                                  </Icon>
                                </Button>
                              </span>
                            </Tooltip>
                          )}

                          {currentSchedule.status === 'Em Andamento' &&
                            currentSchedule.protocolo_formflow && (
                              <Tooltip
                                title={`${
                                  callingPass && inPanel
                                    ? `Ativação em ${remainingTime}s`
                                    : callingPass
                                    ? 'Chamando senha'
                                    : 'Detalhes Solicitação'
                                }`}
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    style={{
                                      color: 'rgb(255, 255, 255)',
                                    }}
                                    onClick={() =>
                                      handleOpenFormFlowDetails(currentSchedule)
                                    }
                                    className={classes.buttonIcon}
                                    disabled={
                                      (callingPass && inPanel) ||
                                      callingUnlockPass
                                    }
                                  >
                                    <Visibility />
                                  </Button>
                                </span>
                              </Tooltip>
                            )}

                          {(currentSchedule.status === 'Concluído' ||
                            currentSchedule.status === 'Congelado' ||
                            currentSchedule.status === 'Em Andamento') &&
                            currentSchedule.atendente_id?.length && (
                              <Tooltip title="Demandas do agendamento">
                                <span>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      handleOpen156Demanda(currentSchedule)
                                    }
                                    className={classes.buttonIcon}
                                  >
                                    <ListAlt />
                                  </Button>
                                </span>
                              </Tooltip>
                            )}
                        </StyledTableCell>
                      </TableRowCheckTime>
                    ),
                  )}
              </TableBody>
            </Table>
            <PaginationService
              getSearch={(page) => {
                setNewPage(page)
                addUrlSearchParams(page)
              }}
              listSearch={{
                count: dataSchedulesNext?.count,
                next: dataSchedulesNext?.next,
                previous: dataSchedulesNext?.previous,
                current: dataSchedulesNext?.current,
                total_pages: dataSchedulesNext?.total_pages,
              }}
            />
          </Box>
          <DependentsDialog
            setOpenDependentsDialog={setOpenDependentsDialog}
            openDependentsDialog={openDependentsDialog}
            schedule={schedulesDependentCurrent}
          />
          <AttendanceDialog
            setOpenAttendanceDialog={setOpenAttendanceDialog}
            openAttendanceDialog={openAttendanceDialog}
            schedule={currentSchedule}
          />
          <AttendanceDialogCancel
            setOpenAttendanceCancel={setOpenAttendanceCancel}
            openAttendanceCancel={openAttendanceCancel}
            schedule={currentSchedule}
          />
          <AttendanceDialogStop
            setOpenAttendanceStop={setOpenAttendanceStop}
            openAttendanceStop={openAttendanceStop}
            schedule={currentSchedule}
          />
        </Paper>
      </Box>
      <Typography className={classes.textSchedules}>
        Lista de agendamentos
      </Typography>
      <Box
        style={{
          overflow: 'auto',
          background: '#FFF',
        }}
      >
        {matchesMobile ? (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
            style={{
              borderBottom: '1px solid #F7F8FC',
              borderRadius: 4,
              marginLeft: 24,
            }}
          >
            <Tab
              style={{ padding: 0, marginRight: 48 }}
              label={
                <span className={classes.tabLabel}>
                  Agendamentos congelados
                </span>
              }
              {...a11yProps(0)}
            />
            {/* <Tab
              style={{ padding: 0 }}
              label={
                <span className={classes.tabLabel}>
                  Agendamentos Anteriores
                </span>
              }
              {...a11yProps(1)}
            />
            <Tab
              style={{ padding: 0 }}
              label={<span className={classes.tabLabel}>Todos</span>}
              {...a11yProps(2)}
            /> */}
          </Tabs>
        ) : (
          <>
            <IconButton onClick={(e): void => handleClick(e, 'menu')}>
              <Menu />
            </IconButton>
            {value === 0 && 'Próximo agendamentos hoje'}
            {value === 1 && 'Agendamentos Anteriores'}
            {value === 3 && 'Todos'}
            <Popover
              open={Boolean(anchorElMenu)}
              anchorEl={anchorElMenu}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(0)}
                >
                  <Typography className={classes.textMenuItem}>
                    Próximo agendamentos hoje
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(1)}
                >
                  <Typography className={classes.textMenuItem}>
                    Agendamentos Anteriores
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(2)}
                >
                  <Typography className={classes.textMenuItem}>
                    Todos
                  </Typography>
                </MenuItem>
              </Box>
            </Popover>
          </>
        )}
        <Box>
          {/* Hoje */}
          <TabAttendanceToday value={value} index={0} />
          {/* Anteriores */}
          {/* <TabOldsAttendance value={value} index={1} /> */}
          {/* Todos */}
          {/* <TabAllAttendance value={value} index={2} /> */}
        </Box>
      </Box>
      <Modal156
        open={open156}
        setOpen={setOpen156}
        protocol={protocolSelected}
        cpf={cpfSelected}
        tipo={typeSelected}
      />

      <Modal156ListDemand
        open={open156Demand}
        setOpen={setOpen156Demand}
        protocol={protocolSelected}
        cpf_atendente={cpfAtendenteSelected}
      />
      <ModalFormFlow
        open={openFormFlow}
        setOpen={setOpenFormFlow}
        protocol={protocolSelected}
        servico_slug={slugServicoSelected}
        cpf_cidadao={cpfSelected}
      />

      <ModalFormFlowDetails
        open={openFormFlowDetails}
        setOpen={setOpenFormFlowDetails}
        protocol={protocolSelected}
        protocol_form={protocolFormSelected}
      />

      {scheduleSelected && (
        <ModalFreeze
          open={openFreeze}
          setOpen={setOpenFreeze}
          agendamento={scheduleSelected}
        />
      )}
    </Box>
  )
}
