import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Event from '@material-ui/icons/Event'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GpsFixed from '@material-ui/icons/GpsFixed'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import Home from '@material-ui/icons/Home'
import Menu from '@material-ui/icons/Menu'
import MyLocation from '@material-ui/icons/MyLocation'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import React from 'react'
import { useHistory } from 'react-router'

import { useStateValue } from '../../providers/StateProvider'
import { logout } from '../../services/auth'
import { nameSplitOne, nameSplitTwo } from '../../utils/nameFormat'
import { useStyles } from './style'

export default function Header(): JSX.Element {
  const [{ user, currentTicketOffice }] = useStateValue()

  const classes = useStyles()
  const history = useHistory()

  const matchesMobile = useMediaQuery('(min-width:576px)')
  const [anchorElUser, setAnchorElUser] =
    React.useState<HTMLButtonElement | null>(null)
  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null)

  const goPage = (page: string) => {
    history.push(page)
    setAnchorElMenu(null)
  }
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: 'user' | 'menu',
  ) => {
    if (type === 'user') {
      setAnchorElUser(event.currentTarget)
    }
    if (type === 'menu') {
      setAnchorElMenu(event.currentTarget)
    }
  }
  const handleClose = () => {
    setAnchorElUser(null)
  }
  const handleCloseMenu = () => {
    setAnchorElMenu(null)
  }
  const logoutUser = () => {
    logout()

    setAnchorElUser(null)
  }
  const changeTicketOffice = () => {
    sessionStorage.removeItem('gov_ticket_office')
    history.push('/')
  }

  return (
    <Box className={classes.root}>
      {!matchesMobile && (
        <>
          <IconButton
            onClick={(e): void => handleClick(e, 'menu')}
            color="primary"
          >
            <Menu />
          </IconButton>
          <Popover
            open={Boolean(anchorElMenu)}
            anchorEl={anchorElMenu}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box>
              <MenuItem
                className={classes.menuItem}
                style={{ width: 250, wordBreak: 'break-word' }}
              >
                <GpsFixed color="primary" />
                <Typography
                  className={classes.textMenuItem}
                  style={{
                    width: 220,
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  GUICHÊ: {currentTicketOffice?.ordem || ''}{' '}
                  {currentTicketOffice?.nome || ''}{' '}
                  {currentTicketOffice?.setor &&
                    currentTicketOffice?.setor?.setor?.nome}{' '}
                  | UNIDADE:{' '}
                  {currentTicketOffice?.setor?.unidade?.nome ||
                    currentTicketOffice?.unidade?.nome}
                </Typography>
              </MenuItem>

              <Divider />

              <MenuItem
                className={classes.menuItem}
                style={{
                  color: '#053396',
                }}
                onClick={() => {
                  goPage('/home')
                }}
              >
                <Home />
                <Typography className={classes.textMenuItem}>Início</Typography>
              </MenuItem>
              <Divider />
              {currentTicketOffice &&
                currentTicketOffice.tipo === 'Presencial' && (
                  <MenuItem
                    className={classes.menuItem}
                    style={{
                      color: '#053396',
                    }}
                    onClick={() => goPage('/agendamento')}
                  >
                    <Event />
                    <Typography className={classes.textMenuItem}>
                      Atendimentos
                    </Typography>
                  </MenuItem>
                )}
              {currentTicketOffice && currentTicketOffice.tipo === 'Online' && (
                <MenuItem
                  className={classes.menuItem}
                  style={{
                    color: '#053396',
                  }}
                  onClick={() => goPage('/atendimento')}
                >
                  <Event />
                  <Typography className={classes.textMenuItem}>
                    Agendamentos
                  </Typography>
                </MenuItem>
              )}
              {currentTicketOffice &&
                currentTicketOffice.tipo === 'Interno' && (
                  <MenuItem
                    className={classes.menuItem}
                    style={{
                      color: '#053396',
                    }}
                    onClick={() => goPage('/agendamento_interno')}
                  >
                    <Event />
                    <Typography className={classes.textMenuItem}>
                      Atendimentos
                    </Typography>
                  </MenuItem>
                )}
              {currentTicketOffice &&
                currentTicketOffice.tipo === 'Consulta' && (
                  <MenuItem
                    className={classes.menuItem}
                    style={{
                      color: '#053396',
                    }}
                    onClick={() => goPage('/consulta_agendamento')}
                  >
                    <Event />
                    <Typography className={classes.textMenuItem}>
                      Atendimentos
                    </Typography>
                  </MenuItem>
                )}
              {currentTicketOffice && currentTicketOffice.tipo === 'Diario' && (
                <MenuItem
                  className={classes.menuItem}
                  style={{
                    color: '#053396',
                  }}
                  onClick={() => {
                    goPage('/atendimento_diario')
                  }}
                >
                  <Event />
                  <Typography className={classes.textMenuItem}>
                    Atendimentos
                  </Typography>
                </MenuItem>
              )}
            </Box>
          </Popover>
        </>
      )}

      <Box className={classes.textTicketOffice}>
        {matchesMobile ? (
          <Typography className={classes.textHeader}>
            GUICHÊ: {currentTicketOffice?.ordem || ''}{' '}
            {currentTicketOffice?.nome || ''}{' '}
            {currentTicketOffice?.setor &&
              currentTicketOffice?.setor?.setor?.nome}{' '}
            | UNIDADE:{' '}
            {currentTicketOffice?.setor?.unidade?.nome ||
              currentTicketOffice?.unidade?.nome}
          </Typography>
        ) : (
          <span
            style={{
              background: '#787878',
              color: '#fff',
              textTransform: 'uppercase',
              fontFamily: 'Roboto',
              marginRight: 10,
              padding: 5,
              fontSize: 13,
              borderRadius: 5,
            }}
          >
            {currentTicketOffice &&
              currentTicketOffice?.tipo === 'Diario' &&
              'Diário'}
            {currentTicketOffice &&
              currentTicketOffice?.tipo === 'Consulta' &&
              'Recepção'}
            {currentTicketOffice &&
              currentTicketOffice?.tipo !== 'Diario' &&
              currentTicketOffice?.tipo !== 'Consulta' &&
              currentTicketOffice?.tipo}
          </span>
        )}
      </Box>

      <Box className={classes.userMenus}>
        <Popover
          open={Boolean(anchorElUser)}
          anchorEl={anchorElUser}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box>
            {user && (
              <MenuItem
                style={{
                  backgroundColor: '#F3F6FA',
                  // minHeight: 73,
                }}
              >
                <Avatar className={classes.avatar} />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography className={classes.textName} color="primary">
                    {matchesMobile
                      ? nameSplitTwo(user?.nome?.toLowerCase())
                      : nameSplitOne(user?.nome?.toLowerCase())}
                  </Typography>
                  <Typography color="primary" className={classes.textCpf}>
                    {user.cpf}
                  </Typography>
                </Box>
              </MenuItem>
            )}
            <Divider />
            <MenuItem className={classes.menuItem}>
              <HeadsetMicIcon />
              <Typography className={classes.textMenuItem}>
                GUICHÊ: {currentTicketOffice?.ordem || ''}{' '}
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem className={classes.menuItem}>
              <MyLocation />
              <Typography className={classes.textMenuItem}>
                Unidade:{' '}
                {currentTicketOffice?.setor?.unidade?.nome ||
                  currentTicketOffice?.unidade?.nome}{' '}
              </Typography>
            </MenuItem>

            <Divider />
            <MenuItem className={classes.menuItem} onClick={changeTicketOffice}>
              <RadioButtonUncheckedIcon />
              <Typography className={classes.textMenuItem}>
                Trocar Guichê
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem className={classes.menuItem} onClick={logoutUser}>
              <ExitToAppIcon />
              <Typography className={classes.textMenuItem}>Sair</Typography>
            </MenuItem>
            <Divider />
          </Box>
        </Popover>
        {matchesMobile && (
          <span
            style={{
              background: '#787878',
              color: '#fff',
              textTransform: 'uppercase',
              fontFamily: 'Roboto',
              marginRight: 10,
              padding: 5,
              fontSize: 13,
              borderRadius: 5,
            }}
          >
            {currentTicketOffice &&
              currentTicketOffice?.tipo === 'Diario' &&
              'Diário'}
            {currentTicketOffice &&
              currentTicketOffice?.tipo === 'Consulta' &&
              'Recepção'}
            {currentTicketOffice &&
              currentTicketOffice?.tipo !== 'Diario' &&
              currentTicketOffice?.tipo !== 'Consulta' &&
              currentTicketOffice?.tipo}
          </span>
        )}

        {user && (
          <Button color="primary" onClick={(e): void => handleClick(e, 'user')}>
            <Avatar className={classes.avatar} />
          </Button>
        )}
      </Box>
    </Box>
  )
}
