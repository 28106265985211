import DateFnsUtils from '@date-io/date-fns'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import { useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AssignmentInd from '@material-ui/icons/AssignmentInd'
import Close from '@material-ui/icons/Close'
import Menu from '@material-ui/icons/Menu'
import Notifications from '@material-ui/icons/Notifications'
import People from '@material-ui/icons/People'
import Search from '@material-ui/icons/Search'
import SyncAltRounded from '@material-ui/icons/SyncAltRounded'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import ptBR from 'date-fns/locale/pt-BR'
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'

import PaginationService from '../../Components/PaginationService'
import { Atendimento, RespostaAtendimento } from '../../interfaces/atendimento'
import { useStateValue } from '../../providers/StateProvider'
import { getAtendimentos } from '../../services/atendimento'
import { cpfMask } from '../../utils/cpfFormat'
import dateFormat from '../../utils/dateFormat'
import {
  AttendanceDialog,
  AttendanceDialogCancel,
  DependentsDialog,
} from './components'
import { useStyles } from './style'
import { StyledTableCell, StyledTableRow } from './style'
interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export default function Attendances(): JSX.Element {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = useState(0)
  const [loadingNotStarted, setLoadingNotStarted] = useState<boolean>(false)
  const [loadingStarted, setLoadingStarted] = useState<boolean>(false)
  const [loadingFinished, setLoadingFinished] = useState<boolean>(false)
  const [loadingCancel, setLoadingCancel] = useState<boolean>(false)
  const [openAttendanceDialog, setOpenAttendanceDialog] =
    useState<boolean>(false)
  const [openAttendanceDialogCancel, setOpenAttendanceDialogCancel] =
    useState<boolean>(false)

  const [notStartedAttendance, setNotStartedAttendance] = useState<
    undefined | Atendimento[]
  >()
  const [currentAttendece, setCurrentAttendece] = useState<
    undefined | Atendimento
  >()

  const [countPageCurrentAttendance, setCountPageCurrentAttendance] =
    useState<number>(1)
  const [nextPageCurrentAttendance, setNextPageCurrentAttendance] =
    useState<number>(1)
  const [previousPageCurrentAttendance, setPreviousPageCurrentAttendance] =
    useState<number>(1)
  const [currentPageCurrentAttendance, setCurrentPageCurrentAttendance] =
    useState<number>(1)
  const [totalPagesCurrentAttendance, setTotalPagesCurrentAttendance] =
    useState<number>(1)

  const [startedAttendance, setStartedAttendance] = useState<
    undefined | Atendimento[]
  >()

  const [countPageStartedAttendance, setCountPageStartedAttendance] =
    useState<number>(1)
  const [nextPageStartedAttendance, setNextPageStartedAttendance] =
    useState<number>(1)
  const [previousPageStartedAttendance, setPreviousPageStartedAttendance] =
    useState<number>(1)
  const [currentPageStartedAttendance, setCurrentPageStartedAttendance] =
    useState<number>(1)
  const [totalPagesStartedAttendance, setTotalPagesStartedAttendance] =
    useState<number>(1)

  const [finishedAttendance, setFinishedAttendance] = useState<
    undefined | Atendimento[]
  >()
  const [cancelAttendance, setCancelAttendance] = useState<
    undefined | Atendimento[]
  >()

  const [countPageFinishedAttendance, setCountPageFinishedAttendance] =
    useState<number>(1)
  const [nextPageFinishedAttendance, setNextPageFinishedAttendance] =
    useState<number>(1)
  const [previousPageFinishedAttendance, setPreviousPageFinishedAttendance] =
    useState<number>(1)
  const [currentPageFinishedAttendance, setCurrentPageFinishedAttendance] =
    useState<number>(1)
  const [totalPagesFinishedAttendance, setTotalPagesFinishedAttendance] =
    useState<number>(1)
  const [countPageCancelAttendance, setCountPageCancelAttendance] =
    useState<number>(1)
  const [nextPageCancelAttendance, setNextPageCancelAttendance] =
    useState<number>(1)
  const [previousPageCancelAttendance, setPreviousPageCancelAttendance] =
    useState<number>(1)
  const [currentPageCancelAttendance, setCurrentPageCancelAttendance] =
    useState<number>(1)
  const [totalPagesCancelAttendance, setTotalPagesCancelAttendance] =
    useState<number>(1)
  const history = useHistory()

  const [openDependentsDialog, setOpenDependentsDialog] =
    useState<boolean>(false)

  const [{ user }] = useStateValue()
  const [selectedDate, handleDateChange] = useState<undefined | Date>(
    new Date(),
  )
  const [typeFilterNotStarted, setTypeFilterNotStared] = useState<
    null | string
  >('Todos')
  const [typeFilter, setTypeFilter] = useState<null | string>(null)

  const [valueFilter, setValueFilter] = useState<undefined | string>()
  const matchesMobile = useMediaQuery('(min-width:576px)')

  const getAttendanceNotStarted = async (page = 1) => {
    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      setLoadingNotStarted(true)
      const params: any = {
        status: 'Aguardando',
        hoje: false,
        guiche_id,
        page,
      }

      if (typeFilterNotStarted === 'Data' && selectedDate) {
        params['data'] = format(selectedDate, 'yyyy-MM-dd')
      }

      if (typeFilterNotStarted === 'CPF') {
        params['cpf'] = valueFilter?.replaceAll(/[^\w\s]/gi, '')
      }

      const { data } = await getAtendimentos(token || '', params)

      if (data) {
        setNotStartedAttendance(data.results)
        setCountPageCurrentAttendance(data.count)
        setNextPageCurrentAttendance(data.next)
        setPreviousPageCurrentAttendance(data.previous)
        setCurrentPageCurrentAttendance(data.current)
        setTotalPagesCurrentAttendance(data.total_pages)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingNotStarted(false)
    }
  }

  const getAttendanceStarted = async (page = 1) => {
    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      setLoadingStarted(true)
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      const params: any = {
        status: 'Em Andamento',
        hoje: false,
        page,
        guiche_id,
      }

      if (typeFilter === 'Data' && selectedDate) {
        params['data'] = format(selectedDate, 'yyyy-MM-dd')
      }

      if (typeFilter === 'CPF') {
        params['cpf'] = valueFilter?.replaceAll(/[^\w\s]/gi, '')
      }
      const { data } = await getAtendimentos(token || '', params)

      if (data) {
        setStartedAttendance(data.results)
        setCountPageStartedAttendance(data.count)
        setNextPageStartedAttendance(data.next)
        setPreviousPageStartedAttendance(data.previous)
        setCurrentPageStartedAttendance(data.current)
        setTotalPagesStartedAttendance(data.total_pages)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingStarted(false)
    }
  }

  const getAttendanceFinished = async (page = 1) => {
    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      setLoadingFinished(true)

      const params: any = {
        status: 'Concluído',
        hoje: false,
        page,
        guiche_id,
      }

      if (typeFilter === 'Data' && selectedDate) {
        params['data'] = format(selectedDate, 'yyyy-MM-dd')
      }
      if (typeFilter === 'CPF') {
        params['cpf'] = valueFilter?.replaceAll(/[^\w\s]/gi, '')
      }

      const { data } = await getAtendimentos(token || '', params)

      if (data) {
        setFinishedAttendance(data.results)
        setCountPageFinishedAttendance(data.count)
        setNextPageFinishedAttendance(data.next)
        setPreviousPageFinishedAttendance(data.previous)
        setCurrentPageFinishedAttendance(data.current)
        setTotalPagesFinishedAttendance(data.total_pages)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingFinished(false)
    }
  }

  const getAttendanceCancel = async (page = 1) => {
    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      setLoadingCancel(true)

      const params: any = {
        status: 'Não Compareceu',
        hoje: false,
        page,
        guiche_id,
      }

      if (typeFilter === 'Data' && selectedDate) {
        params['data'] = format(selectedDate, 'yyyy-MM-dd')
      }
      if (typeFilter === 'CPF') {
        params['cpf'] = valueFilter?.replaceAll(/[^\w\s]/gi, '')
      }
      const { data } = await getAtendimentos(token || '', params)

      if (data) {
        setCancelAttendance(data.results)
        setCountPageCancelAttendance(data.count)
        setNextPageCancelAttendance(data.next)
        setPreviousPageCancelAttendance(data.previous)
        setCurrentPageCancelAttendance(data.current)
        setTotalPagesCancelAttendance(data.total_pages)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingCancel(false)
    }
  }

  useEffect(() => {
    getAttendanceNotStarted()
    getAttendanceStarted()
    getAttendanceFinished()
    getAttendanceCancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setValue(index)
    handleCloseMenu()
  }

  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null)

  const handleCloseMenu = () => {
    setAnchorElMenu(null)
  }

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: 'menu',
  ) => {
    if (type === 'menu') {
      setAnchorElMenu(event.currentTarget)
    }
  }

  const countReplyNotRead = (reply: Array<RespostaAtendimento>) => {
    const auth_cpf = user?.cpf
    let count = 0
    reply.forEach(({ lido, user }) => {
      if (!lido && user?.username !== auth_cpf) {
        count++
      }
    })
    return count
  }

  const openModalAttendance = async (rowsSchedulesToday: Atendimento) => {
    setCurrentAttendece(rowsSchedulesToday)
    setOpenAttendanceDialog(true)
  }

  const openModalAttendanceCancel = async (rowsSchedulesToday: Atendimento) => {
    setCurrentAttendece(rowsSchedulesToday)
    setOpenAttendanceDialogCancel(true)
  }

  const openModalDependents = async (rowsSchedulesToday: Atendimento) => {
    setCurrentAttendece(rowsSchedulesToday)
    setOpenDependentsDialog(true)
  }

  return (
    <Box className={classes.main}>
      <Typography className={classes.textSchedules}>
        Lista de atendimentos
      </Typography>
      <Box
        style={{
          background: '#FFF',
          padding: 5,
        }}
      >
        {matchesMobile ? (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="Status tabs"
            style={{
              borderBottom: '1px solid #F7F8FC',
              borderRadius: 4,
              marginLeft: 5,
            }}
          >
            <Tab
              style={{ padding: 0 }}
              label={
                <span className={classes.tabLabel}>
                  Não iniciados (
                  {loadingNotStarted ? (
                    <CircularProgress size={12} />
                  ) : (
                    countPageCurrentAttendance || 0
                  )}
                  )
                </span>
              }
              {...a11yProps(0)}
            />
            <Tab
              style={{ padding: 0 }}
              label={
                <span className={classes.tabLabel}>
                  Em Andamento (
                  {loadingStarted ? (
                    <CircularProgress size={12} />
                  ) : (
                    countPageStartedAttendance || 0
                  )}
                  )
                </span>
              }
              {...a11yProps(1)}
            />
            <Tab
              style={{ padding: 0 }}
              label={
                <span className={classes.tabLabel}>
                  Concluídos (
                  {loadingFinished ? (
                    <CircularProgress size={12} />
                  ) : (
                    countPageFinishedAttendance || 0
                  )}
                  )
                </span>
              }
              {...a11yProps(2)}
            />
            <Tab
              style={{ padding: 0 }}
              label={
                <span className={classes.tabLabel}>
                  Não compareceu (
                  {loadingCancel ? (
                    <CircularProgress size={12} />
                  ) : (
                    countPageCancelAttendance || 0
                  )}
                  )
                </span>
              }
              {...a11yProps(3)}
            />
          </Tabs>
        ) : (
          <>
            <IconButton onClick={(e): void => handleClick(e, 'menu')}>
              <Menu />
            </IconButton>
            {value === 0 &&
              `Não iniciados (${
                loadingNotStarted ? (
                  <CircularProgress size={12} />
                ) : (
                  countPageCurrentAttendance || 0
                )
              })`}
            {value === 1 &&
              `Em Andamento (${
                loadingStarted ? (
                  <CircularProgress size={12} />
                ) : (
                  countPageStartedAttendance || 0
                )
              })`}
            {value === 2 &&
              `Concluídos (${
                loadingFinished ? (
                  <CircularProgress size={12} />
                ) : (
                  countPageFinishedAttendance || 0
                )
              })`}
            {value === 3 &&
              `Não compareceu (${
                loadingCancel ? (
                  <CircularProgress size={12} />
                ) : (
                  countPageCancelAttendance || 0
                )
              })`}
            <Popover
              open={Boolean(anchorElMenu)}
              anchorEl={anchorElMenu}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(0)}
                >
                  <Typography className={classes.textMenuItem}>
                    Não iniciados (
                    {loadingNotStarted ? (
                      <CircularProgress size={12} />
                    ) : (
                      countPageCurrentAttendance || 0
                    )}
                    )
                  </Typography>
                </MenuItem>
                <Divider />

                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(1)}
                >
                  <Typography className={classes.textMenuItem}>
                    Em Andamento (
                    {loadingStarted ? (
                      <CircularProgress size={12} />
                    ) : (
                      countPageStartedAttendance || 0
                    )}
                    )
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(2)}
                >
                  <Typography className={classes.textMenuItem}>
                    Concluídos (
                    {loadingFinished ? (
                      <CircularProgress size={12} />
                    ) : (
                      countPageFinishedAttendance || 0
                    )}
                    )
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(3)}
                >
                  <Typography className={classes.textMenuItem}>
                    Não compareceu (
                    {loadingCancel ? (
                      <CircularProgress size={12} />
                    ) : (
                      countPageCancelAttendance || 0
                    )}
                    )
                  </Typography>
                </MenuItem>
              </Box>
            </Popover>
          </>
        )}
        <Box>
          {/* Aguardando */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ width: '100%' }}
            >
              <Autocomplete
                id="controllable-states-demo"
                options={['Data', 'Todos', 'CPF']}
                onChange={(event: any, newValue: string | null) => {
                  // if (newValue === null) {
                  //   setTypeFilterNotStared(newValue);

                  // }
                  setTypeFilterNotStared(newValue)
                  // getAttendanceNotStarted();
                }}
                disableClearable
                value={typeFilterNotStarted as any}
                style={{
                  width: !matchesMobile ? '100%' : 168,
                  marginBottom: 15,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Filtrar por"
                  />
                )}
              />

              {typeFilterNotStarted && typeFilterNotStarted === 'Data' && (
                <>
                  {matchesMobile && (
                    <IconButton>
                      <SyncAltRounded />
                    </IconButton>
                  )}
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture={false}
                      minDateMessage="Você não pode agendar em dias anteriores"
                      invalidDateMessage="A data informada é inválida"
                      InputProps={{
                        className: classes.menuItem,
                        classes: { adornedEnd: classes.adornedEnd },
                      }}
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Selecione o dia"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputAdornmentProps={{ position: 'end', color: '#000' }}
                      onChange={(date) => {
                        if (date && isValid(date)) {
                          handleDateChange(date)
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </>
              )}
              {typeFilterNotStarted && typeFilterNotStarted === 'CPF' && (
                <>
                  {matchesMobile && (
                    <IconButton>
                      <SyncAltRounded />
                    </IconButton>
                  )}
                  <InputMask
                    value={valueFilter}
                    onChange={(event: any): void => {
                      if (event?.target?.value) {
                        setValueFilter(event?.target?.value)
                      }
                    }}
                    mask="999.999.999-99"
                    disabled={typeFilterNotStarted === undefined}
                  >
                    {() => (
                      <TextField
                        placeholder="Ex: 000.000.000-00"
                        disabled={typeFilterNotStarted === undefined}
                        style={{
                          width: !matchesMobile ? '100%' : 235,
                          marginBottom: 15,
                          marginRight: matchesMobile ? 15 : 0,
                        }}
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </>
              )}

              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonSearch}
                style={!matchesMobile ? { width: '100%', margin: 0 } : {}}
                startIcon={<Search />}
                disabled={!selectedDate || !typeFilterNotStarted}
                onClick={() => {
                  getAttendanceNotStarted()
                }}
              >
                BUSCAR
              </Button>
            </Box>
            <Box style={{ overflow: 'auto' }}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>NOME</StyledTableCell>
                    <StyledTableCell align="left">CPF</StyledTableCell>
                    <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                    <StyledTableCell align="left">STATUS</StyledTableCell>
                    <StyledTableCell align="left">SERVIÇO</StyledTableCell>
                    <StyledTableCell align="left">AÇÕES</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingNotStarted && <CircularProgress />}
                  {notStartedAttendance &&
                    notStartedAttendance.map((rowsSchedulesToday) => (
                      <StyledTableRow key={rowsSchedulesToday.id}>
                        <StyledTableCell component="th" scope="row">
                          {rowsSchedulesToday.user.first_name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {cpfMask(rowsSchedulesToday?.user?.username)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {`${dateFormat(rowsSchedulesToday.data)} ${
                            rowsSchedulesToday.hora
                          }`}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.status}
                          align="left"
                        >
                          {rowsSchedulesToday.status}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {rowsSchedulesToday.servico.titulo}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {rowsSchedulesToday.para_dependente &&
                            rowsSchedulesToday.dependentes && (
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.buttonIcon}
                                onClick={() =>
                                  openModalDependents(rowsSchedulesToday)
                                }
                                title="Dependentes"
                              >
                                <People />
                              </Button>
                            )}
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.buttonIcon}
                            onClick={() =>
                              openModalAttendance(rowsSchedulesToday)
                            }
                            title="Atendimento"
                          >
                            <AssignmentInd />
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>

            <PaginationService
              getSearch={getAttendanceNotStarted}
              listSearch={{
                count: countPageCurrentAttendance,
                next: nextPageCurrentAttendance,
                previous: previousPageCurrentAttendance,
                current: currentPageCurrentAttendance,
                total_pages: totalPagesCurrentAttendance,
              }}
            />
          </TabPanel>
          {/* Em Andamento */}
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ width: '100%' }}
            >
              <Autocomplete
                id="controllable-states-demo"
                options={['Data', 'CPF']}
                onChange={(event: any, newValue: string | null) => {
                  if (newValue === null) {
                    history.go(0)
                  }
                  setTypeFilter(newValue)
                }}
                value={typeFilter}
                style={{
                  width: !matchesMobile ? '100%' : 168,
                  marginBottom: 15,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Filtrar por"
                  />
                )}
              />
              {matchesMobile && (
                <IconButton>
                  <SyncAltRounded />
                </IconButton>
              )}
              {typeFilter && typeFilter === 'Data' && (
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture={false}
                    minDateMessage="Você não pode agendar em dias anteriores"
                    invalidDateMessage="A data informada é inválida"
                    InputProps={{
                      className: classes.menuItem,
                      classes: { adornedEnd: classes.adornedEnd },
                    }}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Selecione o dia"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{ position: 'end', color: '#000' }}
                    onChange={(date) => {
                      if (date && isValid(date)) {
                        handleDateChange(date)
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}

              {typeFilter && typeFilter === 'CPF' && (
                <>
                  <InputMask
                    value={valueFilter}
                    onChange={(event: any): void => {
                      if (event?.target?.value) {
                        setValueFilter(event.target.value)
                      }
                    }}
                    mask="999.999.999-99"
                    disabled={typeFilter === undefined}
                  >
                    {() => (
                      <TextField
                        placeholder="Ex: 000.000.000-00"
                        disabled={typeFilter === undefined}
                        style={{
                          width: !matchesMobile ? '100%' : 235,
                          marginBottom: 15,
                          marginRight: matchesMobile ? 15 : 0,
                        }}
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </>
              )}

              {!typeFilter && (
                <Autocomplete
                  id="controllable-states-demo"
                  options={[{ name: '' }]}
                  getOptionLabel={(filter) => filter.name}
                  style={{
                    width: !matchesMobile ? '100%' : 235,
                    marginBottom: 15,
                    marginRight: matchesMobile ? 15 : 0,
                  }}
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="-------------"
                    />
                  )}
                />
              )}

              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonSearch}
                style={!matchesMobile ? { width: '100%', margin: 0 } : {}}
                startIcon={<Search />}
                disabled={!selectedDate || !typeFilter}
                onClick={() => {
                  getAttendanceStarted()
                }}
              >
                BUSCAR
              </Button>
            </Box>
            <Box style={{ overflow: 'auto' }}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>NOME</StyledTableCell>
                    <StyledTableCell align="left">CPF</StyledTableCell>
                    <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                    <StyledTableCell align="left">STATUS</StyledTableCell>
                    <StyledTableCell align="left">SERVIÇO</StyledTableCell>
                    <StyledTableCell align="left">AÇÕES</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingStarted && <CircularProgress />}
                  {startedAttendance &&
                    startedAttendance.map((rowsSchedulesPreviou) => (
                      <StyledTableRow key={rowsSchedulesPreviou.id}>
                        <StyledTableCell component="th" scope="row">
                          {rowsSchedulesPreviou.user.first_name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {cpfMask(rowsSchedulesPreviou?.user?.username)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {`${dateFormat(rowsSchedulesPreviou.data)} ${
                            rowsSchedulesPreviou.hora
                          }`}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.status}
                          align="left"
                        >
                          {rowsSchedulesPreviou.status}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {rowsSchedulesPreviou.servico.titulo}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {rowsSchedulesPreviou.para_dependente &&
                            rowsSchedulesPreviou.dependentes && (
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.buttonIcon}
                                onClick={() =>
                                  openModalDependents(rowsSchedulesPreviou)
                                }
                                title="Dependentes"
                              >
                                <People />
                              </Button>
                            )}
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.buttonIcon}
                            onClick={() => {
                              history.push(
                                `/atendimento/${rowsSchedulesPreviou.id}`,
                              )
                            }}
                            title="Atendimento"
                          >
                            <AssignmentInd />
                          </Button>
                          <Button
                            variant="contained"
                            style={
                              countReplyNotRead(rowsSchedulesPreviou.respostas)
                                ? {
                                    color: '#FFF',
                                    backgroundColor: '#EB5757',
                                  }
                                : {}
                            }
                            size="small"
                            disabled={
                              !countReplyNotRead(rowsSchedulesPreviou.respostas)
                            }
                            className={classes.buttonIcon}
                            onClick={() => {
                              history.push(
                                `/atendimento/${rowsSchedulesPreviou.id}`,
                              )
                            }}
                            title="Nova resposta"
                          >
                            <Notifications />
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            className={classes.buttonIconCancel}
                            onClick={() =>
                              openModalAttendanceCancel(rowsSchedulesPreviou)
                            }
                            title="Não compareceu"
                          >
                            <Close />
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
            <PaginationService
              getSearch={getAttendanceStarted}
              listSearch={{
                count: countPageStartedAttendance,
                next: nextPageStartedAttendance,
                previous: previousPageStartedAttendance,
                current: currentPageStartedAttendance,
                total_pages: totalPagesStartedAttendance,
              }}
            />
          </TabPanel>
          {/*Concluído  */}
          <TabPanel value={value} index={2} dir={theme.direction}>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ width: '100%' }}
            >
              <Autocomplete
                id="controllable-states-demo"
                options={['Data', 'CPF']}
                onChange={(event: any, newValue: string | null) => {
                  if (newValue === null) {
                    history.go(0)
                  }
                  setTypeFilter(newValue)
                }}
                value={typeFilter}
                style={{
                  width: !matchesMobile ? '100%' : 168,
                  marginBottom: 15,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Filtrar por"
                  />
                )}
              />

              {matchesMobile && (
                <IconButton>
                  <SyncAltRounded />
                </IconButton>
              )}
              {typeFilter && typeFilter === 'Data' && (
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture={false}
                    minDateMessage="Você não pode agendar em dias anteriores"
                    invalidDateMessage="A data informada é inválida"
                    InputProps={{
                      className: classes.menuItem,
                      classes: { adornedEnd: classes.adornedEnd },
                    }}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Selecione o dia"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{ position: 'end', color: '#000' }}
                    onChange={(date) => {
                      if (date && isValid(date)) {
                        handleDateChange(date)
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}
              {typeFilter && typeFilter === 'CPF' && (
                <>
                  <InputMask
                    value={valueFilter}
                    onChange={(event: any): void => {
                      if (event?.target?.value) {
                        setValueFilter(event.target.value)
                      }
                    }}
                    mask="999.999.999-99"
                    disabled={typeFilter === undefined}
                  >
                    {() => (
                      <TextField
                        placeholder="Ex: 000.000.000-00"
                        disabled={typeFilter === undefined}
                        style={{
                          width: !matchesMobile ? '100%' : 235,
                          marginBottom: 15,
                          marginRight: matchesMobile ? 15 : 0,
                        }}
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </>
              )}
              {!typeFilter && (
                <Autocomplete
                  id="controllable-states-demo"
                  options={[{ name: '' }]}
                  getOptionLabel={(filter) => filter.name}
                  style={{
                    width: !matchesMobile ? '100%' : 235,
                    marginBottom: 15,
                    marginRight: matchesMobile ? 15 : 0,
                  }}
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="-------------"
                    />
                  )}
                />
              )}
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonSearch}
                startIcon={<Search />}
                disabled={!selectedDate || !typeFilter}
                style={!matchesMobile ? { width: '100%', margin: 0 } : {}}
                onClick={() => {
                  getAttendanceFinished()
                }}
              >
                BUSCAR
              </Button>
            </Box>
            <Box style={{ overflow: 'auto' }}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="25%">NOME</StyledTableCell>
                    <StyledTableCell align="left">CPF</StyledTableCell>
                    <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                    <StyledTableCell align="left">STATUS</StyledTableCell>
                    <StyledTableCell align="left">SERVIÇO</StyledTableCell>
                    <StyledTableCell align="left">AÇÕES</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingFinished && <CircularProgress />}
                  {finishedAttendance &&
                    finishedAttendance.map((rowsSchedulesPreviou) => (
                      <>
                        <StyledTableRow
                          key={rowsSchedulesPreviou.id}
                          style={{
                            borderBottomStyle: 'none',
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            {rowsSchedulesPreviou.user.first_name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {cpfMask(rowsSchedulesPreviou?.user?.username)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {`${dateFormat(rowsSchedulesPreviou.data)} ${
                              rowsSchedulesPreviou.hora
                            }`}
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.status}
                            align="left"
                          >
                            {rowsSchedulesPreviou.status}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {rowsSchedulesPreviou.servico.titulo}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {rowsSchedulesPreviou.para_dependente &&
                              rowsSchedulesPreviou.dependentes && (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={classes.buttonIcon}
                                  onClick={() =>
                                    openModalDependents(rowsSchedulesPreviou)
                                  }
                                  title="Dependentes"
                                >
                                  <People />
                                </Button>
                              )}
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.buttonIcon}
                              onClick={() => {
                                history.push(
                                  `/atendimento/${rowsSchedulesPreviou.id}`,
                                )
                              }}
                              title="Atendimento"
                            >
                              <AssignmentInd />
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </Box>
            <PaginationService
              getSearch={getAttendanceFinished}
              listSearch={{
                count: countPageFinishedAttendance,
                next: nextPageFinishedAttendance,
                previous: previousPageFinishedAttendance,
                current: currentPageFinishedAttendance,
                total_pages: totalPagesFinishedAttendance,
              }}
            />
          </TabPanel>
          {/*Não compareceu  */}
          <TabPanel value={value} index={3} dir={theme.direction}>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ width: '100%' }}
            >
              <Autocomplete
                id="controllable-states-demo"
                options={['Data']}
                onChange={(event: any, newValue: string | null) => {
                  if (newValue === null) {
                    history.go(0)
                  }
                  setTypeFilter(newValue)
                }}
                value={typeFilter}
                style={{
                  width: !matchesMobile ? '100%' : 168,
                  marginBottom: 15,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Filtrar por"
                  />
                )}
              />
              {matchesMobile && (
                <IconButton>
                  <SyncAltRounded />
                </IconButton>
              )}
              {typeFilter && typeFilter === 'Data' && (
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture={false}
                    minDateMessage="Você não pode agendar em dias anteriores"
                    invalidDateMessage="A data informada é inválida"
                    InputProps={{
                      className: classes.menuItem,
                      classes: { adornedEnd: classes.adornedEnd },
                    }}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Selecione o dia"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{ position: 'end', color: '#000' }}
                    onChange={(date) => {
                      if (date && isValid(date)) {
                        handleDateChange(date)
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}

              {typeFilter && typeFilter === 'CPF' && (
                <>
                  <InputMask
                    value={valueFilter}
                    onChange={(event: any): void => {
                      if (event?.target?.value) {
                        setValueFilter(event.target.value)
                      }
                    }}
                    mask="999.999.999-99"
                    disabled={typeFilter === undefined}
                  >
                    {() => (
                      <TextField
                        placeholder="Ex: 000.000.000-00"
                        disabled={typeFilter === undefined}
                        style={{
                          width: !matchesMobile ? '100%' : 235,
                          marginBottom: 15,
                          marginRight: matchesMobile ? 15 : 0,
                        }}
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </>
              )}
              {!typeFilter && (
                <Autocomplete
                  id="controllable-states-demo"
                  options={[{ name: '' }]}
                  getOptionLabel={(filter) => filter.name}
                  style={{
                    width: !matchesMobile ? '100%' : 235,
                    marginBottom: 15,
                    marginRight: matchesMobile ? 15 : 0,
                  }}
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="-------------"
                    />
                  )}
                />
              )}
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonSearch}
                startIcon={<Search />}
                disabled={!selectedDate || !typeFilter}
                style={!matchesMobile ? { width: '100%', margin: 0 } : {}}
                onClick={() => {
                  getAttendanceCancel()
                }}
              >
                BUSCAR
              </Button>
            </Box>
            <Box style={{ overflow: 'auto' }}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="25%">NOME</StyledTableCell>
                    <StyledTableCell align="left">CPF</StyledTableCell>
                    <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                    <StyledTableCell align="left">STATUS</StyledTableCell>
                    <StyledTableCell align="left">SERVIÇO</StyledTableCell>
                    <StyledTableCell align="left">AÇÕES</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingCancel && <CircularProgress />}
                  {cancelAttendance &&
                    cancelAttendance.map((rowsSchedulesPreviou) => (
                      <>
                        <StyledTableRow
                          key={rowsSchedulesPreviou.id}
                          style={{
                            borderBottomStyle: 'none',
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            {rowsSchedulesPreviou.user.first_name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {cpfMask(rowsSchedulesPreviou?.user?.username)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {`${dateFormat(rowsSchedulesPreviou.data)} ${
                              rowsSchedulesPreviou.hora
                            }`}
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.status}
                            align="left"
                          >
                            {rowsSchedulesPreviou.status}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {rowsSchedulesPreviou.servico.titulo}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {rowsSchedulesPreviou.para_dependente &&
                              rowsSchedulesPreviou.dependentes && (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={classes.buttonIcon}
                                  onClick={() =>
                                    openModalDependents(rowsSchedulesPreviou)
                                  }
                                  title="Dependentes"
                                >
                                  <People />
                                </Button>
                              )}
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.buttonIcon}
                              onClick={() => {
                                history.push(
                                  `/atendimento/${rowsSchedulesPreviou.id}`,
                                )
                              }}
                              title="Atendimento"
                            >
                              <AssignmentInd />
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </Box>
            <PaginationService
              getSearch={getAttendanceCancel}
              listSearch={{
                count: countPageCancelAttendance,
                next: nextPageCancelAttendance,
                previous: previousPageCancelAttendance,
                current: currentPageCancelAttendance,
                total_pages: totalPagesCancelAttendance,
              }}
            />
          </TabPanel>
        </Box>
      </Box>
      <AttendanceDialog
        setOpenAttendanceDialog={setOpenAttendanceDialog}
        openAttendanceDialog={openAttendanceDialog}
        attendence={currentAttendece}
      />

      <AttendanceDialogCancel
        setOpenAttendanceCancel={setOpenAttendanceDialogCancel}
        openAttendanceCancel={openAttendanceDialogCancel}
        attendence={currentAttendece}
      />
      <DependentsDialog
        setOpenDependentsDialog={setOpenDependentsDialog}
        openDependentsDialog={openDependentsDialog}
        attendance={currentAttendece}
      />
    </Box>
  )
}
