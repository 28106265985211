import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  textModalTitleUDependent: {
    color: '#373F41',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 18,
    letterSpacing: 0.25,
  },
  textModalTitleUDependentCPF: {
    color: 'rgba(55, 63, 65, 0.6)',
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: 16,
  },
  textHeaderModal: {
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0.3,
  },
  header: {
    background: '#FFF',
    border: '1px solid #F7F8FC',
    padding: 10,
  },
  buttonClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: '#B7B7B7',
  },
  textModal: {
    color: '#373F41',
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: 16,
  },
  titleDependents: {
    display: 'inline-grid',
    verticalAlign: 'middle',
    margin: '0px 20px 29px',
  },
  textModalTitle: {
    fontSize: 14,
    color: 'rgba(55, 63, 65, 0.6)',
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  buttonCloseModal: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px 0px 0px 0px',
    alignItems: 'center',
  },
  link: {
    color: '#373F41',
    cursor: 'pointer',
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textDecorationLine: 'underline',
    letterSpacing: 0.3,
    fontStyle: 'normal',
    marginLeft: 16,
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    // margin: theme.spacing(1),
    width: '100%',
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#d3d3d3',
      },
    },
  },
  adornedEnd: {
    paddingRight: 0,
  },
}))

export default useStyles
