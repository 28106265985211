import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import useStyles from '../../style'

interface Props {
  open: boolean
  closeModal: any
  periodoInatividade: any
  motivo: string | undefined
}

function DialogUnavailableService({
  open,
  closeModal,
  periodoInatividade,
  motivo,
}: Props): JSX.Element {
  const handleClose = () => {
    closeModal(false)
  }
  const classes = useStyles()
  function formatDateTime(dateTimeStr: string | number | Date) {
    const date = new Date(dateTimeStr)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Janeiro é 0!
    const year = date.getFullYear()

    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}:${minutes}`
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ display: 'initial' }}>
        <Box className={classes.headerModal}>
          <Typography className={classes.titleModal}>
            SERVIÇO TEMPORARIAMENTE INDISPONÍVEL
          </Typography>
        </Box>
        <DialogContent>
          <Box className={classes.boxService}>
            <Typography className={classes.textHeaderModal}>
              PERÍODO:
              <Box className={classes.boxText}>
                <Typography className={classes.textSubTitlePriority}>
                  {formatDateTime(periodoInatividade?.data_inicial) +
                    ' até ' +
                    formatDateTime(periodoInatividade?.data_final)}
                </Typography>
              </Box>
            </Typography>
          </Box>
          <Box className={classes.boxService}>
            <Typography className={classes.textHeaderModal}>
              MOTIVO:
              <Box className={classes.boxText}>
                <Typography className={classes.textSubTitlePriority}>
                  {motivo}
                </Typography>
              </Box>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className={classes.footerModal}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.buttonClose}
            onClick={handleClose}
          >
            Fechar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default DialogUnavailableService
