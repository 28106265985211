import { apinoticias } from './api'

interface Clima {
  temperatura: number
  temperatura_maxima: number
  temperatura_minima: number
  umidade: number
  descricao_clima: string
  icone: string
}

export const GetClima = async (cidade: string) => {
  try {
    const response = await apinoticias.get<Clima>(
      `/cms/busca-clima?cidade=${cidade}`,
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}
