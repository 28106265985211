import { AxiosResponse } from 'axios'
import format from 'date-fns/format'

import { SchedulesOrganicsResponse } from '../interfaces/agendamento'
import { apisiseci, apisso } from './api'

export const getAgendamentosDiarios = (
  token: string,
  params?: {
    hoje?: boolean
    anteriores?: boolean
    status?:
      | 'Cancelado'
      | 'Concluído'
      | 'Em Andamento'
      | 'Aguardando'
      | 'Não Compareceu'
    page?: number
    unidade?: string
    guiche_id?: string
  },
) =>
  apisiseci.get<SchedulesOrganicsResponse>('agendamento/atendimentos/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  })

export const updateStatusAgendamentoDiario = (
  token: string,
  id: number,
  status:
    | 'Cancelado'
    | 'Concluído'
    | 'Em Andamento'
    | 'Aguardando'
    | 'Não Compareceu',
  guiche: string,
): Promise<AxiosResponse> =>
  apisiseci.put(
    `agendamento/atendimentos/${id}/?guiche_id=${guiche}`,
    {
      id,
      status,
      guiche,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

export const addObservationAgendamentoDiario = (
  token: string,
  id: string,
  observation: string,
): Promise<AxiosResponse> =>
  apisiseci.post(
    `agendamento/observacao/`,
    {
      agendamento: id,
      conteudo: observation,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

export const searchAgendamentosDiarios = (
  token: string,
  params?: {
    data?: string
    cpf?: string
    status?:
      | 'Cancelado'
      | 'Concluído'
      | 'Em Andamento'
      | 'Aguardando'
      | 'Não Compareceu'
    page?: number
    data_inicio?: string
    data_fim?: string
    unidade?: string
    tipo?: 'Presencial' | 'Online' | 'Espontâneo'
    giche_id: string
  },
) =>
  apisiseci.get<SchedulesOrganicsResponse>('agendamento/consulta/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  })

export const gerarAtendimentoOrganico = (
  token: string,
  nome: string,
  cpf: string,
  servico: number,
  setor: number,
  unidade: string,
) =>
  apisiseci.post(
    'agendamento/agendar_espontaneo/',
    {
      nome,
      cpf,
      servico,
      setor,
      unidade,
      tipo: 'Espontâneo',
      para_dependente: false,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

type agendamentoOrganicoProps = {
  token: string
  nome: string
  cpf: string | null
  servico: number
  setor: number
  tipo: string
  hora: string | null
  unidade_slug: string
  para_dependente: boolean
  documento: string | null
  tipo_documento: 'cpf' | 'rg' | 'titulo' | 'nenhum'
  guiche_id: string
  date?: Date
  prioridade?: boolean
  prioridade_alta?: boolean
  descricao_atendimento?: string
  protocolo_atendimento_vinculado?: string
  protocolo_formflow?: string
  servico_interno?: number
  agendamento_id?: number | string | undefined
}

export const agendamentoOrganico = ({
  token,
  nome,
  cpf,
  servico,
  setor,
  tipo,
  hora,
  unidade_slug,
  para_dependente,
  documento,
  tipo_documento,
  guiche_id,
  date,
  prioridade,
  prioridade_alta,
  descricao_atendimento,
  protocolo_atendimento_vinculado,
  protocolo_formflow,
  servico_interno,
  agendamento_id,
}: agendamentoOrganicoProps) => {
  const dateScheduler = date && format(date, 'yyyy-MM-dd')
  let url = ''
  if (agendamento_id) {
    url = `/agendamento/agendar_espontaneo/?guiche_id=${guiche_id}&agendamento_presencial_id=${agendamento_id}`
  } else {
    url = `/agendamento/agendar_espontaneo/?guiche_id=${guiche_id}`
  }
  return apisiseci.post(
    url,
    {
      nome,
      cpf,
      servico,
      setor,
      tipo,
      hora,
      unidade: unidade_slug,
      para_dependente,
      documento,
      tipo_documento,
      data: dateScheduler,
      prioridade: prioridade ? prioridade : false,
      prioridade_alta: prioridade_alta ? prioridade_alta : false,
      servico_interno,
      descricao_atendimento,
      protocolo_atendimento_vinculado,
      protocolo_formflow,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

export const checarHorarios = (
  token: string,
  id_servico: number,
  unidade_slug: string,
  tipo: string,
  data?: Date,
) => {
  let url = ''
  const currentDate = format(new Date(), 'yyyy-MM-dd')
  const dateFormatted = data && format(data, 'yyyy-MM-dd')
  if (data) {
    url = `/agendamento/checar_horarios/?servico=${id_servico}&unidade=${unidade_slug}&data=${dateFormatted}&tipo=${tipo}`
  } else {
    url = `/agendamento/checar_horarios/?servico=${id_servico}&unidade=${unidade_slug}&data=${currentDate}&tipo=${tipo}`
  }
  return apisiseci.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const checarHorariosWithDate = (
  date: Date,
  unity: string,
  serviceID: number,
  token: string,
  tipo: string,
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, 'yyyy-MM-dd')

  return apisiseci.get(
    `agendamento/checar_horarios/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}&tipo=${tipo}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

type checharCPFProps = {
  cpf: string
  token: string
}

export const checarCpf = ({
  cpf,
  token,
}: checharCPFProps): Promise<AxiosResponse> => {
  return apisso.post(
    `/admin/checar/atendente/cpf/`,
    { cpf },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}
