import React, { ReactElement, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { sendCodeForSISECI } from '../../../services/auth'

interface Props {
  children: JSX.Element
  // eslint-disable-next-line react/require-default-props
  window?: () => Window
}

export default function BlankLayout(props: Props): ReactElement {
  const { children } = props
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    const url = (window as any).location.search
    const { pathname, search } = location
    const query = new URLSearchParams(url)
    const code: any = query.get('code')
    if (code) {
      sessionStorage.setItem('pathname_redirect_gov', pathname + search)
      sendCodeForSISECI(code, history)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <>{children}</>
}
