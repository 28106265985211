import React from 'react'

import { Props } from '../../types/icons'

const CalendaryIcon = ({
  styles = { width: '18', height: '18', color: '#6B7280' },
}: Props) => {
  return (
    <svg
      style={styles}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82917 16.5H3.16667C2.72464 16.5 2.30072 16.3244 1.98816 16.0118C1.67559 15.6993 1.5 15.2754 1.5 14.8333V4.83333C1.5 4.39131 1.67559 3.96738 1.98816 3.65482C2.30072 3.34226 2.72464 3.16667 3.16667 3.16667H13.1667C13.6087 3.16667 14.0326 3.34226 14.3452 3.65482C14.6577 3.96738 14.8333 4.39131 14.8333 4.83333V8.16667H1.5M11.5 1.5V4.83333M4.83333 1.5V4.83333M14 12.7467V14L14.8333 14.8333M10.6667 14C10.6667 14.8841 11.0179 15.7319 11.643 16.357C12.2681 16.9821 13.1159 17.3333 14 17.3333C14.8841 17.3333 15.7319 16.9821 16.357 16.357C16.9821 15.7319 17.3333 14.8841 17.3333 14C17.3333 13.1159 16.9821 12.2681 16.357 11.643C15.7319 11.0179 14.8841 10.6667 14 10.6667C13.1159 10.6667 12.2681 11.0179 11.643 11.643C11.0179 12.2681 10.6667 13.1159 10.6667 14Z"
        stroke={styles.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CalendaryIcon
