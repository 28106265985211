import React, { useEffect, useState } from 'react'

import { calculateCountDown } from '../../utils/calculateCountDown'
import { StyledTableRow } from './style'

type Props = {
  children?: React.ReactNode
  data?: string
  hora?: string
  status?: string
}

const colors = {
  success: 'rgba(217, 234, 211, 0.5)',
  error: 'rgba(215, 181, 181, 0.5)',
}
export function TableRowCheckTime({ children, data, hora, status }: Props) {
  const [colorCheck, setColorCheck] = useState<string | undefined>(undefined)

  const updateCountDown = (DateTime: string) => {
    const differenceInMinutesValues = calculateCountDown(DateTime)
    if (differenceInMinutesValues >= 0 && differenceInMinutesValues <= 15) {
      setColorCheck(colors.success)
    }
    if (differenceInMinutesValues > 15) {
      setColorCheck(colors.error)
    }
  }
  // refactor this code
  useEffect(() => {
    if (!!data && !!hora && status === 'Aguardando') {
      const intervalId = setInterval(
        () => updateCountDown(`${data}T${hora}`),
        1000,
      )
      return () => clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hora, status])

  return (
    <StyledTableRow style={colorCheck ? { background: `${colorCheck}` } : {}}>
      {children}
    </StyledTableRow>
  )
}

export default TableRowCheckTime
