import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import People from '@material-ui/icons/People'
import React from 'react'

import { Agendamento } from '../../../../interfaces/agendamento'
import { cpfMask } from '../../../../utils/cpfFormat'
import { dateFormat } from '../../../../utils/dateFormat'
import useStyles from './style'

interface Props {
  openDependentsDialog: boolean
  setOpenDependentsDialog: (openDependentsDialog: boolean) => void
  schedule: undefined | Agendamento
}

export default function DependentsDialog({
  setOpenDependentsDialog,
  openDependentsDialog,
  schedule,
}: Props): JSX.Element {
  const classes = useStyles()

  const handleClose = () => {
    setOpenDependentsDialog(false)
  }

  return (
    <Dialog
      fullWidth
      open={openDependentsDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>DEPENDENTE</Typography>
      </DialogTitle>
      {schedule && schedule.para_dependente && schedule.dependentes && (
        <DialogContent>
          <People color="primary" fontSize="large" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {schedule.dependentes.nome}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {cpfMask(schedule.dependentes.cpf)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              Data de nascimento
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(schedule.dependentes.data_nascimento)}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              grau de parentesco
            </Typography>
            <Typography className={classes.textModal}>
              {schedule.dependentes.grau_parentesco}
            </Typography>
          </Box>
          <Divider light />
          <div className={classes.buttonCloseModal}>
            <Link className={classes.link} onClick={handleClose}>
              fechar
            </Link>
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}
