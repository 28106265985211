import { AxiosResponse } from 'axios'

import { apisiseci } from './api'

export const getGuiche = (token: string): Promise<AxiosResponse> =>
  apisiseci.get('/cms/guiche/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
