import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { FileObject } from 'material-ui-dropzone'
import React, { useCallback, useEffect, useState } from 'react'
import { Jutsu } from 'react-jutsu'
import { useHistory, useParams } from 'react-router-dom'

import { Atendimento } from '../../interfaces/atendimento'
import { ServicoInterno } from '../../interfaces/servico'
import { useStateValue } from '../../providers/StateProvider'
import {
  attachFilesOnReply,
  getAtendimento,
  markAsRead,
  sendReply,
} from '../../services/atendimento'
import { getInterService } from '../../services/servicos'
import { cpfMask } from '../../utils/cpfFormat'
import { dateFormat } from '../../utils/dateFormat'
import { getInitialsFromName } from '../../utils/nameFormat'
import { AttendanceDialog } from '../Attendances/components'
import Modal156 from '../Schedules/components/Modal156'
import Modal156ListDemand from '../Schedules/components/Modal156ListDemand'
import AttendanceDialogCancel from './components/AttendanceDialogCancel'
import AttendanceDialogFinish from './components/AttendanceDialogFinish'
import AttendanceNewScheduleInternal from './components/AttendanceNewScheduleInternal'
import Chat from './components/Chat'
import ModalSendFiles from './components/ModalSendFiles'
import SendMessage from './components/SendMessage'
import { useStyles } from './style'

export default function AttendanceOnlineChat() {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(false)
  const [{ user }] = useStateValue()
  const history = useHistory()
  const [sending, setSending] = useState<boolean>(false)
  const [showSendArquive, setShowSendArquive] = useState<boolean>(false)
  const [atendimento, setAtendimento] = useState<undefined | Atendimento>()
  const [files, setFiles] = useState<FileObject[]>([])
  const [loadingSendFiles, setLoadingSendFiles] = useState<boolean>(false)
  const token: string | null = sessionStorage.getItem('gov_access_token_sso')
  const [openAttendanceCancel, setOpenAttendanceCancel] =
    useState<boolean>(false)
  const [openNewScheduleInternal, setOpenNewScheduleInternal] =
    useState<boolean>(false)
  const [interServices, setInterServices] = useState<
    undefined | ServicoInterno[]
  >()
  const [openAttendanceDialog, setOpenAttendanceDialog] =
    useState<boolean>(false)
  const [openAttendanceCancelDialog, setOpenAttendanceCancelDialog] =
    useState<boolean>(false)
  const matchesMobile = useMediaQuery('(min-width:576px)')
  const [showDetails, setShowDetails] = useState(false)

  const [open156, setOpen156] = useState(false)
  const [protocolSelected, setProtocolSelected] = useState('')
  const [typeSelected, setTypeSelected] = useState('')
  const [cpfSelected, setCpfSelected] = useState('')
  const [open156Demand, setOpen156Demand] = useState(false)
  const [cpfAtendenteSelected, setCpfAtendenteSelected] = useState('')

  const handleOpen156 = (protocol: string, cpf: string, type: string) => {
    setOpen156(true)
    setProtocolSelected(protocol)
    setCpfSelected(cpf)
    setTypeSelected(type)
  }

  const handleOpen156Demanda = (schedule: Atendimento) => {
    setProtocolSelected(schedule.protocolo || '')
    setCpfAtendenteSelected(schedule.atendente_id || '')
    setOpen156Demand(true)
  }

  const jitsiConfig = {
    roomName: id,
    password: 'dattebayo',
    subject: 'fan',
    parentNode: 'jitsi-teste',
    userInfo: {
      email: user && user.contato ? user.contato.email : '',
      displayName: user
        ? user.nome.charAt(0).toUpperCase() + user.nome.slice(1)
        : '',
    },
    interfaceConfigOverwrite: {
      MOBILE_APP_PROMO: false,
      SETTINGS_SECTIONS: ['devices', 'language', 'moderator', 'profile'],
      SHOW_CHROME_EXTENSION_BANNER: false,
      TOOLBAR_BUTTONS: [
        'microphone',
        'camera',
        'closedcaptions',
        'desktop',
        'embedmeeting',
        'fullscreen',
        'fodeviceselection',
        'hangup',
        'profile',
        'recording',
        'livestreaming',
        'etherpad',
        'sharedvideo',
        'settings',
        'videoquality',
        'filmstrip',
        'feedback',
        'stats',
        'shortcuts',
        'tileview',
        'select-background',
        'download',
        'help',
        'mute-everyone',
        'mute-video-everyone',
        'security',
      ],
    },
    configOverwrite: {
      enableCalendarIntegration: false,
      disableDeepLinking: true,
      startWithAudioMuted: false,
      defaultLanguage: 'pt-br',
      disableInviteFunctions: true,
      disableProfile: true,
      enableWelcomePage: true,
    },
  }

  const getAgendamento = async (id: string) => {
    try {
      setLoading(true)
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      const { data } = await getAtendimento(token || '', id, guiche_id)
      setAtendimento(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const connect = () => {
    const ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_SERVICE}/${id}`)

    ws.onmessage = (e) => {
      const dataReceive = JSON.parse(e.data)
      if (dataReceive.event === 'MENSAGEM') {
        setAtendimento((chat): any => {
          const newchat: any = {
            ...chat,
            respostas: chat?.respostas.concat(dataReceive),
          }
          console.log(newchat)
          setAtendimento(newchat)
        })
      }

      ws.onclose = (e) => {
        console.log(
          'Socket is closed. Reconnect will be attempted in 1 second.',
          e.reason,
        )
        setTimeout(() => {
          connect()
        }, 1000)
      }

      ws.onerror = (err) => {
        console.error('Socket encountered error: ', err, 'Closing socket')
        ws.close()
      }

      ws.onopen = () => {
        console.log('connect')
      }
    }
  }

  const interServiceToService = useCallback(async (id) => {
    try {
      // setLoading(true);
      const { data } = await getInterService(
        process.env.REACT_APP_API_SISECI_KEY ?? '',
        id,
      )
      if (data) {
        setInterServices(data)
      }
    } catch (error) {
      console.log('Erro', error)
    } finally {
      //setLoading(false);
    }
  }, [])

  const sendMessage = async (text: string): Promise<any> => {
    if (atendimento && token && text) {
      try {
        setSending(true)
        const { data } = await sendReply(token, id, text, false)
        const newchat: any = {
          ...atendimento,
          respostas: atendimento?.respostas.concat(data),
        }
        // console.log(data);
        setAtendimento(newchat)
      } catch (err) {
        console.log(err)
      } finally {
        setSending(false)
      }
    }
  }

  const sendArquive = async () => {
    if (token) {
      setLoadingSendFiles(true)
      try {
        const { data } = await sendReply(token, id, 'Segue anexo: ', true)
        const newResposta: any = data

        for (const fileUpload of files) {
          const response = await attachFilesOnReply(
            token,
            fileUpload.file.name,
            fileUpload.file.type,
            fileUpload.file,
            data.id,
          )
          newResposta.anexos.push(response.data)
        }
        const newchat: any = {
          ...atendimento,
          respostas: atendimento?.respostas.concat(newResposta),
        }
        setAtendimento(newchat)
        setShowSendArquive(false)
      } catch (err) {
        console.log(err)
      } finally {
        setLoadingSendFiles(false)
      }
    }
  }

  const readMessage = async (): Promise<any> => {
    if (atendimento && user) {
      // eslint-disable-next-line no-restricted-syntax
      for (const resposta of atendimento.respostas) {
        if (
          !resposta.lido &&
          resposta.user.username !== user.cpf &&
          token &&
          resposta.id
        ) {
          // eslint-disable-next-line no-await-in-loop
          await markAsRead(token, resposta.id)
        }
      }
    }
  }

  useEffect(() => {
    if (id && token) {
      getAgendamento(id)
      connect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token])

  useEffect(() => {
    if (atendimento) {
      interServiceToService(atendimento.servico.id)
      readMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimento])

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton onClick={() => history.push('/agendamento')}>
                  <ArrowBack />
                </IconButton>
                <Typography style={{ fontFamily: 'Roboto', fontWeight: 700 }}>
                  Atendimento online
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Paper className={classes.paper}>
                  <Box p={1} component="div" whiteSpace="normal">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Avatar className={classes.iconAvatar}>
                        {getInitialsFromName(
                          atendimento?.user?.first_name as string,
                        )}
                      </Avatar>
                    )}

                    <Typography className={classes.name}>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        atendimento?.user.first_name
                      )}
                    </Typography>
                    <Typography className={classes.cpf}>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        atendimento?.user?.username &&
                        cpfMask(atendimento?.user.username)
                      )}
                    </Typography>
                  </Box>

                  {!matchesMobile ? (
                    <>
                      {showDetails && (
                        <>
                          <Box
                            component="div"
                            whiteSpace="normal"
                            style={{ textAlign: 'initial' }}
                          >
                            <Typography className={classes.textTitle}>
                              serviço
                            </Typography>
                            <Typography className={classes.textSubTitle}>
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                atendimento?.servico.titulo
                              )}
                            </Typography>
                          </Box>
                          <Box
                            component="div"
                            whiteSpace="normal"
                            style={{ textAlign: 'initial' }}
                          >
                            <Typography className={classes.textTitle}>
                              data/hora
                            </Typography>
                            <Typography className={classes.textSubTitle}>
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                `${
                                  atendimento?.data &&
                                  dateFormat(atendimento?.data)
                                } às ${atendimento?.hora}`
                              )}
                            </Typography>
                          </Box>
                          <Box
                            mb={6}
                            component="div"
                            whiteSpace="normal"
                            style={{ textAlign: 'initial' }}
                          >
                            <Typography className={classes.textTitle}>
                              status
                            </Typography>
                            <Typography className={classes.textSubTitle}>
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                atendimento?.status
                              )}
                            </Typography>
                          </Box>

                          {atendimento?.protocolo && (
                            <Box
                              mb={6}
                              component="div"
                              whiteSpace="normal"
                              style={{ textAlign: 'initial' }}
                            >
                              <Typography className={classes.textTitle}>
                                Protocolo agendamento
                              </Typography>
                              <Typography className={classes.textSubTitle}>
                                {loading ? (
                                  <CircularProgress />
                                ) : (
                                  atendimento?.protocolo
                                )}
                              </Typography>
                            </Box>
                          )}
                          {atendimento?.protocolo_atendimento && (
                            <Box
                              mb={6}
                              component="div"
                              whiteSpace="normal"
                              style={{ textAlign: 'initial' }}
                            >
                              <Typography className={classes.textTitle}>
                                Protocolo Atendimento
                              </Typography>
                              <Typography className={classes.textSubTitle}>
                                {loading ? (
                                  <CircularProgress />
                                ) : (
                                  atendimento?.protocolo_atendimento
                                )}
                              </Typography>
                            </Box>
                          )}

                          {atendimento && atendimento.para_dependente && (
                            <>
                              <Divider />
                              <Typography className={classes.textTitle}>
                                Dependente
                              </Typography>
                              <Box
                                mb={6}
                                component="div"
                                whiteSpace="normal"
                                style={{ textAlign: 'initial' }}
                              >
                                <Typography className={classes.textTitle}>
                                  Nome
                                </Typography>
                                <Typography className={classes.textSubTitle}>
                                  {loading ? (
                                    <CircularProgress />
                                  ) : (
                                    atendimento?.dependentes?.nome || ''
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                mb={6}
                                component="div"
                                whiteSpace="normal"
                                style={{ textAlign: 'initial' }}
                              >
                                <Typography className={classes.textTitle}>
                                  cpf
                                </Typography>
                                <Typography className={classes.textSubTitle}>
                                  {loading ? (
                                    <CircularProgress />
                                  ) : (
                                    cpfMask(atendimento?.dependentes?.cpf || '')
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                mb={6}
                                component="div"
                                whiteSpace="normal"
                                style={{ textAlign: 'initial' }}
                              >
                                <Typography className={classes.textTitle}>
                                  Data de nascimento
                                </Typography>
                                <Typography className={classes.textSubTitle}>
                                  {loading ? (
                                    <CircularProgress />
                                  ) : (
                                    dateFormat(
                                      atendimento?.dependentes
                                        ?.data_nascimento as string,
                                    )
                                  )}
                                </Typography>
                              </Box>
                            </>
                          )}
                        </>
                      )}
                      {showDetails && (
                        <Button
                          style={{ width: '100%' }}
                          onClick={() => setShowDetails(false)}
                        >
                          <ExpandLess />
                        </Button>
                      )}
                      {!showDetails && (
                        <Button
                          style={{ width: '100%' }}
                          onClick={() => setShowDetails(true)}
                        >
                          <ExpandMore />
                        </Button>
                      )}
                      <Button
                        style={{ width: '100%' }}
                        onClick={() => setShowDetails(true)}
                      >
                        <ExpandMore />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Box
                        component="div"
                        whiteSpace="normal"
                        style={{ textAlign: 'initial' }}
                      >
                        <Typography className={classes.textTitle}>
                          serviço
                        </Typography>
                        <Typography className={classes.textSubTitle}>
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            atendimento?.servico.titulo
                          )}
                        </Typography>
                      </Box>
                      <Box
                        component="div"
                        whiteSpace="normal"
                        style={{ textAlign: 'initial' }}
                      >
                        <Typography className={classes.textTitle}>
                          data/hora
                        </Typography>
                        <Typography className={classes.textSubTitle}>
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            `${
                              atendimento?.data && dateFormat(atendimento?.data)
                            } às ${atendimento?.hora}`
                          )}
                        </Typography>
                      </Box>
                      <Box
                        mb={6}
                        component="div"
                        whiteSpace="normal"
                        style={{ textAlign: 'initial' }}
                      >
                        <Typography className={classes.textTitle}>
                          status
                        </Typography>
                        <Typography className={classes.textSubTitle}>
                          {loading ? <CircularProgress /> : atendimento?.status}
                        </Typography>
                      </Box>

                      {atendimento?.protocolo && (
                        <Box
                          mb={6}
                          component="div"
                          whiteSpace="normal"
                          style={{ textAlign: 'initial' }}
                        >
                          <Typography className={classes.textTitle}>
                            Protocolo agendamento
                          </Typography>
                          <Typography className={classes.textSubTitle}>
                            {loading ? (
                              <CircularProgress />
                            ) : (
                              atendimento?.protocolo
                            )}
                          </Typography>
                        </Box>
                      )}
                      {atendimento?.protocolo_atendimento && (
                        <Box
                          mb={2}
                          component="div"
                          whiteSpace="normal"
                          style={{ textAlign: 'initial' }}
                        >
                          <Typography className={classes.textTitle}>
                            Protocolo atendimento
                          </Typography>
                          <Typography className={classes.textSubTitle}>
                            {loading ? (
                              <CircularProgress />
                            ) : (
                              atendimento?.protocolo_atendimento
                            )}
                          </Typography>
                        </Box>
                      )}
                      {atendimento && atendimento.para_dependente && (
                        <>
                          <Divider />
                          <Typography className={classes.textTitle}>
                            Dependente
                          </Typography>
                          <Box
                            mb={6}
                            component="div"
                            whiteSpace="normal"
                            style={{ textAlign: 'initial' }}
                          >
                            <Typography className={classes.textTitle}>
                              Nome
                            </Typography>
                            <Typography className={classes.textSubTitle}>
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                atendimento?.dependentes?.nome
                              )}
                            </Typography>
                          </Box>
                          <Box
                            mb={6}
                            component="div"
                            whiteSpace="normal"
                            style={{ textAlign: 'initial' }}
                          >
                            <Typography className={classes.textTitle}>
                              cpf
                            </Typography>
                            <Typography className={classes.textSubTitle}>
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                atendimento?.dependentes?.cpf &&
                                cpfMask(atendimento.dependentes.cpf)
                              )}
                            </Typography>
                          </Box>
                          <Box
                            mb={6}
                            component="div"
                            whiteSpace="normal"
                            style={{ textAlign: 'initial' }}
                          >
                            <Typography className={classes.textTitle}>
                              Data de nascimento
                            </Typography>
                            <Typography className={classes.textSubTitle}>
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                atendimento?.dependentes?.data_nascimento &&
                                dateFormat(
                                  atendimento?.dependentes?.data_nascimento,
                                )
                              )}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </>
                  )}

                  {atendimento &&
                    atendimento.status === 'Em Andamento' &&
                    interServices &&
                    interServices.length > 0 && (
                      <>
                        {atendimento && (
                          <AttendanceNewScheduleInternal
                            attendence={atendimento}
                            openModal={openNewScheduleInternal}
                            setOpenModal={setOpenNewScheduleInternal}
                          />
                        )}
                        <Box
                          style={{
                            borderTop: '4px solid #F7F8FC',
                            marginLeft: 'auto',
                          }}
                        >
                          <Button
                            className={classes.buttonAttendance}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setOpenNewScheduleInternal(true)
                            }}
                          >
                            Agendar atendimento
                          </Button>
                        </Box>
                      </>
                    )}
                  {atendimento &&
                    (atendimento.status === 'Concluído' ||
                      atendimento.status === 'Congelado' ||
                      atendimento.status === 'Em Andamento') &&
                    atendimento.atendente_id?.length && (
                      <Box>
                        <Button
                          className={classes.buttonAttendance}
                          variant="outlined"
                          color="primary"
                          onClick={() => handleOpen156Demanda(atendimento)}
                        >
                          Demandas do Agendamento
                        </Button>
                      </Box>
                    )}
                  {atendimento && atendimento.status === 'Em Andamento' && (
                    <Box>
                      <Button
                        className={classes.buttonAttendance}
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          handleOpen156(
                            atendimento.protocolo as string,
                            atendimento.cpf as string,
                            atendimento.tipo as string,
                          )
                        }
                      >
                        Nova Demanda
                      </Button>
                    </Box>
                  )}
                  {atendimento && atendimento.status === 'Em Andamento' && (
                    <Box>
                      <Button
                        className={classes.buttonAttendance}
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenAttendanceCancelDialog(true)}
                      >
                        Não compareceu
                      </Button>
                    </Box>
                  )}
                  {atendimento && atendimento.status === 'Em Andamento' && (
                    <Box>
                      <Button
                        className={classes.buttonAttendance}
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenAttendanceCancel(true)}
                      >
                        Finalizar atendimento
                      </Button>
                    </Box>
                  )}

                  {atendimento && atendimento.status === 'Aguardando' && (
                    <Box>
                      <Button
                        className={classes.buttonAttendance}
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenAttendanceDialog(true)}
                      >
                        Iniciar atendimento
                      </Button>
                    </Box>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  atendimento?.status === 'Em Andamento' && (
                    <Paper className={classes.video}>
                      <Jutsu
                        domain={
                          process.env.REACT_APP_MEET_DOMAIN ?? 'meet.jit.si'
                        }
                        containerStyles={{ width: '100%' }}
                        roomName={jitsiConfig.roomName}
                        displayName={jitsiConfig.userInfo.displayName}
                        onMeetingEnd={() => console.log('Meeting has ended')}
                        loadingComponent={<p>loading ...</p>}
                        errorComponent={<p>Oops, something went wrong</p>}
                        configOverwrite={jitsiConfig.configOverwrite}
                        interfaceConfigOverwrite={
                          jitsiConfig.interfaceConfigOverwrite
                        }
                      />
                    </Paper>
                  )
                )}
                {loading ? (
                  <CircularProgress />
                ) : (
                  atendimento && (
                    <Paper className={classes.chat}>
                      {atendimento && <Chat chatService={atendimento} />}
                      {atendimento?.status === 'Em Andamento' &&
                        (sending ? (
                          <>
                            {' '}
                            Enviando Mensagem <CircularProgress />
                          </>
                        ) : (
                          <SendMessage
                            showSendArquive={(): void =>
                              setShowSendArquive(true)
                            }
                            sendMessage={sendMessage}
                          />
                        ))}
                    </Paper>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {atendimento && (
          <AttendanceDialogFinish
            setOpenAttendanceCancel={setOpenAttendanceCancel}
            openAttendanceCancel={openAttendanceCancel}
            attendance={atendimento}
          />
        )}
        <ModalSendFiles
          open={showSendArquive}
          handleClose={(): void => {
            setShowSendArquive(false)
          }}
          files={files}
          setFiles={setFiles}
          sendArquive={sendArquive}
          loadingFiles={loadingSendFiles}
        />

        <AttendanceDialog
          setOpenAttendanceDialog={setOpenAttendanceDialog}
          openAttendanceDialog={openAttendanceDialog}
          attendence={atendimento}
        />
      </Container>
      <AttendanceDialogCancel
        schedule={atendimento}
        setOpenAttendanceCancel={setOpenAttendanceCancelDialog}
        openAttendanceCancel={openAttendanceCancelDialog}
      />

      {atendimento && (
        <Modal156
          open={open156}
          setOpen={setOpen156}
          protocol={protocolSelected}
          cpf={cpfSelected}
          tipo={typeSelected}
        />
      )}
      {atendimento && (
        <Modal156ListDemand
          open={open156Demand}
          setOpen={setOpen156Demand}
          protocol={protocolSelected}
          cpf_atendente={cpfAtendenteSelected}
        />
      )}
    </>
  )
}
