import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Close from '@material-ui/icons/Close'
import PersonRounded from '@material-ui/icons/PersonRounded'
import { useState } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { Agendamento } from '../../../../interfaces/agendamento'
import { updateStatusAgendamento } from '../../../../services/agendamento'
import { cpfMask } from '../../../../utils/cpfFormat'
import { dateFormat } from '../../../../utils/dateFormat'
import { useStyles } from './style'

interface Props {
  openAttendanceDialog: boolean
  setOpenAttendanceDialog: (openAttendanceDialog: boolean) => void
  schedule: undefined | Agendamento
}

export default function AttendanceDialog({
  setOpenAttendanceDialog,
  openAttendanceDialog,
  schedule,
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()
  const history = useHistory()
  const matchesMobile = useMediaQuery('(min-width:576px)')

  const handleInit = async (scheduleId: any) => {
    if (schedule?.tipo === 'Online') {
      history.push(`/atendimento/${scheduleId}`)
      return
    }

    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      setLoading(true)
      if (token) {
        await updateStatusAgendamento(token, scheduleId, 'Em Andamento', guiche)
        setOpenAttendanceDialog(false)
        history.go(0)
      }
    } catch (error) {
      console.log('Error ao iniciar Atendimento', error)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpenAttendanceDialog(false)
  }

  return (
    <Dialog
      fullWidth
      open={openAttendanceDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          {schedule?.tipo === 'Online'
            ? 'Abrir Atendimento Online'
            : 'Deseja iniciar o atendimento?'}
        </Typography>
      </DialogTitle>
      {schedule && (
        <DialogContent>
          <PersonRounded fontSize="large" color="primary" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {schedule?.user?.first_name || schedule?.nome}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {cpfMask(schedule.cpf)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {schedule.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              data/hora
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(schedule.data)} às ${schedule.hora}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>status</Typography>
            <Typography className={classes.textModal}>
              {schedule.status}
            </Typography>
          </Box>
          <Divider light />
          <div className={classes.buttonCloseModal}>
            {!matchesMobile && (
              <Button
                style={{ color: '#FFF', fontSize: 12, width: '100%' }}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleInit(schedule.id)}
                disabled={loading}
              >
                {!loading ? 'Iniciar atendimento' : <CircularProgress />}
              </Button>
            )}

            {!loading && (
              <Link
                className={classes.link}
                onClick={handleClose}
                style={
                  !matchesMobile
                    ? { width: '100%', textAlign: 'center', padding: '15px 0' }
                    : {}
                }
              >
                fechar
              </Link>
            )}
            {matchesMobile && (
              <Button
                style={{ color: '#FFF', fontSize: 12 }}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleInit(schedule.id)}
                disabled={loading}
              >
                {!loading ? (
                  schedule.tipo === 'Online' ? (
                    'Ir para atendimento online'
                  ) : (
                    'Iniciar atendimento'
                  )
                ) : (
                  <CircularProgress />
                )}
              </Button>
            )}
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}
