import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import ClockIcon from '../../../../../Icons/Clock'
import painelSemSenhaStyles from './style'

const PainelSemSenha = () => {
  const styles = painelSemSenhaStyles()

  return (
    <Box className={styles.main}>
      <ClockIcon styles={{ width: '96px', height: '96px' }} />
      <Typography variant="h1" className={styles.title}>
        O painel está pronto para iniciar o atendimento.
      </Typography>
      <Typography variant="h2" className={styles.subTitle}>
        A primeira senha será chamada em breve.
      </Typography>
    </Box>
  )
}

export default PainelSemSenha
