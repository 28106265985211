import { apisiseci } from './api'

interface Props {
  agendamento_id: string
  guiche_id: number
  slug_unidade: string
}

export const postSenhaChamada = async ({
  agendamento_id,
  guiche_id,
  slug_unidade,
}: Props) => {
  const reponse = await apisiseci.post(
    '/agendamento/atendimento/confirmar-chamada-senha/',
    {
      agendamento_id,
      guiche_id,
      slug_unidade,
    },
    {
      headers: {
        Authorization: process.env.REACT_APP_API_SISECI_KEY,
      },
    },
  )

  return reponse.data
}
