import { Agendamento, DataSchedulesResults } from '../interfaces/agendamento'

interface InitState {
  dataSchedulesToday: DataSchedulesResults | undefined
  dataSchedulesNext: Agendamento[] | undefined
  loadingToday: boolean
  dataSchedulesOlds: DataSchedulesResults | undefined
  loadingOlds: boolean
  dataSchedulesAll: DataSchedulesResults | undefined
  loadingAll: boolean
  openDependentsDialog: boolean
  schedulesDependentCurrent: Agendamento | undefined
  loadingSchedulesNext: boolean
}

export const initialScheduleState: InitState = {
  dataSchedulesToday: undefined,
  dataSchedulesNext: undefined,
  loadingToday: false,
  dataSchedulesOlds: undefined,
  loadingOlds: false,
  dataSchedulesAll: undefined,
  loadingAll: false,
  openDependentsDialog: false,
  schedulesDependentCurrent: undefined,
  loadingSchedulesNext: false,
}

export const actionTypes = {
  SET_DATA_SCHEDULES_TODAY: 'SET_DATA_SCHEDULES_TODAY',
  SET_DATA_SCHEDULES_NEXT: 'SET_DATA_SCHEDULES_NEXT',
  SET_LOADING_TODAY: 'SET_LOADING_TODAY',
  SET_DATA_SCHEDULES_OLDS: 'SET_DATA_SCHEDULES_OLDS',
  SET_LOADING_OLDS: 'SET_LOADING_OLDS',
  SET_DATA_SCHEDULES_ALL: 'SET_DATA_SCHEDULES_ALL',
  SET_LOADING_ALL: 'SET_LOADING_ALL',
  SET_OPEN_DEPENDENTS_DIALOG: 'SET_OPEN_DEPENDENTS_DIALOG',
  SET_SCHEDULES_DEPENDENT_CURRENT: 'SET_SCHEDULES_DEPENDENT_CURRENT',
  SET_LOADING_SCHEDULES_NEXT: 'SET_LOADING_SCHEDULES_NEXT',
}

const reducerSchedule = (
  state: any,
  action: {
    type: any
    dataSchedulesToday: DataSchedulesResults | undefined
    dataSchedulesNext: Agendamento[] | undefined
    loadingToday: boolean
    dataSchedulesOlds: DataSchedulesResults | undefined
    loadingOlds: boolean
    dataSchedulesAll: DataSchedulesResults | undefined
    loadingAll: boolean
    openDependentsDialog: boolean
    schedulesDependentCurrent: Agendamento | undefined
    loadingSchedulesNext: boolean
  },
) => {
  switch (action.type) {
    case actionTypes.SET_DATA_SCHEDULES_TODAY:
      return {
        ...state,
        dataSchedulesToday: action.dataSchedulesToday,
      }
    case actionTypes.SET_DATA_SCHEDULES_NEXT:
      return {
        ...state,
        dataSchedulesNext: action.dataSchedulesNext,
      }
    case actionTypes.SET_LOADING_TODAY:
      return {
        ...state,
        loadingToday: action.loadingToday,
      }
    case actionTypes.SET_DATA_SCHEDULES_OLDS:
      return {
        ...state,
        dataSchedulesOlds: action.dataSchedulesOlds,
      }
    case actionTypes.SET_LOADING_OLDS:
      return {
        ...state,
        loadingOlds: action.loadingOlds,
      }
    case actionTypes.SET_DATA_SCHEDULES_ALL:
      return {
        ...state,
        dataSchedulesAll: action.dataSchedulesAll,
      }
    case actionTypes.SET_LOADING_ALL:
      return {
        ...state,
        loadingAll: action.loadingAll,
      }
    case actionTypes.SET_OPEN_DEPENDENTS_DIALOG:
      return {
        ...state,
        openDependentsDialog: action.openDependentsDialog,
      }
    case actionTypes.SET_SCHEDULES_DEPENDENT_CURRENT:
      return {
        ...state,
        schedulesDependentCurrent: action.schedulesDependentCurrent,
      }
    case actionTypes.SET_LOADING_SCHEDULES_NEXT:
      return {
        ...state,
        loadingSchedulesNext: action.loadingSchedulesNext,
      }
    default:
      return new Error(`Unhandled  action type ${action.type}`)
  }
}

export default reducerSchedule
