import Box from '@material-ui/core/Box'
import * as jose from 'jose'
import React, { useEffect, useState } from 'react'

import { useStateValue } from '../../providers/StateProvider'

export default function MetabaseIframe(): JSX.Element {
  const [{ currentTicketOffice }] = useStateValue()

  const [iframe, setIframe] = useState('')
  async function generateIframeURL() {
    const unidade_id =
      currentTicketOffice?.setor?.unidade?.slug_unidade ||
      currentTicketOffice?.unidade?.slug_unidade
    console.log({ unidade_id })

    const REACT_APP_METABASE_SITE_URL =
      process.env.REACT_APP_METABASE_SITE_URL || ''
    const REACT_APP_METABASE_SECRET_KEY =
      process.env.REACT_APP_METABASE_SECRET_KEY || ''

    const payload: jose.JWTPayload = {
      resource: { dashboard: 11 },
      params: {},
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    }
    const token = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg: 'HS256' })
      .sign(new TextEncoder().encode(REACT_APP_METABASE_SECRET_KEY))
    const iframeUrl =
      REACT_APP_METABASE_SITE_URL +
      '/embed/dashboard/' +
      token +
      '#bordered=false&titled=false'
    setIframe(iframeUrl)
  }

  useEffect(() => {
    generateIframeURL()
  }, [])
  return (
    <Box>
      <iframe
        title="Dados"
        src={iframe}
        frameBorder={0}
        width={'100%'}
        height={850}
      />
    </Box>
  )
}
