import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import React from 'react'
import { useState } from 'react'

import useStyles from './style'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  protocol: string
  protocol_form: string
}

export default function ModalFormFlow({
  open,
  setOpen,
  protocol,
  protocol_form,
}: Props) {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const handleToggle = async () => {
    setOpen(!open)
    setLoading(true)
  }

  const token = sessionStorage.getItem('gov_access_token_sso')
  const baseUrl = `${process.env.REACT_APP_FORMFLOW_FRONT_URL}/carregar-solicitacao`

  const handleLoad = () => {
    setLoading(false)
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <IconButton onClick={handleToggle} className={classes.buttonClose}>
          <Close />
        </IconButton>
        <DialogTitle className={classes.header}>
          <Typography className={classes.textHeaderModal}>Detalhes</Typography>
        </DialogTitle>
        <DialogContent>
          {loading && (
            <Box
              style={{
                display: 'flex',
                width: '100%',
                height: '600',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <iframe
            src={`${baseUrl}?protocolo=${protocol}&protocolo_formflow=${protocol_form}&token=${token}`}
            width="99%"
            height="99%"
            onLoad={handleLoad}
            style={{ display: loading ? 'none' : 'block' }}
            title="Novo Atendimento"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggle} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
