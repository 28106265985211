import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import PersonRounded from '@material-ui/icons/PersonRounded'
import { useState } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AgendamentoDiario } from '../../../../interfaces/agendamento'
import { updateStatusAgendamentoDiario } from '../../../../services/agendamentoOrganico'
import { cpfMask } from '../../../../utils/cpfFormat'
import { dateFormat } from '../../../../utils/dateFormat'
import { useStyles } from './style'

interface Props {
  openAttendanceDialog: boolean
  setOpenAttendanceDialog: (openAttendanceDialog: boolean) => void
  schedule: undefined | AgendamentoDiario
}

export default function AttendanceDialog({
  setOpenAttendanceDialog,
  openAttendanceDialog,
  schedule,
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()
  const history = useHistory()

  const handleInit = async (scheduleId: any) => {
    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id

      setLoading(true)
      if (token) {
        const { data } = await updateStatusAgendamentoDiario(
          token,
          scheduleId,
          'Em Andamento',
          guiche,
        )
        console.log(data)
        // setOpenAttendanceDialog(false);
        history.go(0)
      }
    } catch (error) {
      console.log('Error ao iniciar Atendimento', error)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpenAttendanceDialog(false)
  }

  return (
    <Dialog
      fullWidth
      open={openAttendanceDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Deseja iniciar o atendimento?
        </Typography>
      </DialogTitle>
      {schedule && (
        <DialogContent>
          <PersonRounded fontSize="large" color="primary" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {schedule.nome}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {cpfMask(schedule.cpf)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {schedule.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              data/hora
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(schedule.data)} às ${schedule.hora}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>status</Typography>
            <Typography className={classes.textModal}>
              {schedule.status}
            </Typography>
          </Box>
          <Divider light />
          <div className={classes.buttonCloseModal}>
            {!loading && (
              <Link className={classes.link} onClick={handleClose}>
                fechar
              </Link>
            )}

            <Button
              style={{ color: '#FFF', fontSize: 12, flex: 0.5 }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => handleInit(schedule.id)}
              disabled={loading}
            >
              {!loading ? 'Iniciar atendimento' : <CircularProgress />}
            </Button>
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}
