import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import { useEffect, useState } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { Agendamento } from '../../../../interfaces/agendamento'
import { useStateValue } from '../../../../providers/StateProvider'
import { updateStatusAgendamento } from '../../../../services/agendamento'
import dateFormat from '../../../../utils/dateFormat'
import { handlePrintAttendance } from '../../../../utils/handlePrintAttendance'
import toastMessage from '../../../../utils/handleToastMessage'
import useStyles from './style'

interface Props {
  openAttendanceCancel: boolean
  setOpenAttendanceCancel: (openAttendanceCancel: boolean) => void
  schedule: undefined | Agendamento
}

export default function DialogAttend({
  setOpenAttendanceCancel,
  openAttendanceCancel,
  schedule,
}: Props): JSX.Element {
  const history = useHistory()
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [normal, setNormal] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)
  const matchesMobile = useMediaQuery('(min-width:576px)')
  const [{ user, currentTicketOffice }] = useStateValue()
  const [openFala, setOpenFala] = useState<boolean>(false)
  const REACT_APP_FALA_SALVADOR_URL_INTEGRACAO =
    process.env.REACT_APP_FALA_SALVADOR_URL_INTEGRACAO
  const REACT_APP_KEY_FALA_SALVADOR = process.env.REACT_APP_KEY_FALA_SALVADOR
  const [prioridade, setPrioridade] = useState<boolean>(false)
  const [prioridadeAlta, setPrioridadeAlta] = useState<boolean>(false)
  const [scheduleSaved, setScheduleSaved] = useState<Agendamento | undefined>()

  const handleClose = () => {
    setOpenAttendanceCancel(false)
    if (saved) {
      history.go(0)
    }
  }

  const handleFinishAttendance = async (scheduleId: any) => {
    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      const codUnid = currentTicketOffice?.unidade?.identificador_externo
      const cpfAtendente = user?.cpf
      const dataAge = schedule?.data
      const cpfCidadao = schedule?.cpf

      const date = dataAge && dateFormat(dataAge as string)

      setLoading(true)
      if (token) {
        const { data } = await updateStatusAgendamento(
          token,
          scheduleId,
          'Compareceu',
          guiche,
          prioridade,
          prioridadeAlta,
        )

        toastMessage({
          type: 'success',
          message: 'Usuário compareceu!',
        })

        const url = `${REACT_APP_FALA_SALVADOR_URL_INTEGRACAO}&cpfAtendente=${cpfAtendente}&codUnid=${codUnid}&dataAge=${date}&cpfCidadao=${cpfCidadao}&chave=${REACT_APP_KEY_FALA_SALVADOR}`

        if (openFala) {
          window.open(url, '_blank', 'noopener')
          history.go(0)
          return
        }

        setTimeout(() => {
          setLoading(false)
          setSaved(true)
          setScheduleSaved(data)
          //handleClose();
          //history.go(0);
        }, 2000)
      }
    } catch (error) {
      toastMessage({
        type: 'error',
        message: 'Erro ao informar o comparecimento atendimento',
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (currentTicketOffice?.unidade?.habilitar_externo) {
      setOpenFala(true)
    }
  }, [currentTicketOffice])

  return (
    <Dialog
      fullWidth
      open={openAttendanceCancel}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          {!saved
            ? !currentTicketOffice?.unidade?.habilitar_externo
              ? 'Selecione o tipo de atendimento'
              : 'Informe o comparecimento'
            : 'Confirmado!'}
        </Typography>
      </DialogTitle>
      {schedule && (
        <DialogContent className={classes.boxContentPriority}>
          {!saved ? (
            <>
              {!currentTicketOffice?.unidade?.habilitar_externo ? (
                <>
                  {' '}
                  {!prioridade && (
                    <>
                      <Box className={classes.boxRowPriority}>
                        <Box>
                          <Checkbox
                            checked={normal}
                            onChange={() => {
                              setNormal(!normal)
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Box>
                        <Box>
                          <Typography className={classes.textTitlePriority}>
                            Normal
                          </Typography>
                          <Typography className={classes.textSubTitlePriority}>
                            Atendimento direcionado ao público em geral.
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                  {!normal && (
                    <>
                      <Divider />
                      <Box className={classes.boxRowPriority}>
                        <Box>
                          <Checkbox
                            checked={prioridade}
                            onChange={() => {
                              setPrioridade(!prioridade)
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Box>
                        <Box className={classes.textTitlePriority}>
                          <Typography className={classes.textTitlePriority}>
                            Prioridade
                          </Typography>
                          <Typography className={classes.textSubTitlePriority}>
                            A principal lei federal é a Lei 10.048/00, que
                            estabelece, no artigo 1º, os grupos de pessoas que
                            têm direito ao atendimento prioritário: pessoas com
                            deficiência, idosos com idade igual ou superior a 60
                            anos.
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                  {prioridade && (
                    <>
                      <Divider />
                      <Box className={classes.boxRowPriority}>
                        <Box>
                          <Checkbox
                            checked={prioridadeAlta}
                            onChange={() => {
                              setPrioridadeAlta(!prioridadeAlta)
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Box>
                        <Box className={classes.textTitlePriority}>
                          <Typography className={classes.textTitlePriority}>
                            Alta Prioridade{' '}
                          </Typography>
                          <Typography className={classes.textSubTitlePriority}>
                            A Lei Federal 13.466/17, estabelece, os idosos acima
                            de 80 anos possuem prioridade perante os outros
                            idosos, gestantes, lactantes, pessoas com crianças
                            de colo e obesos.
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Typography className={classes.textTitlePriority}>
                    Confirme o parecimento de:
                  </Typography>
                  <Typography className={classes.textSubTitlePriority}>
                    {schedule?.nome}
                  </Typography>
                  <Typography className={classes.textSubTitlePriority}>
                    CPF: {schedule?.cpf}
                  </Typography>
                </>
              )}
            </>
          ) : (
            <>
              <CheckCircleOutline
                style={{
                  fontSize: 60,
                  color: '#4bbd7e',
                  textAlign: 'center',
                  width: '100%',
                }}
              />
              <Box
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <Typography className={classes.textTitlePriority}>
                  Comparecimento informado com sucesso!
                </Typography>
                <Typography className={classes.textSubTitlePriority}>
                  Senha
                </Typography>
                <Typography
                  className={classes.textTitlePriority}
                  style={{
                    fontSize: 40,
                  }}
                >
                  {scheduleSaved?.senha_formatada}
                </Typography>
              </Box>
            </>
          )}

          <div className={classes.buttonCloseModal}>
            {!matchesMobile && (
              <>
                {!saved ? (
                  <Button
                    style={{ color: '#FFF', fontSize: 15, width: '100%' }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleFinishAttendance(schedule.id)
                    }}
                    disabled={loading}
                  >
                    {!loading ? 'CONFIRMAR' : <CircularProgress />}
                  </Button>
                ) : (
                  <Button
                    style={{ color: '#FFF', fontSize: 15, width: '100%' }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      if (scheduleSaved) {
                        handlePrintAttendance(scheduleSaved)
                        history.go(0)
                      }
                    }}
                    disabled={loading}
                  >
                    {!loading ? 'IMPRIMIR SENHA' : <CircularProgress />}
                  </Button>
                )}
              </>
            )}
            {!loading && (
              <Link
                className={classes.link}
                onClick={handleClose}
                style={
                  !matchesMobile
                    ? { width: '100%', textAlign: 'center', padding: '15px 0' }
                    : {}
                }
              >
                fechar
              </Link>
            )}
            {matchesMobile && (
              <>
                {!saved ? (
                  <Button
                    style={{ color: '#FFF', fontSize: 15 }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => handleFinishAttendance(schedule.id)}
                    disabled={loading}
                  >
                    {!loading ? 'CONFIRMAR' : <CircularProgress />}
                  </Button>
                ) : (
                  <Button
                    style={{ color: '#FFF', fontSize: 15 }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      if (scheduleSaved) {
                        handlePrintAttendance(scheduleSaved)
                        history.go(0)
                      }
                    }}
                    disabled={loading}
                  >
                    {!loading ? 'IMPRIMIR SENHA' : <CircularProgress />}
                  </Button>
                )}
              </>
            )}
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}
