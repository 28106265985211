import Typography from '@material-ui/core/Typography'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import React, { useEffect, useState } from 'react'

interface TimerProps {
  initialTimestamp: number
}

const Timer: React.FC<TimerProps> = ({ initialTimestamp }) => {
  const currentTime = new Date()
  const initialTime = differenceInSeconds(
    currentTime,
    new Date(initialTimestamp),
  )

  const [time, setTime] = useState(initialTime)

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const formatTime = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`
    return formattedTime
  }

  return (
    <Typography
      style={{
        fontSize: 40,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      {formatTime(time)}
    </Typography>
  )
}

export default Timer
