import { useState } from 'react'

import { TicketOffice } from '../../../../interfaces/ticketOffice'
import { useStateValue } from '../../../../providers/StateProvider'
import { GetClima } from '../../../../services/clima'
import { actionTypes } from '../../../../store/reducer'
import { Props } from './types'

export const useHeaderPainelDeSenhas = ({ GuichesDisponiveis }: Props) => {
  const [{}, dispatch] = useStateValue()
  const [clima, setClima] = useState<number | null>(null)
  const [horaAtual, setHoraAtual] = useState<string>('')
  const [abrirSelect, setAbrirSelect] = useState<boolean>(false)
  const [buscandoClima, setBuscandoClima] = useState<boolean>(false)
  const [guicheSelecionado, setGuicheSelecionado] =
    useState<TicketOffice | null>(null)

  const returnWeekDay = () => {
    const date = new Date()
    const formattedDate = date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    const weekDay = date.toLocaleDateString('pt-BR', { weekday: 'short' })
    return `${formattedDate} - ${
      weekDay.charAt(0).toUpperCase() + weekDay.slice(1)
    }`
  }

  const returnCurrentTime = () => {
    const date = new Date()
    setHoraAtual(
      date.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      }),
    )
  }

  const handleValueOptions = (guiche: TicketOffice) => {
    const unidadeNome =
      guiche?.setor?.unidade?.nome || guiche?.unidade?.nome || ''

    return unidadeNome
  }

  const nomesUnicos = new Set<string>()

  const guichesFiltrados = GuichesDisponiveis.filter((guiche) => {
    const nome = handleValueOptions(guiche)
    if (!nomesUnicos.has(nome)) {
      nomesUnicos.add(nome)
      return true
    }
    return false
  })

  const handleGetClima = async () => {
    try {
      if (guicheSelecionado) {
        setBuscandoClima(true)

        const response = await GetClima(
          guicheSelecionado.setor !== null
            ? guicheSelecionado.setor.unidade.cidade
            : guicheSelecionado.unidade !== null
            ? guicheSelecionado.unidade.cidade
            : '',
        )

        if (response) {
          setClima(response.temperatura)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setBuscandoClima(false)
    }
  }

  const handleGetGuiche = () => {
    try {
      const ticketOffice = sessionStorage.getItem('gov_ticket_office')

      if (ticketOffice) {
        const guiche = JSON.parse(ticketOffice) as TicketOffice

        dispatch({
          type: actionTypes.SET_TICKET_OFFICE,
          currentTicketOffice: guiche,
        })

        setGuicheSelecionado(guiche)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectGuiche = (id: number) => {
    try {
      const guiche = GuichesDisponiveis.find((guiche) => guiche.id === id)

      if (guiche) {
        sessionStorage.setItem('gov_ticket_office', JSON.stringify(guiche))

        dispatch({
          type: actionTypes.SET_TICKET_OFFICE,
          currentTicketOffice: guiche,
        })

        setGuicheSelecionado(guiche || null)
        setAbrirSelect(!abrirSelect)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    clima,
    horaAtual,
    abrirSelect,
    returnWeekDay,
    buscandoClima,
    handleGetClima,
    setAbrirSelect,
    handleGetGuiche,
    guicheSelecionado,
    guichesFiltrados,
    returnCurrentTime,
    handleValueOptions,
    handleSelectGuiche,
  }
}
