import { makeStyles } from '@material-ui/core/styles'

export const footerDeSenhasStyles = makeStyles(() => ({
  main: {
    width: '100%',
    height: '43px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#1F2937',
  },
  mainSmalll: {
    width: '100%',
    height: '23px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#1F2937',
  },
  title: {
    color: 'white',
    fontSize: '16px',
  },
  titleSmall: {
    color: 'white',
    fontSize: '16px',
  },
}))

export default footerDeSenhasStyles
