import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import { useState } from 'react'
import React from 'react'

import { Agendamento } from '../../../../interfaces/agendamento'
import { cpfMask } from '../../../../utils/cpfFormat'
import { useSchedules } from '../../useSchedules'
import useStyles from './style'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  agendamento: Agendamento
}

export default function ModalUnfreeze({ open, setOpen, agendamento }: Props) {
  const classes = useStyles()

  const { handlePlay } = useSchedules()

  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setLoading(false)
  }

  const handleSave = async () => {
    setLoading(true)
    await handlePlay(agendamento.id as string)
    handleClose()
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton onClick={handleClose} className={classes.buttonClose}>
          <Close />
        </IconButton>
        <DialogTitle className={classes.header}>
          <Typography className={classes.textHeaderModal}>
            Descongelar atendimento
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>Nome</Typography>
            <Typography className={classes.textModal}>
              {agendamento.nome}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>CPF</Typography>
            <Typography className={classes.textModal}>
              {cpfMask(agendamento.cpf || '')}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {agendamento.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              Motivo congelamento
            </Typography>
            <Typography className={classes.textModal}>
              {agendamento.congelado_motivo || 'Não informado'}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            form="form-pause"
            disabled={loading}
            onClick={handleSave}
          >
            {loading ? <CircularProgress /> : 'Descongelar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
