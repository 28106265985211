import Box from '@material-ui/core/Box'
import React, { ReactElement, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useStateValue } from '../../../providers/StateProvider'
import { sendCodeForSISECI } from '../../../services/auth'
import { Header, SideBar } from '../..'

interface Props {
  children: JSX.Element
  window?: () => Window
}

export default function Dash(props: Props): ReactElement {
  const [{ user, currentTicketOffice }] = useStateValue()
  const { children } = props
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    const url = (window as any).location.search
    const { pathname, search } = location
    const query = new URLSearchParams(url)
    const code: any = query.get('code')
    if (code) {
      sessionStorage.setItem('pathname_redirect_gov', pathname + search)
      sendCodeForSISECI(code, history)
    } else if (!user && !currentTicketOffice) {
      sessionStorage.setItem('pathname_redirect_gov', pathname + search)
      history.push('/')
    }
  }, [user, currentTicketOffice])

  return (
    <Box
      display="flex"
      style={{ background: '#F7F8FC', minHeight: '100vh', overflow: 'auto' }}
    >
      <SideBar />
      <Box style={{ width: '100%', overflow: 'auto' }}>
        <Header />
        {user && currentTicketOffice && (
          <Box style={{ width: '100%', overflow: 'auto' }}>{children}</Box>
        )}
      </Box>
    </Box>
  )
}
