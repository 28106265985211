import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((_theme: Theme) => ({
  main: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#053396',
    color: '#FFFFFF',
    flexDirection: 'column',
  },
  icon: {
    padding: 10,
  },
  text: {
    fontSize: 32,
    fontWeight: 'bold',
    font: 'Roboto',
    letterSpacing: 0.3,
  },
  loading: {
    color: '#FFFFFF',
    padding: 10,
  },
  selectTicket: {
    minWidth: 600,
    padding: 10,
    margin: 10,
    fontSize: 16,
    fontWeight: 'bold',
    font: 'Roboto',
  },
  btnSelectTicket: {
    minWidth: 400,
    padding: 10,
    margin: 10,
    fontSize: 16,
    fontWeight: 'bold',
    font: 'Roboto',
    backgroundColor: '#053396',
    color: '#FFFFFF',
    border: '1px solid #FFFFFF',
    borderRadius: 5,
  },
}))

export default useStyles
