import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import React from 'react'
import { FormEvent, useState } from 'react'

import { Agendamento } from '../../../../interfaces/agendamento'
import { cpfMask } from '../../../../utils/cpfFormat'
import { useSchedules } from '../../useSchedules'
import useStyles from './style'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  agendamento: Agendamento
}

export default function ModalFreeze({ open, setOpen, agendamento }: Props) {
  const classes = useStyles()

  const { handlePause } = useSchedules()

  const [loading, setLoading] = useState(false)
  const [motivo, setMotivo] = useState('')

  const handleClose = () => {
    setOpen(false)
    setLoading(false)
    setMotivo('')
  }

  const handleSave = async (event: FormEvent) => {
    event.preventDefault()
    setLoading(true)
    await handlePause(agendamento.id as string, motivo)
    handleClose()
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton onClick={handleClose} className={classes.buttonClose}>
          <Close />
        </IconButton>
        <DialogTitle className={classes.header}>
          <Typography className={classes.textHeaderModal}>
            Congelar atendimento
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box component="form" id="form-pause" onSubmit={handleSave}>
            <Box mb={3}>
              <Typography className={classes.textModalTitle}>Nome</Typography>
              <Typography className={classes.textModal}>
                {agendamento.nome}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography className={classes.textModalTitle}>CPF</Typography>
              <Typography className={classes.textModal}>
                {cpfMask(agendamento.cpf || '')}
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography className={classes.textModalTitle}>
                serviço
              </Typography>
              <Typography className={classes.textModal}>
                {agendamento.servico.titulo}
              </Typography>
            </Box>

            <TextField
              type="text"
              label="Motivo do congelamento"
              fullWidth
              required
              multiline
              minRows={7}
              variant="outlined"
              name="motivo"
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            form="form-pause"
            disabled={loading || motivo.length < 4}
          >
            {loading ? <CircularProgress /> : 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
