import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useStateValue } from '../../providers/StateProvider'
import { useStyles } from './style'

export default function Home(): JSX.Element {
  const classes = useStyles()
  const history = useHistory()
  const [{ user, currentTicketOffice }] = useStateValue()

  const handleOpenFalaSalvador = () => {
    window.open(process.env.REACT_APP_FALA_SALVADOR_URL || '', '_blank')
  }
  return (
    <>
      <Box className={classes.main}>
        <Typography className={classes.textTitleHome}>
          {user && `Bem-vindo(a), ${user?.nome}.`}
        </Typography>
        <img
          src="/assets/images/logo_home.svg"
          alt="Home"
          className={classes.imgHome}
        />
        <Box className={classes.boxButtons}>
          {currentTicketOffice?.tipo === 'Interno' && (
            <div className={classes.boxButtonsConsulta}>
              <Button
                variant="contained"
                className={classes.buttonLink}
                onClick={() => history.push('/agendamento_interno')}
              >
                Atendimento internos
              </Button>
              <Button
                variant="outlined"
                className={classes.buttonLinkOpen}
                onClick={handleOpenFalaSalvador}
              >
                ABRIR FALA SALVADOR
              </Button>
            </div>
          )}

          {currentTicketOffice?.tipo === 'Atendimento' && (
            <div className={classes.boxButtonsConsulta}>
              <Button
                variant="contained"
                className={classes.buttonLink}
                onClick={() => history.push('/agendamento')}
              >
                LISTA DE AGENDAMENTOS
              </Button>
              <Button
                variant="outlined"
                className={classes.buttonLinkOpen}
                onClick={handleOpenFalaSalvador}
              >
                ABRIR FALA SALVADOR
              </Button>
            </div>
          )}

          {currentTicketOffice?.tipo === 'Consulta' && (
            <div className={classes.boxButtonsConsulta}>
              <Button
                variant="contained"
                className={classes.buttonLink}
                onClick={() => history.push('/consulta_agendamento')}
              >
                LISTA DE AGENDAMENTOS
              </Button>
              <Button
                variant="outlined"
                className={classes.buttonLinkOpen}
                onClick={handleOpenFalaSalvador}
              >
                ABRIR FALA SALVADOR
              </Button>
            </div>
          )}
          {currentTicketOffice?.tipo === 'Presencial' && (
            <div className={classes.boxButtonsConsulta}>
              <Button
                variant="contained"
                className={classes.buttonLink}
                onClick={() => history.push('/agendamento')}
              >
                atendimento
              </Button>
              <Button
                variant="outlined"
                className={classes.buttonLinkOpen}
                onClick={handleOpenFalaSalvador}
              >
                ABRIR FALA SALVADOR
              </Button>
            </div>
          )}
          {currentTicketOffice?.tipo === 'Online' && (
            <Button
              variant="contained"
              className={classes.buttonLink}
              onClick={() => history.push('/atendimento')}
            >
              Atendimento Online
            </Button>
          )}

          {currentTicketOffice?.tipo === 'Diario' && (
            <>
              <Button
                variant="contained"
                className={classes.buttonLink}
                onClick={() => history.push('/atendimento_diario')}
              >
                Agendamento Diário
              </Button>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}
