import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Atendimento } from '../../../../interfaces/atendimento'
import { updateStatusAgendamento } from '../../../../services/agendamento'
import {
  addObservationAttendence,
  sendReply,
} from '../../../../services/atendimento'
import { cpfMask } from '../../../../utils/cpfFormat'
import { dateFormat } from '../../../../utils/dateFormat'
import { getInitialsFromName } from '../../../../utils/nameFormat'
import useStyles from './style'

interface Props {
  openAttendanceCancel: boolean
  setOpenAttendanceCancel: (openAttendanceCancel: boolean) => void
  attendance: Atendimento
}

export default function AttendanceDialogFinish({
  setOpenAttendanceCancel,
  openAttendanceCancel,
  attendance,
}: Props): JSX.Element {
  const classes = useStyles()
  const [observation, setObservation] = useState<undefined | string>()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()

  const handleClose = () => {
    setOpenAttendanceCancel(false)
  }

  const handleFinishAttendance = async () => {
    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )

      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id

      setLoading(true)

      if (token) {
        await sendReply(token, attendance.id, 'Atendimento Concluído', false)
        const { status } = await updateStatusAgendamento(
          token,
          attendance.id,
          'Concluído',
          guiche_id,
        )

        if (status === 200 && !!observation) {
          await addObservationAttendence(
            token,
            attendance.id,
            observation || '',
          )
        }
        history.push('/agendamento')
      }
    } catch (error) {
      console.log('Erro', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullWidth
      open={openAttendanceCancel}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Deseja finalizar este atendimento?
        </Typography>
      </DialogTitle>
      {attendance && (
        <DialogContent>
          <Avatar className={classes.iconAvatar}>
            {getInitialsFromName(attendance.user.first_name)}
          </Avatar>
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {attendance.user.first_name}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {attendance?.user?.username &&
                cpfMask(attendance?.user?.username)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {attendance.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              data/hora
            </Typography>
            <Typography className={classes.textModal}>
              {dateFormat(attendance?.data)} às {attendance?.hora}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>status</Typography>
            <Typography className={classes.textModal}>
              {attendance.status}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              observações: (As informações deste campo serão compartilhadas no
              histórico do cidadão.)
            </Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={5}
              variant="outlined"
              margin="normal"
              disabled={loading}
              inputProps={{
                style: {
                  fontSize: 14,
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                },
              }}
              style={{ width: '100%', marginBottom: 10 }}
              value={observation}
              onChange={(e) => {
                setObservation(e.target.value)
              }}
            />
          </Box>
          <Divider light />
          <div className={classes.buttonCloseModal}>
            {!loading && (
              <Link className={classes.link} onClick={handleClose}>
                fechar
              </Link>
            )}
            <Button
              style={{ color: '#FFF', fontSize: 14 }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => handleFinishAttendance()}
              disabled={loading}
            >
              {!loading ? 'finalizar atendimento' : <CircularProgress />}
            </Button>
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}
