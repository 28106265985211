import React from 'react'

import { Props } from '../../types/icons'

const TicketIcon = ({ styles = { width: '16', height: '14' } }: Props) => {
  return (
    <svg
      style={styles}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 1.1665V2.83317M10.5 6.1665V7.83317M10.5 11.1665V12.8332M2.16667 1.1665H13.8333C14.2754 1.1665 14.6993 1.3421 15.0118 1.65466C15.3244 1.96722 15.5 2.39114 15.5 2.83317V5.33317C15.058 5.33317 14.634 5.50877 14.3215 5.82133C14.0089 6.13389 13.8333 6.55781 13.8333 6.99984C13.8333 7.44186 14.0089 7.86579 14.3215 8.17835C14.634 8.49091 15.058 8.6665 15.5 8.6665V11.1665C15.5 11.6085 15.3244 12.0325 15.0118 12.345C14.6993 12.6576 14.2754 12.8332 13.8333 12.8332H2.16667C1.72464 12.8332 1.30072 12.6576 0.988155 12.345C0.675595 12.0325 0.5 11.6085 0.5 11.1665V8.6665C0.942027 8.6665 1.36595 8.49091 1.67851 8.17835C1.99107 7.86579 2.16667 7.44186 2.16667 6.99984C2.16667 6.55781 1.99107 6.13389 1.67851 5.82133C1.36595 5.50877 0.942027 5.33317 0.5 5.33317V2.83317C0.5 2.39114 0.675595 1.96722 0.988155 1.65466C1.30072 1.3421 1.72464 1.1665 2.16667 1.1665Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TicketIcon
