import './index.css'

import { ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { ScheduleProvider } from './providers/ScheduleProvider'
import theme from './providers/theme-service'
import reportWebVitals from './reportWebVitals'
import reducerSchedule, { initialScheduleState } from './store/schedulesReducer'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ScheduleProvider
        initialState={initialScheduleState}
        reducer={reducerSchedule}
      >
        <App />
      </ScheduleProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
