import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((_theme: Theme) => ({
  main: {
    background: '#FFFFFF',
    maxWidth: 900,
    margin: 'auto',
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
  },
  textTitleHome: {
    fontFamily: 'Roboto',
    fontSize: 32,
    fontStyle: 'normal',
    letterSpacing: 0.3,
    fontWeight: 700,
    color: '#373F41',
    textAlign: 'center',
    flex: 1,
    marginBottom: 50,
  },
  imgHome: {
    width: '100%',
    maxWidth: 400,
    flex: 1,
  },
  buttonLinkOnline: {
    background: '#F2994A',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    width: 220,
    padding: 10,
    '&:hover': {
      background: '#F2994A',
    },
    marginTop: 50,
  },
  buttonLink: {
    background: '#053396',
    color: '#FFFFFF',
    minWidth: 220,
    padding: 10,
    '&:hover': {
      background: '#053396',
    },
    margin: 5,
    flex: 1,
  },
  buttonLinkOpen: {
    color: '#053396',
    border: '1px solid #053396',
    padding: 10,
    '&:hover': {},
    margin: 5,
    flex: 1,
    minWidth: 220,
  },

  boxButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minWidth: 400,
  },
  boxButtonsConsulta: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
}))

export default useStyles
