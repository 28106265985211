import { makeStyles, Theme } from '@material-ui/core/styles'

import { Props } from './types/styles'

export const usePausePanelStyles = makeStyles((theme: Theme) => ({
  main: {
    gap: '1rem',
    width: '100%',
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  text: {
    bottom: 50,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  title: ({ tvSize }: Props) => ({
    fontWeight: 'bolder',
    color: theme.palette.primary.main,
    fontSize: tvSize === 'FULL_HD' ? '4rem' : '3rem',
  }),
  subtitle: ({ tvSize }: Props) => ({
    color: theme.palette.primary.main,
    fontSize: tvSize === 'FULL_HD' ? '2rem' : '1.5rem',
  }),
}))

export default usePausePanelStyles
