import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

export const StyledTableCell = withStyles((_theme) => ({
  head: {
    color: 'rgba(55, 63, 65, 0.6);',
  },
  body: {
    minWidth: 145,
    fontSize: 16,
    color: '#373F41',
    justifyItems: 'center',
  },
}))(TableCell)

export const StyledTableRow = withStyles((_theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F7F8FC',
    },
  },
}))(TableRow)

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  main: {
    width: '90%',
    margin: 'auto',
  },
  mainTable: {
    width: '100%',
    padding: 10,
    borderRadius: 8,
  },
  tableDatail: {
    display: 'flex',
    fontSize: 24,
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    fontWeight: 'bold',
    width: '100%',
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleTable: {
    background: '#053396',
    color: '#FFFFFF',
    height: 64,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
  textSchedules: {
    fontSize: 24,
    letterSpacing: 0.3,
    fontFamily: 'Roboto',
    color: '#373F41',
    margin: '15px 0px ',
  },
  headerTable: {
    height: 64,
    borderBottom: '1px solid #E3E3E3',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: 14,
    textAlign: 'left',
    padding: 10,
  },
  dataTable: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: 16,
    padding: 10,
    height: 80,
    color: '#373F41',
  },
  dataTableActions: {
    width: 200,
  },
  listSchedules: {
    width: '100%',
    borderRadius: 4,
  },
  status: {
    color: theme.palette.primary.main,
  },
  tabLabel: {
    textTransform: 'capitalize',
    fontSize: 16,
    margin: '32px 0px 3px',
  },
  buttonIcon: {
    minWidth: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    margin: 2,
  },
  buttonIconAttention: {
    minWidth: 40,
    height: 40,
    backgroundColor: '#E15050',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#E15050',
    },
  },
  buttonSearch: {
    height: 54,
    minWidth: 133,
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: 0.3,
    margin: '0px 8px 15px 0px',
  },
  buttonCancelSearch: {
    height: 54,
    minWidth: 133,
    color: '#ff9b38',
    borderColor: '#ff9b38',
    letterSpacing: 0.3,
    margin: '0px 8px 0px 8px',
    marginBottom: 15,
  },
  paginationClass: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    '& .Mui-selected': {
      borderRadius: 0,
    },
  },
  textTitleDetails: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  textValueDetails: {
    color: '#373F41',
    fontSize: 14,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  adornedEnd: {
    paddingRight: 0,
    marginBottom: 15,
    marginRight: 8,
    '@media (max-width: 660px)': {
      marginRight: 0,
      width: '100%',
    },
  },
  textMenuItem: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    marginLeft: 10,
  },
}))

export default useStyles
