/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import format from 'date-fns/format'
import { useCallback, useState } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { Agendamento } from '../../interfaces/agendamento'
import { useStateScheduleValue } from '../../providers/ScheduleProvider'
import { useStateValue } from '../../providers/StateProvider'
import {
  callPassword,
  getAgendamentos,
  pauseAttendance,
  playAttendance,
  unlockPassword,
} from '../../services/agendamento'
import { actionTypes } from '../../store/schedulesReducer'
import { cpfMask } from '../../utils/cpfFormat'
import toastMessage from '../../utils/handleToastMessage'
import useStyles from './style'

export function useSchedules() {
  const [
    {
      dataSchedulesToday,
      dataSchedulesNext,
      loadingToday,
      loadingSchedulesNext,
      openDependentsDialog,
      schedulesDependentCurrent,
      loadingOlds,
      dataSchedulesOlds,
      dataSchedulesAll,
      loadingAll,
    },
    dispatch,
  ] = useStateScheduleValue()

  const searchParams = new URLSearchParams(window.location.search)

  const tipo_prioridade = searchParams.get('tipo_prioridade')!
  const cpf = searchParams.get('cpf')!
  const nome = searchParams.get('nome')!
  const senha = searchParams.get('senha')!
  const statusParams = searchParams.get('status')!
  const tipo = searchParams.get('tipo')!
  const page = searchParams.get('page')!

  const classes: any = useStyles()
  const [{ currentTicketOffice }] = useStateValue()
  const theme = useTheme()
  const history = useHistory()
  const [value, setValue] = useState(0)

  const [callingPass, setCallingPass] = useState(false)
  const [callingUnlockPass, setCallingUnlockPass] = useState(false)
  const matchesMobile = useMediaQuery('(min-width:576px)')
  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null)
  const [currentSchedule, setCurrentSchedule] = useState<
    undefined | Agendamento
  >()

  const [filter, setFilter] = useState<undefined | 'CPF' | 'Data'>(undefined)
  const [valueFilter, setValueFilter] = useState<undefined | string>()
  const [passId, setPassId] = useState<string>()
  const [showDetails, setShowDetails] = useState<undefined | string>()
  const [selectedDate, handleDateChange] = useState<undefined | Date>(
    new Date(),
  )

  const [emAndamento, setEmAndamento] = useState<boolean>(false)
  const [valueFilterName, setValueFilterName] = React.useState<
    string | undefined
  >(searchParams.get('nome') || undefined)
  const [valueFilterCpf, setValueFilterCpf] = React.useState<
    string | undefined
  >(searchParams.get('cpf') ? cpfMask(searchParams.get('cpf') as string) : '')
  const [valueFilterPassword, setValueFilterPassword] = React.useState<
    string | undefined
  >(searchParams.get('senha') || undefined)
  const [valueFilterType, setValueFilterType] = React.useState<
    'PrioridadeAlta' | 'Prioridade' | 'Normal' | undefined | null | string
  >(searchParams.get('tipo_prioridade') || null)
  const [valueFilterStatus, setValueFilterStatus] = React.useState<
    'Em Andamento' | 'Compareceu' | undefined | null | string
  >(searchParams.get('status') || null)
  const [valueFilterTypeSchedule, setValueFilterTypeSchedule] = React.useState<
    'Online' | 'Presencial' | 'Espontâneo' | undefined | null | string
  >(searchParams.get('tipo') || null)

  const [remainingTime, setRemainingTime] = useState(25)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const setSchedulesDependentCurrent = (
    currentSchedule: undefined | Agendamento,
  ) => {
    dispatch({
      type: actionTypes.SET_SCHEDULES_DEPENDENT_CURRENT,
      schedulesDependentCurrent: currentSchedule,
    })
  }

  const handleUnlockPass = async (agendamento_id: string) => {
    try {
      setCallingUnlockPass(true)
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      const { data, status } = await unlockPassword(
        token || '',
        guiche_id,
        agendamento_id,
      )

      if (status === 200) {
        toastMessage({
          type: 'success',
          message: data?.message || 'Senha liberada com sucesso!',
        })
        await agendamentosDaVezHoje({
          page: Number(page) !== 0 ? Number(page) : 1,
          cpf,
          nome,
          senha,
          status: statusParams,
          tipo,
          tipo_prioridade,
        })
        await agendamentosHoje()
      }
    } catch (error) {
      toastMessage({
        type: 'error',
        message: 'Error ao liberar senha',
      })
      console.log(error)
    } finally {
      setCallingUnlockPass(false)
    }
  }

  const handlePause = async (agendamento_id: string, motivo: string) => {
    try {
      setCallingUnlockPass(true)
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      const { data, status } = await pauseAttendance(
        token || '',
        guiche_id,
        agendamento_id,
        motivo,
      )

      if (status === 200) {
        toastMessage({
          type: 'success',
          message: data?.message || 'Atendimento congelado com sucesso!',
        })
        await agendamentosDaVezHoje()
        await agendamentosHoje()
      }
    } catch (error) {
      toastMessage({
        type: 'error',
        message: 'Falha ao tenta congelar o atendimento',
      })
      console.log(error)
    } finally {
      setCallingUnlockPass(false)
    }
  }
  const handlePlay = async (agendamento_id: string) => {
    try {
      setCallingUnlockPass(true)
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      const { data, status } = await playAttendance(
        token || '',
        guiche_id,
        agendamento_id,
      )

      if (status === 200) {
        toastMessage({
          type: 'success',
          message: data?.message || 'Atendimento descongelado com sucesso!',
        })
        await agendamentosDaVezHoje()
        await agendamentosHoje()
      }
    } catch (error) {
      toastMessage({
        type: 'error',
        message: 'Falha ao tenta descongelar o atendimento',
      })
      console.log(error)
    } finally {
      setCallingUnlockPass(false)
    }
  }

  const [inPanel, setInPanel] = useState(false)

  const [awaitCallPass, setAwaitCallPass] = useState(false)

  const handleCallPass = async (agendamento_id: string) => {
    try {
      if (callingPass) {
        return
      }
      setAwaitCallPass(true)
      setRemainingTime(15)
      setCallingPass(true)
      setPassId(agendamento_id)
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      const { data, status } = await callPassword(
        token || '',
        guiche_id,
        agendamento_id,
      )
      if (status === 200) {
        toastMessage({
          type: 'success',
          message: data?.message || 'Atendimento chamado com sucesso!',
        })
      }
    } catch (error) {
      toastMessage({
        type: 'error',
        message: 'Error ao chamar senha',
      })
      console.log(error)
      setCallingPass(false)
      setRemainingTime(0)
    }
  }

  const getAllAgendamentos = useCallback(async (page = 1, params?: any) => {
    try {
      dispatch({
        type: actionTypes.SET_LOADING_ALL,
        loadingAll: true,
      })
      dispatch({
        type: actionTypes.SET_DATA_SCHEDULES_ALL,
        dataSchedulesAll: undefined,
      })
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id

      const { data } = await getAgendamentos(token || '', {
        page,
        guiche_id,
        ...params,
      })
      if (!!data) {
        dispatch({
          type: actionTypes.SET_DATA_SCHEDULES_ALL,
          dataSchedulesAll: data,
        })
      } else {
        dispatch({
          type: actionTypes.SET_DATA_SCHEDULES_ALL,
          dataSchedulesAll: undefined,
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: actionTypes.SET_DATA_SCHEDULES_ALL,
        dataSchedulesAll: undefined,
      })
    } finally {
      dispatch({
        type: actionTypes.SET_LOADING_ALL,
        loadingAll: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const agendamentosDaVezHoje = useCallback(
    async ({
      page,
      cpf,
      tipo_prioridade,
      tipo,
      senha,
      nome,
      status,
    }: {
      page?: number
      tipo_prioridade?: string
      tipo?: string
      em_andamento?: boolean
      cpf?: string
      senha?: string
      nome?: string
      status?:
        | 'Cancelado'
        | 'Concluído'
        | 'Em Andamento'
        | 'Aguardando'
        | 'Congelado'
        | 'Compareceu'
        | 'Não Compareceu'
        | string
    } = {}) => {
      try {
        dispatch({
          type: actionTypes.SET_LOADING_SCHEDULES_NEXT,
          loadingSchedulesNext: true,
        })
        dispatch({
          type: actionTypes.SET_DATA_SCHEDULES_NEXT,
          dataSchedulesNext: undefined,
        })

        const token: string | null = sessionStorage.getItem(
          'gov_access_token_sso',
        )
        const guiche_id = JSON.parse(
          sessionStorage.getItem('gov_ticket_office') || '',
        ).id

        const { data } = await getAgendamentos(token || '', {
          page,
          periodo: 'hoje',
          guiche_id,
          davez: 'Sim',
          items_size: 35,
          cpf,
          tipo_prioridade,
          tipo,
          senha,
          status,
          nome,
        })

        if (!!data) {
          const { results } = data

          if (!!results.length) {
            dispatch({
              type: actionTypes.SET_DATA_SCHEDULES_NEXT,
              dataSchedulesNext: data,
            })
          } else {
            dispatch({
              type: actionTypes.SET_DATA_SCHEDULES_NEXT,
              dataSchedulesNext: undefined,
            })
          }
        }
      } catch (error) {
        console.log(error)
        dispatch({
          type: actionTypes.SET_DATA_SCHEDULES_NEXT,
          dataSchedulesNext: undefined,
        })

        dispatch({
          type: actionTypes.SET_DATA_SCHEDULES_TODAY,
          dataSchedulesToday: undefined,
        })
      } finally {
        dispatch({
          type: actionTypes.SET_LOADING_SCHEDULES_NEXT,
          loadingSchedulesNext: false,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [currentTicketOffice],
  )

  const agendamentosHoje = useCallback(
    async ({
      page,
    }: {
      page?: number
    } = {}) => {
      try {
        dispatch({
          type: actionTypes.SET_LOADING_TODAY,
          loadingToday: true,
        })
        dispatch({
          type: actionTypes.SET_DATA_SCHEDULES_TODAY,
          dataSchedulesToday: undefined,
        })

        const token: string | null = sessionStorage.getItem(
          'gov_access_token_sso',
        )
        const guiche_id = JSON.parse(
          sessionStorage.getItem('gov_ticket_office') || '',
        ).id

        const dataHoje = await getAgendamentos(token || '', {
          periodo: 'hoje',
          page,
          guiche_id,
          in_status: 'Congelado',
        })

        if (dataHoje?.data) {
          if (dataHoje?.data?.results) {
            dispatch({
              type: actionTypes.SET_DATA_SCHEDULES_TODAY,
              dataSchedulesToday: {
                count: dataHoje.data?.count,
                next: dataHoje.data?.next,
                previous: dataHoje.data?.previous,
                current: dataHoje.data?.current,
                total_pages: dataHoje.data?.total_pages,
                results: dataHoje.data?.results,
              },
            })
          } else {
            dispatch({
              type: actionTypes.SET_DATA_SCHEDULES_TODAY,
              dataSchedulesToday: undefined,
            })
          }
        }
      } catch (error) {
        console.log(error)
        dispatch({
          type: actionTypes.SET_DATA_SCHEDULES_TODAY,
          dataSchedulesToday: undefined,
        })
      } finally {
        dispatch({
          type: actionTypes.SET_LOADING_TODAY,
          loadingToday: false,
        })
      }
    },
    [dispatch],
  )

  const agendamentosAnteriores = useCallback(
    async (page = 1) => {
      try {
        dispatch({
          type: actionTypes.SET_LOADING_OLDS,
          loadingOlds: true,
        })
        dispatch({
          type: actionTypes.SET_DATA_SCHEDULES_OLDS,
          dataSchedulesOlds: undefined,
        })

        const token: string | null = sessionStorage.getItem(
          'gov_access_token_sso',
        )
        const guiche_id = JSON.parse(
          sessionStorage.getItem('gov_ticket_office') || '',
        ).id

        const params: any = {
          periodo: 'anteriores',
          out_status: 'Aguardando,Compareceu,Em Andamento',
          page,
          guiche_id,
        }

        if (filter === 'Data' && selectedDate) {
          params['data'] = format(selectedDate, 'yyyy-MM-dd')
        }

        if (filter === 'CPF') {
          params['cpf'] = valueFilter?.replaceAll(/[^\w\s]/gi, '')
        }
        const { data } = await getAgendamentos(token || '', params)

        if (!!data) {
          const { count, next, previous, total_pages, current, results } = data
          if (results) {
            const previousSchedulesLines = data.results.filter(
              (schedule) =>
                schedule.status !== 'Aguardando' &&
                schedule.status !== 'Em Andamento',
            )
            dispatch({
              type: actionTypes.SET_DATA_SCHEDULES_OLDS,
              dataSchedulesOlds: {
                count,
                next,
                previous,
                current,
                total_pages,
                results: previousSchedulesLines,
              },
            })
          }
        }
      } catch (error) {
        console.log(error)
        dispatch({
          type: actionTypes.SET_DATA_SCHEDULES_OLDS,
          dataSchedulesOlds: undefined,
        })
      } finally {
        dispatch({
          type: actionTypes.SET_LOADING_OLDS,
          loadingOlds: false,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [filter, selectedDate, valueFilter],
  )

  const [openAttendanceCancel, setOpenAttendanceCancel] =
    useState<boolean>(false)
  const [openAttendanceDialog, setOpenAttendanceDialog] =
    useState<boolean>(false)

  const [openAttendanceStop, setOpenAttendanceStop] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const setOpenDependentsDialog = (openDependentsDialog: boolean) => {
    dispatch({
      type: actionTypes.SET_OPEN_DEPENDENTS_DIALOG,
      openDependentsDialog,
    })
  }

  const handleChangeIndex = (index: number) => {
    setValue(index)
    handleCloseMenu()
  }

  const handleCloseMenu = () => {
    setAnchorElMenu(null)
  }

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: 'menu',
  ) => {
    if (type === 'menu') {
      setAnchorElMenu(event.currentTarget)
    }
  }

  const searchUSer = () => {
    agendamentosAnteriores()
  }

  const changeAutoComplete = (event: any, valueParam: any): void => {
    if (valueParam === null) {
      history.go(0)
    }
    setFilter(valueParam)
  }

  const getHrefComprovante = (ticket: string) => {
    const baseUrl = process.env.REACT_APP_PORTAL_URL || ''
    if (!!ticket) {
      return `${baseUrl}/comprovante/agendamento/${ticket}`
    }
    return '#'
  }

  const addUrlSearchParams = (pageNumber: number) => {
    setCurrentPage(pageNumber)
    searchParams.set('page', pageNumber.toString())

    const newUrl = searchParams
      ? `${window.location.pathname}?${searchParams}`
      : window.location.pathname

    window.history.pushState({}, '', newUrl)
  }

  return {
    classes,
    currentSchedule,
    setSchedulesDependentCurrent,
    setOpenDependentsDialog,
    setCurrentSchedule,
    setOpenAttendanceDialog,
    openAttendanceCancel,
    setOpenAttendanceCancel,
    openAttendanceStop,
    setOpenAttendanceStop,
    history,
    openDependentsDialog,
    schedulesDependentCurrent,
    openAttendanceDialog,
    matchesMobile,
    value,
    handleChange,
    anchorElMenu,
    handleCloseMenu,
    handleChangeIndex,
    theme,
    agendamentosDaVezHoje,
    agendamentosHoje,
    handleClick,
    filter,
    changeAutoComplete,
    selectedDate,
    handleDateChange,
    valueFilter,
    setValueFilter,
    searchUSer,
    showDetails,
    setShowDetails,
    agendamentosAnteriores,
    loadingAll,
    getAllAgendamentos,
    dataSchedulesToday,
    dataSchedulesNext,
    loadingToday,
    loadingSchedulesNext,
    loadingOlds,
    dataSchedulesOlds,
    dataSchedulesAll,
    getHrefComprovante,
    handleCallPass,
    callingPass,
    awaitCallPass,
    remainingTime,
    handleUnlockPass,
    callingUnlockPass,
    handlePause,
    handlePlay,
    valueFilterName,
    setValueFilterName,
    valueFilterCpf,
    setValueFilterCpf,
    valueFilterPassword,
    setValueFilterPassword,
    valueFilterType,
    setValueFilterType,
    valueFilterStatus,
    passId,
    inPanel,
    setInPanel,
    setValueFilterStatus,
    currentPage,
    setCurrentPage,
    emAndamento,
    setCallingPass,
    setRemainingTime,
    setEmAndamento,
    valueFilterTypeSchedule,
    setValueFilterTypeSchedule,
    addUrlSearchParams,
    setPassId,
  }
}
