import Box from '@material-ui/core/Box'
import React, { useEffect } from 'react'

import { WebSocketProvider } from '../../context/WebSocketContext'
import BodyPainelDeSenhas from './components/BodyPainelDeSenhas'
import FooterPaineDeSenhas from './components/FooterPainelDeSenhas'
import HeaderPainelDeSenhas from './components/HeaderPainelDeSenhas'
import painelDeSenhasStyles from './style'
import { usePainelDeSenhas } from './usePainelDeSenhas'

const PaineDeSenhas = () => {
  const styles = painelDeSenhasStyles()
  const {
    buscandoGuiche,
    handleGetGuiche,
    guichesDisponiveis,
    guicheSelecionado,
    setGuicheSelecionado,
  } = usePainelDeSenhas()

  useEffect(() => {
    handleGetGuiche()
  }, [])

  return (
    <Box className={styles.main}>
      <WebSocketProvider>
        <HeaderPainelDeSenhas
          BuscandoGuiche={buscandoGuiche}
          GuichesDisponiveis={guichesDisponiveis}
          onSelectGuiche={(guiche) => setGuicheSelecionado(guiche)}
        />
        <BodyPainelDeSenhas GuicheSelecionado={guicheSelecionado} />
        <FooterPaineDeSenhas />
      </WebSocketProvider>
    </Box>
  )
}

export default PaineDeSenhas
