import { makeStyles } from '@material-ui/core/styles'

import theme from '../../../../../../../providers/theme-service'

export const painelSemSenhaStyles = makeStyles(() => ({
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundSize: 'cover',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: theme.palette.primary.main,
    backgroundImage: 'url(/assets/images/city-azul.webp)',
  },
  title: {
    fontSize: '40px',
    fontWeight: 'bolder',
  },
  subTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
}))

export default painelSemSenhaStyles
