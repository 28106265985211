import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'

import footerDeSenhasStyles from './style'

const FooterPaineDeSenhas = () => {
  const styles = footerDeSenhasStyles()
  const isFullHd = useMediaQuery('(min-width:1920px)')

  return (
    <Box className={isFullHd ? styles.main : styles.mainSmalll}>
      <Typography
        variant="button"
        className={isFullHd ? styles.title : styles.titleSmall}
      >
        Seja Bem-vindo! Retire sua senha e aguarde ser chamado
      </Typography>
    </Box>
  )
}

export default FooterPaineDeSenhas
