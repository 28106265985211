import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'

import useScreenSize from '../../../../../../hooks/useScreenSize'
import usePausePanelStyles from './style'
import { Props } from './types/index'
// import { usePausePanel } from './usePausePanel'

const PausePanel = ({ newsResponse }: Props) => {
  const screenSize = useScreenSize()
  // const { configurarIframe } = usePausePanel()
  const classes = usePausePanelStyles({ tvSize: screenSize })
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (newsResponse.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % newsResponse.length)
      }, newsResponse[currentIndex].tempo_exibicao * 1000)

      return () => clearInterval(interval)
    }
  }, [currentIndex, newsResponse])

  return (
    <Box className={classes.main} position="relative">
      {newsResponse[currentIndex].video_id ? (
        <iframe
          style={{ width: '100%' }}
          height="100%"
          src={`https://www.youtube.com/embed/${newsResponse[currentIndex].video_id}?autoplay=1&controls=0`}
          title="YouTube video player"
          frameBorder="0"
          allow="fullscreen;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : (
        <>
          <Box
            className={classes.image}
            style={{
              backgroundImage: `url(${newsResponse[currentIndex].imagem})`,
            }}
          />
          <Box position="absolute" zIndex={1} className={classes.text}>
            <Typography className={classes.title}>
              {newsResponse[currentIndex].titulo}
            </Typography>
            <Typography className={classes.subtitle}>
              {newsResponse[currentIndex].subtitulo}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  )
}

export default PausePanel
