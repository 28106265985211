import React from 'react'

import { Props } from '../../types/icons'

const PersonIcon = ({ styles = { width: '80', height: '81' } }: Props) => {
  return (
    <svg
      style={styles}
      viewBox="0 0 19 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 25.5V22.8333C1.5 21.4188 2.0619 20.0623 3.0621 19.0621C4.06229 18.0619 5.41885 17.5 6.83333 17.5H12.1667C13.5812 17.5 14.9377 18.0619 15.9379 19.0621C16.9381 20.0623 17.5 21.4188 17.5 22.8333V25.5M4.16667 6.83333C4.16667 8.24782 4.72857 9.60438 5.72876 10.6046C6.72896 11.6048 8.08551 12.1667 9.5 12.1667C10.9145 12.1667 12.271 11.6048 13.2712 10.6046C14.2714 9.60438 14.8333 8.24782 14.8333 6.83333C14.8333 5.41885 14.2714 4.06229 13.2712 3.0621C12.271 2.0619 10.9145 1.5 9.5 1.5C8.08551 1.5 6.72896 2.0619 5.72876 3.0621C4.72857 4.06229 4.16667 5.41885 4.16667 6.83333Z"
        stroke={styles.color || 'black'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PersonIcon
