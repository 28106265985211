import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import PlayArrow from '@material-ui/icons/PlayArrow'
import React, { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getLastPauseDate, retomarGuiche } from '../../services/agendamento'
import toastMessage from '../../utils/handleToastMessage'
import Timer from './components/Timer'
import useStyles from './style'

export default function PausePage(): JSX.Element {
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [startTime, setStartTime] = useState(0)
  const classes = useStyles()

  const changeTicketOffice = () => {
    sessionStorage.removeItem('gov_ticket_office')
    history.push('/')
  }
  const getLastTime = async () => {
    try {
      setLoading(true)
      const ticketOffice = sessionStorage.getItem('gov_ticket_office')
      if (ticketOffice) {
        const dataTicket = JSON.parse(ticketOffice)
        const token: string | null = sessionStorage.getItem(
          'gov_access_token_sso',
        )
        if (dataTicket?.id) {
          const { data } = await getLastPauseDate(token || '', dataTicket?.id)
          if (data?.pausado_em) {
            setStartTime(data?.pausado_em)
          }
        }
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const handleSave = async (event: FormEvent) => {
    event.preventDefault()
    try {
      setLoading(true)
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const ticketOffice = sessionStorage.getItem('gov_ticket_office')
      if (ticketOffice) {
        const currentTicketOffice = JSON.parse(ticketOffice)
        const data = await retomarGuiche(token || '', currentTicketOffice?.id)
        if (data.status === 200) {
          currentTicketOffice.pausado = false
          sessionStorage.setItem(
            'gov_ticket_office',
            JSON.stringify(currentTicketOffice),
          )
          toastMessage({
            type: 'success',
            message: 'Guichê retomado com Sucesso!',
          })
        }
        history.push('/agendamento')
      }
    } catch (error: any) {
      toastMessage({
        type: 'error',
        message: error?.response?.data?.detail || 'Error ao pausar guichê!',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getLastTime()
  }, [])

  return (
    <Box className={classes.main}>
      <img
        src="/assets/images/SupportAgent.svg"
        className={classes.icon}
        alt="logo Atendimento"
      />

      <Typography className={classes.text}>Guichê Pausado</Typography>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <Timer initialTimestamp={startTime} />
          <Button
            color="secondary"
            variant="contained"
            style={{
              marginBottom: 20,
              color: '#0b3d13',
            }}
            onClick={handleSave}
          >
            <PlayArrow />
            Entrar
          </Button>
          <Button color="secondary" onClick={changeTicketOffice}>
            Trocar Guichê
          </Button>
        </>
      )}
    </Box>
  )
}
