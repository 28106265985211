import DateFnsUtils from '@date-io/date-fns'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import { useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowRight from '@material-ui/icons/ArrowRight'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import Menu from '@material-ui/icons/Menu'
import People from '@material-ui/icons/People'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Search from '@material-ui/icons/Search'
import SyncAltRounded from '@material-ui/icons/SyncAltRounded'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import ptBR from 'date-fns/locale/pt-BR'
import React, { Fragment, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'

import PaginationService from '../../Components/PaginationService'
import { AgendamentoDiario } from '../../interfaces/agendamento'
import { getAgendamentosDiarios } from '../../services/agendamentoOrganico'
import { cpfMask } from '../../utils/cpfFormat'
import dateFormat, {
  extractDateFormat,
  extractHourFormat,
} from '../../utils/dateFormat'
import {
  AttendanceDialog,
  AttendanceDialogCancel,
  DependentsDialog,
} from './components'
import AttendanceDialogStop from './components/AttendanceDialogCancelStop'
import { useStyles } from './style'
import { StyledTableCell, StyledTableRow } from './style'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export default function OrganicSchedules(): JSX.Element {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const [value, setValue] = useState(0)
  const [loadingCurrentNext, setLoadingCurrentNext] = useState<boolean>(false)
  const [loadingPrevious, setloadingPrevious] = useState<boolean>(false)

  const [currentSchedule, setCurrentSchedule] = useState<
    undefined | AgendamentoDiario
  >()

  const [countPageCurrentAttendance, setCountPageCurrentAttendance] =
    useState<number>(1)
  const [nextPageCurrentAttendance, setNextPageCurrentAttendance] =
    useState<number>(1)
  const [previousPageCurrentAttendance, setPreviousPageCurrentAttendance] =
    useState<number>(1)
  const [currentPageCurrentAttendance, setCurrentPageCurrentAttendance] =
    useState<number>(1)
  const [totalPagesCurrentAttendance, setTotalPagesCurrentAttendance] =
    useState<number>(1)

  const [nextSchedules, setNextSchedules] = useState<
    undefined | AgendamentoDiario[]
  >()
  const [previousSchedules, setPreviousSchedules] = useState<
    undefined | AgendamentoDiario[]
  >()

  const [countPagePreviousAttendance, setCountPagePreviousAttendance] =
    useState<number>(1)
  const [nextPagePreviousAttendance, setNextPagePreviousAttendance] =
    useState<number>(1)
  const [previousPagePreviousAttendance, setPreviousPagePreviousAttendance] =
    useState<number>(1)
  const [currentPagePreviousAttendance, setCurrentPagePreviousAttendance] =
    useState<number>(1)
  const [totalPagesPreviousAttendance, setTotalPagesPreviousAttendance] =
    useState<number>(1)
  const [schedulesDependentCurrent, setSchedulesDependentCurrent] = useState<
    undefined | AgendamentoDiario
  >()
  const [filter, setFilter] = useState<undefined | 'CPF' | 'Data'>(undefined)
  const [valueFilter, setValueFilter] = useState<undefined | string>()
  const [showDetails, setShowDetails] = useState<undefined | string>()
  const [selectedDate, handleDateChange] = useState<undefined | Date>(
    new Date(),
  )

  async function agendamentosHoje(page = 1) {
    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id

      setLoadingCurrentNext(true)
      const { data } = await getAgendamentosDiarios(token || '', {
        hoje: true,
        page,
        guiche_id,
      })
      setCountPageCurrentAttendance(data.count)
      setNextPageCurrentAttendance(data.next)
      setPreviousPageCurrentAttendance(data.previous)
      setCurrentPageCurrentAttendance(data.current)
      setTotalPagesCurrentAttendance(data.total_pages)
      if (data.results.length) {
        const current = data.results.filter(
          ({ status }) =>
            status === 'Aguardando' ||
            status === 'Em Andamento' ||
            status === 'Compareceu',
        )
        setCurrentSchedule(current.splice(0, 1)[0])
        setNextSchedules(current)
      } else {
        setCurrentSchedule(undefined)
        setNextSchedules(undefined)
      }
    } catch (error) {
    } finally {
      setLoadingCurrentNext(false)
    }
  }

  async function agendamentosAnteriores(page = 1) {
    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id

      setloadingPrevious(true)
      const params: any = {
        anteriores: true,
        page,
        guiche_id,
      }

      if (filter === 'Data' && selectedDate) {
        params['data'] = format(selectedDate, 'yyyy-MM-dd')
      }

      if (filter === 'CPF') {
        params['cpf'] = valueFilter?.replaceAll(/[^\w\s]/gi, '')
      }
      const { data } = await getAgendamentosDiarios(token || '', params)
      setCountPagePreviousAttendance(data.count)
      setNextPagePreviousAttendance(data.next)
      setPreviousPagePreviousAttendance(data.previous)
      setCurrentPagePreviousAttendance(data.current)
      setTotalPagesPreviousAttendance(data.total_pages)
      if (data.results.length) {
        const previousSchedulesLines = data.results.filter(
          (schedule) =>
            schedule.status !== 'Aguardando' &&
            schedule.status !== 'Em Andamento',
        )
        setPreviousSchedules(previousSchedulesLines)
      }
    } catch (error) {
    } finally {
      setloadingPrevious(false)
    }
  }

  useEffect(() => {
    agendamentosHoje()
    agendamentosAnteriores()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [openAttendanceCancel, setOpenAttendanceCancel] =
    useState<boolean>(false)

  const [openAttendanceDialog, setOpenAttendanceDialog] =
    useState<boolean>(false)

  const [openDependentsDialog, setOpenDependentsDialog] =
    useState<boolean>(false)

  const [openAttendanceStop, setOpenAttendanceStop] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setValue(index)
    handleCloseMenu()
  }
  const matchesMobile = useMediaQuery('(min-width:576px)')
  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null)

  const handleCloseMenu = () => {
    setAnchorElMenu(null)
  }

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: 'menu',
  ) => {
    if (type === 'menu') {
      setAnchorElMenu(event.currentTarget)
    }
  }
  const searchUSer = () => {
    agendamentosAnteriores()
  }

  const changeAutoComplete = (event: any, valueParam: any): void => {
    if (valueParam === null) {
      history.go(0)
    }

    setFilter(valueParam)
  }

  useEffect(() => {
    if (!currentSchedule) {
      const interval = setInterval(() => {
        agendamentosHoje()
      }, 120000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [currentSchedule])

  return (
    <Box className={classes.main}>
      <Box
        style={{
          overflow: 'auto',
        }}
      >
        <Paper className={classes.root}>
          <Box className={classes.tableDatail}>Agendamento da vez</Box>
          <Box style={{ overflow: 'auto' }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>CIDADÃO</StyledTableCell>
                  <StyledTableCell align="left">CPF</StyledTableCell>
                  <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                  <StyledTableCell align="left">STATUS</StyledTableCell>
                  <StyledTableCell align="left">SERVIÇO</StyledTableCell>
                  <StyledTableCell align="left">AÇÕES</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingCurrentNext && <CircularProgress />}
                {currentSchedule && (
                  <StyledTableRow
                    style={{ backgroundColor: '#FFF' }}
                    key={currentSchedule.cpf}
                  >
                    <StyledTableCell component="th" scope="row">
                      {currentSchedule.nome}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {cpfMask(currentSchedule.cpf)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {`${dateFormat(currentSchedule.data)} ${
                        currentSchedule.hora
                      }`}
                    </StyledTableCell>
                    <StyledTableCell className={classes.status} align="left">
                      {currentSchedule.status}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {currentSchedule.servico.titulo}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {currentSchedule.para_dependente &&
                        currentSchedule.dependentes && (
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.buttonIcon}
                            onClick={() => {
                              setSchedulesDependentCurrent(currentSchedule)
                              setOpenDependentsDialog(true)
                            }}
                            title="Dependentes"
                          >
                            <People />
                          </Button>
                        )}
                      {!currentSchedule.para_dependente && (
                        <span title="Sem dependentes">
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.buttonIcon}
                            disabled
                          >
                            <People />
                          </Button>
                        </span>
                      )}
                      {currentSchedule.status === 'Aguardando' && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.buttonIcon}
                            onClick={() => setOpenAttendanceDialog(true)}
                            title="Iniciar atendimento"
                          >
                            <PlayArrow />
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            className={classes.buttonIconAttention}
                            onClick={() => setOpenAttendanceCancel(true)}
                            title="Não compareceu"
                          >
                            <Icon className="material-icons-outlined">
                              person_off
                            </Icon>
                          </Button>
                        </>
                      )}

                      {currentSchedule.status === 'Em Andamento' && (
                        <>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.buttonIcon}
                            onClick={() => setOpenAttendanceStop(true)}
                            title="Finalizar atendimento"
                          >
                            <CheckCircleOutline />
                          </Button>
                        </>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </Box>

          <DependentsDialog
            setOpenDependentsDialog={setOpenDependentsDialog}
            openDependentsDialog={openDependentsDialog}
            schedule={schedulesDependentCurrent}
          />
          <AttendanceDialog
            setOpenAttendanceDialog={setOpenAttendanceDialog}
            openAttendanceDialog={openAttendanceDialog}
            schedule={currentSchedule}
          />
          <AttendanceDialogCancel
            setOpenAttendanceCancel={setOpenAttendanceCancel}
            openAttendanceCancel={openAttendanceCancel}
            schedule={currentSchedule}
          />
          <AttendanceDialogStop
            setOpenAttendanceStop={setOpenAttendanceStop}
            openAttendanceStop={openAttendanceStop}
            schedule={currentSchedule}
          />
        </Paper>
      </Box>
      <Typography className={classes.textSchedules}>
        Lista de agendamentos
      </Typography>

      <Box
        style={{
          overflow: 'auto',
          background: '#FFF',
        }}
      >
        {matchesMobile ? (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
            style={{
              borderBottom: '1px solid #F7F8FC',
              borderRadius: 4,
              marginLeft: 24,
            }}
          >
            <Tab
              style={{ padding: 0, marginRight: 48 }}
              label={
                <span className={classes.tabLabel}>
                  Próximo agendamentos hoje (
                  {nextSchedules ? nextSchedules.length : 0}){' '}
                </span>
              }
              {...a11yProps(0)}
            />
            <Tab
              style={{ padding: 0 }}
              label={
                <span className={classes.tabLabel}>
                  Agendamentos Anteriores (
                  {previousSchedules && previousSchedules.length})
                </span>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        ) : (
          <>
            <IconButton onClick={(e): void => handleClick(e, 'menu')}>
              <Menu />
            </IconButton>
            {value === 0 &&
              ` Próximo agendamentos hoje (
                  ${
                    countPageCurrentAttendance && countPageCurrentAttendance - 1
                  }
                  )`}
            {value === 1 &&
              `Agendamentos Anteriores (${countPagePreviousAttendance || 0})`}
            <Popover
              open={Boolean(anchorElMenu)}
              anchorEl={anchorElMenu}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(0)}
                >
                  <Typography className={classes.textMenuItem}>
                    Próximo agendamentos hoje (
                    {countPageCurrentAttendance &&
                      countPageCurrentAttendance - 1}
                    )
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(1)}
                >
                  <Typography className={classes.textMenuItem}>
                    Agendamentos Anteriores ({countPagePreviousAttendance || 0})
                  </Typography>
                </MenuItem>
              </Box>
            </Popover>
          </>
        )}

        <Box>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box style={{ overflow: 'auto' }}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>NOME</StyledTableCell>
                    <StyledTableCell align="left">
                      CPF | RG | TITULO
                    </StyledTableCell>
                    <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                    <StyledTableCell align="left">STATUS</StyledTableCell>
                    <StyledTableCell align="left" colSpan={2}>
                      SERVIÇO
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingCurrentNext && <CircularProgress />}
                  {nextSchedules &&
                    nextSchedules.map((rowsSchedulesToday) => (
                      <StyledTableRow key={rowsSchedulesToday.ticket}>
                        <StyledTableCell component="th" scope="row">
                          {rowsSchedulesToday.nome}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {rowsSchedulesToday?.cpf
                            ? cpfMask(rowsSchedulesToday.cpf)
                            : rowsSchedulesToday?.tipo_documento === 'nenhum'
                            ? 'Não informado'
                            : rowsSchedulesToday?.documento}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {`${dateFormat(rowsSchedulesToday.data)} ${
                            rowsSchedulesToday.hora
                          }`}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.status}
                          align="left"
                        >
                          {rowsSchedulesToday.status}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {rowsSchedulesToday.servico.titulo}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {rowsSchedulesToday.para_dependente &&
                            rowsSchedulesToday.dependentes && (
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.buttonIcon}
                                onClick={() => {
                                  setSchedulesDependentCurrent(
                                    rowsSchedulesToday,
                                  )
                                  setOpenDependentsDialog(true)
                                }}
                                title="Dependentes"
                              >
                                <People />
                              </Button>
                            )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {!nextSchedules && !loadingCurrentNext && (
                    <h2>Nenhum agendamento para hoje</h2>
                  )}
                </TableBody>
              </Table>
            </Box>
            <PaginationService
              getSearch={agendamentosHoje}
              listSearch={{
                count: countPageCurrentAttendance,
                next: nextPageCurrentAttendance,
                previous: previousPageCurrentAttendance,
                current: currentPageCurrentAttendance,
                total_pages: totalPagesCurrentAttendance,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ width: '100%' }}
            >
              <Autocomplete
                id="controllable-states-demo"
                options={['Data', 'CPF']}
                value={filter}
                onChange={changeAutoComplete}
                style={{
                  width: !matchesMobile ? '100%' : 162,
                  marginBottom: 15,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Filtrar por"
                  />
                )}
              />
              {matchesMobile && (
                <IconButton>
                  <SyncAltRounded />
                </IconButton>
              )}
              {filter && filter === 'Data' && (
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture={false}
                    invalidDateMessage="A data informada é inválida"
                    InputProps={{
                      className: classes.menuItem,
                      classes: { adornedEnd: classes.adornedEnd },
                    }}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Selecione o dia"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{ position: 'end', color: '#000' }}
                    onChange={(date) => {
                      if (date && isValid(date)) {
                        handleDateChange(date)
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}

              {filter && filter === 'CPF' && (
                <>
                  <InputMask
                    value={valueFilter}
                    onChange={(event: any): void => {
                      if (event?.target?.value) {
                        setValueFilter(event?.target?.value)
                      }
                    }}
                    mask="999.999.999-99"
                    disabled={filter === undefined}
                  >
                    {() => (
                      <TextField
                        placeholder="Ex: 000.000.000-00"
                        disabled={filter === undefined}
                        style={{
                          width: !matchesMobile ? '100%' : 235,
                          marginBottom: 15,
                          marginRight: matchesMobile ? 15 : 0,
                        }}
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </>
              )}
              {!filter && (
                <Autocomplete
                  id="controllable-states-demo"
                  options={[{ name: '' }]}
                  getOptionLabel={(filter) => filter.name}
                  style={{
                    width: !matchesMobile ? '100%' : 235,
                    marginBottom: 15,
                    marginRight: matchesMobile ? 15 : 0,
                  }}
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="-------------"
                    />
                  )}
                />
              )}
              <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.buttonSearch}
                style={!matchesMobile ? { width: '100%', margin: 0 } : {}}
                onClick={searchUSer}
                startIcon={<Search />}
              >
                BUSCAR
              </Button>
            </Box>
            <Box style={{ overflow: 'auto' }}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="25%">NOME</StyledTableCell>
                    <StyledTableCell align="left">CPF</StyledTableCell>
                    <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                    <StyledTableCell align="left">STATUS</StyledTableCell>
                    <StyledTableCell align="left" colSpan={2}>
                      SERVIÇO
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingPrevious && <CircularProgress />}
                  {previousSchedules &&
                    previousSchedules.map((rowsSchedulesPreviou) => (
                      <Fragment key={rowsSchedulesPreviou.ticket}>
                        <StyledTableRow
                          style={{
                            borderBottomStyle: 'none',
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            {showDetails &&
                            showDetails === rowsSchedulesPreviou.ticket ? (
                              <Button
                                onClick={() => {
                                  setShowDetails(undefined)
                                }}
                              >
                                <ArrowDropDown />
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  setShowDetails(rowsSchedulesPreviou.ticket)
                                }}
                              >
                                <ArrowRight />
                              </Button>
                            )}

                            {rowsSchedulesPreviou.nome}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {cpfMask(rowsSchedulesPreviou.cpf)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {`${dateFormat(rowsSchedulesPreviou.data)} ${
                              rowsSchedulesPreviou.hora
                            }`}
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.status}
                            align="left"
                          >
                            {rowsSchedulesPreviou.status}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {rowsSchedulesPreviou.servico.titulo}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {rowsSchedulesPreviou.para_dependente &&
                              rowsSchedulesPreviou.dependentes && (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={classes.buttonIcon}
                                  onClick={() => {
                                    setSchedulesDependentCurrent(
                                      rowsSchedulesPreviou,
                                    )
                                    setOpenDependentsDialog(true)
                                  }}
                                  title="Dependentes"
                                >
                                  <People />
                                </Button>
                              )}
                          </StyledTableCell>
                        </StyledTableRow>

                        {showDetails &&
                          showDetails === rowsSchedulesPreviou.ticket && (
                            <StyledTableRow
                              key={rowsSchedulesPreviou.ticket}
                              style={{
                                borderTopStyle: 'dotted',
                                borderTopWidth: 1.4,
                              }}
                            >
                              <StyledTableCell>
                                <Typography
                                  className={classes.textTitleDetails}
                                  style={{ paddingLeft: 60 }}
                                >
                                  INICIO DO ATENDIMENTO
                                </Typography>
                                <Typography
                                  className={classes.textValueDetails}
                                  style={{ paddingLeft: 60 }}
                                >
                                  {rowsSchedulesPreviou.status ===
                                  'Não Compareceu'
                                    ? 'Não Compareceu'
                                    : `${
                                        rowsSchedulesPreviou.hora_inicio &&
                                        extractDateFormat(
                                          rowsSchedulesPreviou.hora_inicio,
                                        )
                                      } às ${
                                        rowsSchedulesPreviou.hora_inicio &&
                                        extractHourFormat(
                                          rowsSchedulesPreviou.hora_inicio,
                                        )
                                      }`}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  className={classes.textTitleDetails}
                                >
                                  FIM DO ATENDIMENTO
                                </Typography>
                                <Typography
                                  className={classes.textValueDetails}
                                >
                                  {rowsSchedulesPreviou.status ===
                                  'Não Compareceu'
                                    ? 'Não Compareceu'
                                    : `${extractDateFormat(
                                        rowsSchedulesPreviou.updated_at,
                                      )} às ${extractHourFormat(
                                        rowsSchedulesPreviou.updated_at,
                                      )}`}
                                </Typography>
                              </StyledTableCell>

                              {rowsSchedulesPreviou?.protocolo && (
                                <StyledTableCell>
                                  <Typography
                                    className={classes.textTitleDetails}
                                  >
                                    PROTOCOLO AGENDAMENTO
                                  </Typography>
                                  <Typography
                                    className={classes.textValueDetails}
                                  >
                                    {rowsSchedulesPreviou?.protocolo}
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {rowsSchedulesPreviou?.protocolo_atendimento && (
                                <StyledTableCell>
                                  <Typography
                                    className={classes.textTitleDetails}
                                  >
                                    PROTOCOLO ATENDIMENTO
                                  </Typography>
                                  <Typography
                                    className={classes.textValueDetails}
                                  >
                                    {
                                      rowsSchedulesPreviou?.protocolo_atendimento
                                    }
                                  </Typography>
                                </StyledTableCell>
                              )}

                              <StyledTableCell colSpan={3}>
                                <Typography
                                  className={classes.textTitleDetails}
                                >
                                  OBSERVAÇÕES:
                                </Typography>
                                {rowsSchedulesPreviou?.observacao_diario
                                  ?.length ? (
                                  rowsSchedulesPreviou.observacao_diario.map(
                                    (observacao, i) => (
                                      <Typography
                                        key={i}
                                        className={classes.textValueDetails}
                                      >
                                        {`(${extractDateFormat(
                                          observacao.updated_at,
                                        )} às ${extractHourFormat(
                                          observacao.updated_at,
                                        )}) - ${observacao.conteudo} `}
                                      </Typography>
                                    ),
                                  )
                                ) : (
                                  <Typography
                                    className={classes.textValueDetails}
                                  >
                                    Sem Observaçẽos
                                  </Typography>
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                      </Fragment>
                    ))}
                </TableBody>
              </Table>
            </Box>
            <PaginationService
              getSearch={agendamentosAnteriores}
              listSearch={{
                count: countPagePreviousAttendance,
                next: nextPagePreviousAttendance,
                previous: previousPagePreviousAttendance,
                current: currentPagePreviousAttendance,
                total_pages: totalPagesPreviousAttendance,
              }}
            />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  )
}
