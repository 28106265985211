import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { sendCodeForSISECI } from '../../services/auth'
import useStyles from './style'

export default function LoginCallback(): JSX.Element {
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    const url = (window as any).location.search
    const query = new URLSearchParams(url)
    const code: any = query.get('code')
    if (code) {
      sendCodeForSISECI(code, history)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box className={classes.main}>
      <img
        src="/assets/images/SupportAgent.svg"
        className={classes.icon}
        alt="logo Atendimento"
      />
      <Typography className={classes.text}>Atendimento</Typography>
      <CircularProgress className={classes.loading} />
    </Box>
  )
}
