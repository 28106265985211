import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useEffect, useState } from 'react'

import { ServiceResults } from '../../Pages/SearchSchedules'
import { useStateValue } from '../../providers/StateProvider'
import { getUnidadeServices } from '../../services/servicos'
import useStyles from './style'

export function useFormFilterAll() {
  const classes = useStyles()
  const [{ currentTicketOffice }] = useStateValue()
  const matchesMobile = useMediaQuery('(min-width:576px)')
  const [valueFilterDate, setValueFilterDate] = useState<undefined | string>(
    'Data',
  )
  const [selectedDate, handleDateChange] = useState<null | Date>(null)
  const [selectedDateInit, handleDateChangeInit] = useState<null | Date>(null)
  const [selectedDateEnd, handleDateChangeEnd] = useState<null | Date>(null)
  const [valueFilterHora, setValueFilterHora] = useState<undefined | string>(
    undefined,
  )
  const [serviceSelected, setServiceSelected] = useState<any>()
  const [servicesData, setServicesData] = useState<ServiceResults[]>()
  const [valueFilterCpf, setValueFilterCpf] = useState<undefined | string>()
  const [valueFilterStatus, setValueFilterStatus] = useState<string>('')
  const [valueFilterType, setValueFilterType] = useState<string>('')

  const getServices = async () => {
    const slug_unidade =
      currentTicketOffice?.setor?.unidade?.slug_unidade ||
      currentTicketOffice?.unidade?.slug_unidade

    if (!slug_unidade) return
    const { data } = await getUnidadeServices(slug_unidade)
    setServicesData(data)
  }

  useEffect(() => {
    getServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    matchesMobile,
    classes,
    valueFilterDate,
    setValueFilterDate,
    selectedDate,
    handleDateChange,
    selectedDateInit,
    handleDateChangeInit,
    selectedDateEnd,
    handleDateChangeEnd,
    valueFilterHora,
    setValueFilterHora,
    serviceSelected,
    setServiceSelected,
    servicesData,
    setServicesData,
    valueFilterCpf,
    setValueFilterCpf,
    valueFilterStatus,
    setValueFilterStatus,
    valueFilterType,
    setValueFilterType,
  }
}
