import React from 'react'

import { WebSocketProvider } from '../../context/WebSocketContext'
import { SchedulesContent } from './components'

export default function Schedules(): JSX.Element {
  return (
    <WebSocketProvider>
      <SchedulesContent />
    </WebSocketProvider>
  )
}
