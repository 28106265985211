import { makeStyles } from '@material-ui/core/styles'

export const painelDeSenhasStyles = makeStyles(() => ({
  main: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
}))

export default painelDeSenhasStyles
