import { useEffect, useState } from 'react'

function useScreenSize() {
  const [screenSize, setScreenSize] = useState<'HD' | 'FULL_HD' | 'OTHER'>(
    getScreenSize(),
  )
  useEffect(() => {
    function handleResize() {
      setScreenSize(getScreenSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  function getScreenSize() {
    const width = window.innerWidth
    const height = window.innerHeight

    //Descontando 150px da barra de tarefas
    if (width >= 1920 && height >= 945) {
      return 'FULL_HD'
    } else if (width >= 1280 && width < 1920 && height >= 585 && height < 945) {
      return 'HD'
    } else {
      return 'OTHER'
    }
  }

  return screenSize
}

export default useScreenSize
