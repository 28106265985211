import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useStateValue } from '../../providers/StateProvider'
import { getUserData as getUserDataRequest, logout } from '../../services/auth'
import { getGuiche } from '../../services/giche'
import { actionTypes } from '../../store/reducer'
import useStyles from './style'

export default function InitPage(): JSX.Element {
  const history = useHistory()
  const classes = useStyles()
  const [{ user, currentTicketOffice }, dispatch] = useStateValue()

  const [ticketOffices, setTicketOffices] = useState([])

  const [ticketOfficeSelected, setTicketOfficeSelected] = useState<string>()

  const token: string | null = sessionStorage.getItem('gov_access_token_sso')
  const tokenSiseci: string | null = sessionStorage.getItem(
    'gov_access_token_sso',
  )

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive)
      dispatch({
        type: actionTypes.SET_USER,
        user: data,
      })
      sessionStorage.setItem('gov_user_data', JSON.stringify(data))
    } catch (err) {
      console.log(err)
      logout()
    }
  }

  const handleSelectTicketOffice = async () => {
    if (ticketOfficeSelected) {
      const ticketOffice: any = ticketOffices.find(
        (ticketOff: any) => ticketOff?.id === parseInt(ticketOfficeSelected),
      )

      if (ticketOffice) {
        sessionStorage.setItem(
          'gov_ticket_office',
          JSON.stringify(ticketOffice),
        )

        dispatch({
          type: actionTypes.SET_TICKET_OFFICE,
          currentTicketOffice: ticketOffice,
        })

        if (ticketOffice?.pausado) {
          history.push('/agendamento/pause')
        } else {
          history.push('/home')
        }
      }
    }
  }

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const requestCurrentTicketOffice = async () => {
      try {
        const { data } = await getGuiche(tokenSiseci || '')
        if (data?.length) {
          setTicketOffices(data)
        } else {
          await logout()
        }
      } catch (error) {
        console.log(error)
      }
    }
    const ticket = sessionStorage.getItem('gov_ticket_office')
    if (user && !ticket) {
      requestCurrentTicketOffice()
    } else if (ticket) {
      dispatch({
        type: actionTypes.SET_TICKET_OFFICE,
        currentTicketOffice: JSON.parse(ticket),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    const ticketOffice = sessionStorage.getItem('gov_ticket_office')
    if (user && currentTicketOffice && ticketOffice) {
      const pathname = sessionStorage.getItem('pathname_redirect_gov')
      const spathname = pathname === '/' ? '/home' : pathname
      const dataTicketOffice = JSON.parse(ticketOffice)
      if (dataTicketOffice?.pausado) {
        history.push('/agendamento/pause')
      } else {
        history.push(spathname || '/home')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentTicketOffice])

  const getNameTicketOffices = (ticketOffices: any) => {
    return ticketOffices?.tipo === 'Diario'
      ? 'Espontâneo'
      : ticketOffices?.tipo === 'Consulta'
      ? 'Recepção'
      : ticketOffices?.tipo
  }

  return (
    <Box className={classes.main}>
      {ticketOffices.length > 0 ? (
        <>
          <Typography variant="h5" className={classes.text}>
            Selecione o guichê
          </Typography>
          <Box>
            <select
              onChange={(e: any) => setTicketOfficeSelected(e?.target?.value)}
              className={classes.selectTicket}
            >
              <option value={''}> Selecione </option>
              {ticketOffices.map((ticket: any, i: any) => (
                <option key={i} value={ticket?.id}>{`GUICHÊ ${
                  ticket?.ordem
                } : ${ticket?.nome || ''}  ${
                  ticket?.setor?.setor?.nome || 'Misto'
                } - ${getNameTicketOffices(ticket)} -
            ${ticket?.setor?.unidade?.nome || ticket?.unidade?.nome || ''}
            ${ticket?.pausado ? '(PAUSADO)' : ''}`}</option>
              ))}
            </select>
          </Box>
          {ticketOfficeSelected && (
            <Box>
              <button
                className={classes.btnSelectTicket}
                onClick={handleSelectTicketOffice}
              >
                Selecionar
              </button>
            </Box>
          )}
        </>
      ) : (
        <>
          <img
            src="/assets/images/SupportAgent.svg"
            className={classes.icon}
            alt="logo Atendimento"
          />
          <Typography className={classes.text}>Atendimento</Typography>
          <CircularProgress className={classes.loading} />
        </>
      )}
    </Box>
  )
}
