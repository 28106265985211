import React from 'react'

import { Props } from '../../types/icons'

const ClockIcon = ({ styles = { width: '80', height: '81' } }: Props) => {
  return (
    <svg
      style={styles}
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.9995 18.5002V38.5002L45.9995 46.5002M42.0675 74.2722C34.539 75.1279 26.9324 73.5863 20.331 69.8671C13.7297 66.1478 8.47011 60.4406 5.30134 53.558C2.13258 46.6754 1.2162 38.9685 2.6827 31.5348C4.1492 24.1011 7.92381 17.3196 13.469 12.1561C19.0142 6.99259 26.0472 3.71034 33.5664 2.77678C41.0857 1.84322 48.7078 3.30594 55.3473 6.95664C61.9868 10.6073 67.3052 16.2599 70.545 23.1093C73.7849 29.9587 74.781 37.6557 73.3915 45.1042M57.9995 78.5002L77.9995 66.5002L57.9995 54.5002V78.5002Z"
        stroke={styles.color || '#4F46E5'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ClockIcon
