import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Close from '@material-ui/icons/Close'
import PersonRounded from '@material-ui/icons/PersonRounded'
import React from 'react'

import { Agendamento } from '../../../../interfaces/agendamento'
import { cpfMask } from '../../../../utils/cpfFormat'
import dateFormat from '../../../../utils/dateFormat'
import useStyles from './style'

interface Props {
  openDialog: boolean
  handleClose: () => void
  schedule: Agendamento
  onActionButton: (value: boolean) => void
}

const DialogAntecipate = ({
  openDialog,
  handleClose,
  schedule,
  onActionButton,
}: Props) => {
  const classes = useStyles()
  const matchesMobile = useMediaQuery('(min-width:576px)')

  const handleCancelSchedule = async () => {
    handleClose()
    onActionButton(true)
  }

  return (
    <Dialog
      fullWidth
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Antecipar agendamento
        </Typography>
        <IconButton onClick={handleClose} className={classes.buttonClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />

      {schedule && (
        <DialogContent>
          <PersonRounded fontSize="large" color="primary" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {schedule?.user?.first_name || schedule?.nome}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {schedule?.cpf?.length > 0
                ? cpfMask(schedule.cpf)
                : schedule?.user && cpfMask(schedule?.user?.username)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {schedule.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              data/hora
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(schedule.data)} às ${schedule.hora}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>status</Typography>
            <Typography className={classes.textModal}>
              {schedule.status}
            </Typography>
          </Box>
          <Divider light />
          <Box>
            <Typography className={classes.textModalTitle}>
              Deseja antecipar o agendamento?
            </Typography>
            <Typography className={classes.textModal}>
              O agendamento atual será cancelado e um agendamento espontâneo
              será criado.
            </Typography>
          </Box>
          <Box className={classes.buttonCloseModal}>
            <Button
              className={classes.close}
              onClick={() => {
                handleClose()
                onActionButton(false)
              }}
              style={
                !matchesMobile
                  ? { width: '100%', textAlign: 'center', padding: '15px 0' }
                  : {}
              }
            >
              fechar
            </Button>
            {matchesMobile && (
              <Button
                style={{ color: '#FFF', fontSize: 15 }}
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCancelSchedule}
              >
                Antecipar
              </Button>
            )}
          </Box>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default DialogAntecipate
