import 'react-toastify/dist/ReactToastify.css'

import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { RouteLayout } from './Components'
import BlankLayout from './Components/Layouts/BlankLayout'
import WithHeader from './Components/Layouts/WithHeader'
import AttendanceOnlineChat from './Pages/AttendanceOnlineChat'
import Attendances from './Pages/Attendances'
import FrameSchedule from './Pages/FrameSchedule'
import Home from './Pages/Home'
import InitPage from './Pages/InitPage'
import LoginCallback from './Pages/LoginCallback'
import MetabaseIframe from './Pages/MetabaseIframe'
import OrganicSchedules from './Pages/OrganicSchedules'
import PaineDeSenhas from './Pages/PainelDeSenhas'
import PausePage from './Pages/PausePage'
import PrintScheduler from './Pages/PrintScheduler'
import Schedules from './Pages/Schedules'
import SearchSchedules from './Pages/SearchSchedules'
import SetTicketOffice from './Pages/SetTicketOffice'

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/login/callback/" component={LoginCallback} />
          <RouteLayout
            path="/"
            exact
            component={InitPage}
            layout={BlankLayout}
            isAuth
          />
          <RouteLayout
            path="/agendamento/pause"
            exact
            component={PausePage}
            layout={BlankLayout}
            isAuth
          />
          <RouteLayout
            path="/select-ticket-office"
            exact
            component={SetTicketOffice}
            layout={BlankLayout}
            isAuth
          />
          <RouteLayout
            path="/home"
            exact
            component={Home}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/painel-de-senhas"
            exact
            component={PaineDeSenhas}
            layout={BlankLayout}
            isAuth
          />
          <RouteLayout
            path="/agendamento"
            exact
            component={Schedules}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/agendamento_interno"
            exact
            component={Schedules}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/consulta_agendamento"
            exact
            component={SearchSchedules}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/atendimento"
            exact
            component={Attendances}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/atendimento/:id"
            exact
            component={AttendanceOnlineChat}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/atendimento_diario"
            exact
            component={OrganicSchedules}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/imprimir_agendamento"
            exact
            component={PrintScheduler}
            layout={BlankLayout}
          />
          <RouteLayout
            path="/fala/:dataAge/:cpfCidadao"
            exact
            component={FrameSchedule}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/metabase"
            exact
            component={MetabaseIframe}
            layout={WithHeader}
            isAuth
          />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </>
  )
}

export default App
