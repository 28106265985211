import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import Close from '@material-ui/icons/Close'
import HelpIcon from '@material-ui/icons/Help'
import PersonRounded from '@material-ui/icons/PersonRounded'
import { useState } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AgendamentoDiario } from '../../../../interfaces/agendamento'
import {
  addObservationAgendamentoDiario,
  updateStatusAgendamentoDiario,
} from '../../../../services/agendamentoOrganico'
import { cpfMask } from '../../../../utils/cpfFormat'
import { dateFormat } from '../../../../utils/dateFormat'
import useStyles from './style'

interface Props {
  openAttendanceCancel: boolean
  setOpenAttendanceCancel: (openAttendanceCancel: boolean) => void
  schedule: undefined | AgendamentoDiario
}

export default function AttendanceDialogCancel({
  setOpenAttendanceCancel,
  openAttendanceCancel,
  schedule,
}: Props): JSX.Element {
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [observation, setObservation] = useState(undefined)
  const [isOk, setIsOk] = useState<boolean>(false)
  const history = useHistory()

  const handleClose = () => {
    if (isOk) {
      return history.go(0)
    }
    setOpenAttendanceCancel(false)
  }

  const handleFinishAttendance = async (scheduleId: any) => {
    try {
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id

      setLoading(true)
      if (token) {
        const { status } = await updateStatusAgendamentoDiario(
          token,
          scheduleId,
          'Não Compareceu',
          guiche,
        )
        if (status === 200 && !!observation) {
          await addObservationAgendamentoDiario(
            token,
            scheduleId,
            observation || '',
          )
        }
        setIsOk(true)
      }
    } catch (error) {
      console.log('Erro', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullWidth
      open={openAttendanceCancel}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isOk ? (
        <DialogTitle className={classes.header}>
          Atendimento Concluído com sucesso!
        </DialogTitle>
      ) : (
        <DialogTitle className={classes.header}>
          <IconButton onClick={handleClose} className={classes.buttonClose}>
            <Close />
          </IconButton>
          <Typography className={classes.textHeaderModal}>
            Deseja informar o não comparecimento do cidadão?
          </Typography>
        </DialogTitle>
      )}

      {isOk ? (
        <>
          <DialogContent>
            <CheckCircleOutline
              style={{
                fontSize: 60,
                color: '#94C120',
                textAlign: 'center',
                width: '100%',
              }}
            />
            {schedule && (
              <>
                <Box style={{ textAlign: 'center' }}>
                  <Box className={classes.titleDependents}>
                    <Typography className={classes.textModalTitleUDependent}>
                      {schedule.nome}
                    </Typography>
                    <Typography className={classes.textModalTitleUDependentCPF}>
                      {cpfMask(schedule.cpf)}
                    </Typography>
                  </Box>
                </Box>
                <Box mb={3} style={{ textAlign: 'center' }}>
                  <Typography className={classes.textModalTitleServico}>
                    serviço
                  </Typography>
                  <Typography className={classes.textModalServico}>
                    {schedule.servico.titulo}
                  </Typography>
                </Box>
                {schedule?.protocolo_atendimento && (
                  <Box mb={3} style={{ textAlign: 'center' }}>
                    <Typography className={classes.textModalTitleServico}>
                      Protocolo Atendimento
                    </Typography>
                    <Typography className={classes.textModalServico}>
                      {schedule.protocolo_atendimento}
                    </Typography>
                  </Box>
                )}
                <Box mb={3} style={{ textAlign: 'center' }}>
                  <Typography className={classes.textModalTitleServico}>
                    Status
                  </Typography>
                  <Typography className={classes.textModalServico}>
                    Não compareceu
                  </Typography>
                </Box>
              </>
            )}
            <Divider light />
            <Button
              style={{
                color: '#FFF',
                fontSize: 14,
                float: 'right',
                marginTop: 20,
              }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleClose}
            >
              FECHAR
            </Button>
          </DialogContent>
        </>
      ) : (
        <>
          {schedule && (
            <DialogContent>
              <PersonRounded fontSize="large" color="primary" />
              <Box className={classes.titleDependents}>
                <Typography className={classes.textModalTitleUDependent}>
                  {schedule.nome}
                </Typography>
                <Typography className={classes.textModalTitleUDependentCPF}>
                  {cpfMask(schedule.cpf)}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography className={classes.textModalTitle}>
                  serviço
                </Typography>
                <Typography className={classes.textModal}>
                  {schedule.servico.titulo}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography className={classes.textModalTitle}>
                  data/hora
                </Typography>
                <Typography className={classes.textModal}>
                  {`${dateFormat(schedule.data)} às ${schedule.hora}`}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography className={classes.textModalTitle}>
                  status
                </Typography>
                <Typography className={classes.textModal}>
                  {schedule.status}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography className={classes.textModalTitle}>
                  observações: (opcional)
                  <Tooltip title="As informações deste campo serão compartilhadas no histórico do cidadão.">
                    <span>
                      <IconButton disabled>
                        <HelpIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={5}
                  variant="outlined"
                  margin="normal"
                  disabled={loading}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      fontFamily: 'Roboto',
                      fontWeight: 400,
                    },
                    placeholder:
                      'As informações deste campo serão compartilhadas no histórico do cidadão.',
                  }}
                  style={{ width: '100%', marginBottom: 10 }}
                  value={observation}
                  onChange={(e: any) => {
                    setObservation(e?.target?.value || '')
                  }}
                />
              </Box>
              <Divider light />
              <div className={classes.buttonCloseModal}>
                {!loading && (
                  <Link className={classes.link} onClick={handleClose}>
                    fechar
                  </Link>
                )}
                <Button
                  style={{ color: '#FFF', fontSize: 12, flex: 0.5 }}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={() => handleFinishAttendance(schedule.id)}
                  disabled={loading}
                >
                  {!loading ? 'CONFIRMAR' : <CircularProgress />}
                </Button>
              </div>
            </DialogContent>
          )}
        </>
      )}
    </Dialog>
  )
}
