import { useState } from 'react'

import { NewsResponse } from '../../../../interfaces/news'
import { TicketOffice } from '../../../../interfaces/ticketOffice'
import { getNews } from '../../../../services/news'
import { postSenhaChamada } from '../../../../services/senhas'
import { speakText } from '../../../../utils/speakText'
import { WebSocketResponse } from '../../types/ws'

export const useBodyPainelDeSenhas = () => {
  const [contador, setContador] = useState<number>(0)
  const [iniciarContador, setIniciarContador] = useState<boolean>(false)
  const [senhaAtual, setSenhaAtual] = useState<WebSocketResponse | null>(null)
  const [senhasChamadas, setSenhasChamadas] = useState<WebSocketResponse[]>()
  const [filaDeSenhas, setFilaDeSenhas] = useState<WebSocketResponse[]>()
  const [noticias, setNoticias] = useState<NewsResponse[]>([])
  const [mostrarNoticias, setMostrarNoticias] = useState<boolean>(false)

  const SenhaAtual = localStorage.getItem('SenhaAtual')
  const StorageDate = localStorage.getItem('StorageDate')
  const FilaDeSenhas = localStorage.getItem('FilaDeSenhas')
  const SenhasChamadas = localStorage.getItem('SenhasChamadas')

  const handleGetNews = async () => {
    const response = await getNews()
    if (response) {
      setNoticias(response)
    }
  }

  const handlePostSenhaChamada = async (schedule: WebSocketResponse) => {
    try {
      const guiche = sessionStorage.getItem('gov_ticket_office')
      if (guiche) {
        const guicheParsed: TicketOffice = JSON.parse(guiche)
        await postSenhaChamada({
          agendamento_id: `${schedule.payload.agendamento_id}`,
          guiche_id: schedule.payload.guiche_id,
          slug_unidade: guicheParsed.setor
            ? guicheParsed.setor.unidade.slug_unidade
            : guicheParsed.unidade
            ? guicheParsed.unidade.slug_unidade
            : '',
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSetSenhaAtual = (wsResponse: WebSocketResponse) => {
    try {
      setMostrarNoticias(false)
      if (filaDeSenhas && filaDeSenhas.length > 0) {
        setFilaDeSenhas((prevFilaDeSenhas) => {
          // Verificar se o wsResponse já está em senhaAtual
          const jaEmSenhaAtual =
            senhaAtual?.payload.senha === wsResponse.payload.senha

          // Se já estiver em senhaAtual, não adicionar à fila
          if (jaEmSenhaAtual) {
            return prevFilaDeSenhas
          }

          // Verificar se o wsResponse já está na fila
          const jaNaFila = prevFilaDeSenhas?.some(
            (senha) => senha.payload.senha === wsResponse.payload.senha,
          )

          // Se não estiver na fila, adicionar ao final
          if (!jaNaFila) {
            return prevFilaDeSenhas
              ? [...prevFilaDeSenhas, wsResponse]
              : [wsResponse]
          }

          // Se já estiver na fila, retornar a fila sem alterações
          return prevFilaDeSenhas
        })
      }

      if (contador === 0 || contador >= 15) {
        // Inicializar o tempo
        setContador(0)
        setIniciarContador(true)
        const dataAtual = new Date()
        const dataFormatada = dataAtual.toISOString().split('T')[0]
        localStorage.setItem('StorageDate', JSON.stringify(dataFormatada))

        // Verificar se há uma senha atual
        if (senhaAtual) {
          if (senhaAtual.payload.senha !== wsResponse.payload.senha) {
            // Mover a senha atual para o primeiro item de senhasChamadas
            setSenhasChamadas((prevSenhasChamadas) => {
              const novaLista = prevSenhasChamadas
                ? [senhaAtual, ...prevSenhasChamadas]
                : [senhaAtual]
              localStorage.setItem('SenhasChamadas', JSON.stringify(novaLista))
              return novaLista
            })
          }
        }

        // Setar a nova senha atual
        speakText(wsResponse)
        setSenhaAtual(wsResponse)
        handlePostSenhaChamada(wsResponse)
        localStorage.setItem('SenhaAtual', JSON.stringify(wsResponse))
        setSenhasChamadas((prev) => {
          return prev
            ? prev.filter(
                (senha) => senha.payload.senha !== wsResponse.payload.senha,
              )
            : []
        })

        // Remover wsResponse da fila, se estiver presente
        setFilaDeSenhas((prevFilaDeSenhas) => {
          const novaFila = prevFilaDeSenhas
            ? prevFilaDeSenhas.filter(
                (senha) => senha.payload.senha !== wsResponse.payload.senha,
              )
            : []
          localStorage.setItem('FilaDeSenhas', JSON.stringify(novaFila))
          return novaFila
        })
      } else {
        // Adicionar wsResponse ao último lugar da fila
        setFilaDeSenhas((prevFilaDeSenhas) => {
          // Verificar se o wsResponse já está em senhaAtual
          const jaEmSenhaAtual =
            senhaAtual?.payload.senha === wsResponse.payload.senha

          // Se já estiver em senhaAtual, não adicionar à fila
          if (jaEmSenhaAtual) {
            localStorage.setItem(
              'FilaDeSenhas',
              JSON.stringify(prevFilaDeSenhas),
            )
            return prevFilaDeSenhas
          }

          // Verificar se o wsResponse já está na fila
          const jaNaFila = prevFilaDeSenhas?.some(
            (senha) => senha.payload.senha === wsResponse.payload.senha,
          )

          // Se não estiver na fila, adicionar ao final
          if (!jaNaFila) {
            const novaFila = prevFilaDeSenhas
              ? [...prevFilaDeSenhas, wsResponse]
              : [wsResponse]
            localStorage.setItem('FilaDeSenhas', JSON.stringify(novaFila))
            return novaFila
          }

          // Se já estiver na fila, retornar a fila sem alterações
          localStorage.setItem('FilaDeSenhas', JSON.stringify(prevFilaDeSenhas))
          return prevFilaDeSenhas
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    contador,
    noticias,
    SenhaAtual,
    senhaAtual,
    StorageDate,
    setContador,
    FilaDeSenhas,
    filaDeSenhas,
    setSenhaAtual,
    handleGetNews,
    SenhasChamadas,
    senhasChamadas,
    mostrarNoticias,
    setFilaDeSenhas,
    iniciarContador,
    setSenhasChamadas,
    setMostrarNoticias,
    setIniciarContador,
    handleSetSenhaAtual,
    handlePostSenhaChamada,
  }
}
