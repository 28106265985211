import React from 'react'

import { Props } from '../../types/icons'

const TemperatureIcon = ({
  styles = { width: '18', height: '18', color: '#6B7280' },
}: Props) => {
  return (
    <svg
      style={styles}
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33268 6.5H6.66602M3.33268 10.25C2.69721 10.6169 2.20056 11.1832 1.91975 11.8611C1.63895 12.5391 1.58968 13.2907 1.7796 13.9995C1.96951 14.7083 2.388 15.3346 2.97015 15.7813C3.55229 16.228 4.26557 16.4701 4.99935 16.4701C5.73313 16.4701 6.44641 16.228 7.02856 15.7813C7.6107 15.3346 8.02919 14.7083 8.2191 13.9995C8.40902 13.2907 8.35976 12.5391 8.07895 11.8611C7.79814 11.1832 7.30149 10.6169 6.66602 10.25V3.16667C6.66602 2.72464 6.49042 2.30072 6.17786 1.98816C5.8653 1.67559 5.44138 1.5 4.99935 1.5C4.55732 1.5 4.1334 1.67559 3.82084 1.98816C3.50828 2.30072 3.33268 2.72464 3.33268 3.16667V10.25Z"
        stroke={styles.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TemperatureIcon
