import Box from '@material-ui/core/Box'
import React, { useEffect } from 'react'

import { useWebSocketContext } from '../../../../context/WebSocketContext'
import { speakText } from '../../../../utils/speakText'
import { WebSocketResponse } from '../../types/ws'
import PainelComSenha from './components/Views/ComSenha'
import bodyPainelDeSenhasStyles from './style'
import { Props } from './types/index'
import { useBodyPainelDeSenhas } from './useBodyPainelDeSenhas'

const BodyPainelDeSenhas = ({ GuicheSelecionado }: Props) => {
  const styles = bodyPainelDeSenhasStyles()
  const { wsResponse } = useWebSocketContext()
  const {
    contador,
    noticias,
    SenhaAtual,
    senhaAtual,
    StorageDate,
    setContador,
    FilaDeSenhas,
    filaDeSenhas,
    setSenhaAtual,
    handleGetNews,
    SenhasChamadas,
    senhasChamadas,
    mostrarNoticias,
    setFilaDeSenhas,
    iniciarContador,
    setSenhasChamadas,
    setIniciarContador,
    setMostrarNoticias,
    handleSetSenhaAtual,
    handlePostSenhaChamada,
  } = useBodyPainelDeSenhas()

  useEffect(() => {
    if (wsResponse) {
      handleSetSenhaAtual(wsResponse)
    }
  }, [wsResponse])

  useEffect(() => {
    handleGetNews()
    // Buscando a cada 2 minutos
    const interval = setInterval(() => {
      handleGetNews()
    }, 120000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (senhasChamadas && senhaAtual) {
      const removerDuplicatas = (senhas: any) => {
        const senhasUnicas: any = []
        const senhasSet = new Set()

        senhas.forEach((senha: any) => {
          if (
            !senhasSet.has(senha.payload.senha) &&
            senha.payload.senha !== senhaAtual.payload.senha
          ) {
            senhasSet.add(senha.payload.senha)
            senhasUnicas.push(senha)
          }
        })

        return senhasUnicas
      }

      const senhasSemDuplicatas = removerDuplicatas(senhasChamadas)
      if (senhasSemDuplicatas.length !== senhasChamadas.length) {
        setSenhasChamadas(senhasSemDuplicatas)
        localStorage.setItem(
          'SenhasChamadas',
          JSON.stringify(senhasSemDuplicatas),
        )
      }
    }
  }, [senhasChamadas, senhaAtual])

  useEffect(() => {
    const dataAtual = new Date()
    const dataFormatada = dataAtual.toISOString().split('T')[0]
    if (StorageDate === JSON.stringify(dataFormatada)) {
      if (SenhaAtual) {
        const senha_atual: WebSocketResponse = JSON.parse(SenhaAtual)
        handlePostSenhaChamada(senha_atual)
        setSenhaAtual(senha_atual)
        speakText(senha_atual)
      }
      if (SenhasChamadas) {
        const senhas_chamadas: WebSocketResponse[] = JSON.parse(SenhasChamadas)
        setSenhasChamadas(senhas_chamadas)
      }
      if (FilaDeSenhas) {
        const fila_de_senhas: WebSocketResponse[] = JSON.parse(FilaDeSenhas)
        setFilaDeSenhas(fila_de_senhas)
      }
    } else {
      localStorage.removeItem('SenhaAtual')
      localStorage.removeItem('StorageDate')
      localStorage.removeItem('FilaDeSenhas')
      localStorage.removeItem('SenhasChamadas')
    }
  }, [])

  useEffect(() => {
    const SenhaAtualCopy = localStorage.getItem('SenhaAtual')
    const FilaDeSenhasCopy = localStorage.getItem('FilaDeSenhas')
    const SenhasChamadasCopy = localStorage.getItem('SenhasChamadas')

    if (!SenhaAtualCopy) {
      setSenhaAtual(null)
    }
    if (!SenhasChamadasCopy) {
      setSenhasChamadas([])
    }
    if (!FilaDeSenhasCopy) {
      setFilaDeSenhas([])
    }
  }, [GuicheSelecionado])

  useEffect(() => {
    if (contador >= 15) {
      if (noticias && noticias.length > 0) {
        if (filaDeSenhas && filaDeSenhas.length === 0) {
          if (senhaAtual) {
            setSenhasChamadas((prev) => {
              const novaLista = prev ? [senhaAtual, ...prev] : [senhaAtual]
              localStorage.setItem('SenhasChamadas', JSON.stringify(novaLista))
              return novaLista
            })
            setSenhaAtual(null)
            localStorage.removeItem('SenhaAtual')
            setMostrarNoticias(true)
          }
        }
      }
    }
  }, [contador, noticias, filaDeSenhas, senhaAtual])

  useEffect(() => {
    if (!iniciarContador) {
      if (contador <= 0 && filaDeSenhas && filaDeSenhas.length > 0) {
        handleSetSenhaAtual(filaDeSenhas[0])
      }
    } else {
      if (contador >= 15 && filaDeSenhas && filaDeSenhas.length > 0) {
        handleSetSenhaAtual(filaDeSenhas[0])
      }
    }
  }, [contador, senhaAtual, filaDeSenhas])

  useEffect(() => {
    if (iniciarContador) {
      const interval = setInterval(() => {
        setContador((prevContador) => {
          if (prevContador >= 15) {
            setIniciarContador(false)
            clearInterval(interval)
            return prevContador
          }
          return prevContador + 1
        })
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [iniciarContador])

  return (
    <Box className={styles.main}>
      <PainelComSenha
        Noticias={noticias}
        SenhaAtual={senhaAtual}
        MostrarNoticias={mostrarNoticias}
        SenhasChamadas={senhasChamadas || []}
      />
    </Box>
  )
}

export default BodyPainelDeSenhas
