import { useState } from 'react'

import { TicketOffice } from '../../interfaces/ticketOffice'
import { logout } from '../../services/auth'
import { getGuiche } from '../../services/giche'

export const usePainelDeSenhas = () => {
  const [buscandoGuiche, setBuscandoGuiche] = useState<boolean>(false)
  const tokenSiseci: string | null = sessionStorage.getItem(
    'gov_access_token_sso',
  )
  const [guichesDisponiveis, setGuichesDisponiveis] = useState<TicketOffice[]>(
    [],
  )
  const [guicheSelecionado, setGuicheSelecionado] =
    useState<TicketOffice | null>(null)

  const handleGetGuiche = async () => {
    try {
      setBuscandoGuiche(true)
      const { data } = await getGuiche(tokenSiseci || '')
      const guicheData: TicketOffice[] = data

      if (guicheData.length > 0) {
        setGuichesDisponiveis(guicheData)
      } else {
        await logout()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setBuscandoGuiche(false)
    }
  }

  return {
    buscandoGuiche,
    handleGetGuiche,
    guichesDisponiveis,
    guicheSelecionado,
    setGuicheSelecionado,
  }
}
