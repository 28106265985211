import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import React, { useEffect, useMemo, useState } from 'react'

import { abreviarNome } from '../../../../../../../utils/abreviarNomes'
import { extrairGuiche } from '../../../../../../../utils/extrairGuiche'
import { extrairSenha } from '../../../../../../../utils/extrairSenha'
import ClockIcon from '../../../../../Icons/Clock'
import PersonIcon from '../../../../../Icons/Person'
import TicketIcon from '../../../../../Icons/Ticket'
import PausePanel from '../../PausePanel'
import PainelSemSenha from '../SemSenha/index'
import painelComSenhaStyles from './style'
import { Props } from './types/index'

const PainelComSenha = ({
  SenhaAtual,
  SenhasChamadas,
  Noticias,
  MostrarNoticias,
}: Props) => {
  const styles = painelComSenhaStyles()
  const isFullHd = useMediaQuery('(min-width:1920px)')
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const itensPorTela = useMemo(() => {
    const alturaItem = 100
    return Math.floor(windowHeight / alturaItem)
  }, [windowHeight])

  const senhasParaMostrar = SenhasChamadas.slice(3, itensPorTela)

  return (
    <Box className={styles.main}>
      <Box className={styles.containerEsquerdo}>
        {SenhaAtual ? (
          <Box
            className={styles.esquerdoTop}
            style={{
              backgroundImage:
                SenhaAtual.payload.prioridade &&
                SenhaAtual.payload.prioridade_alta
                  ? 'url(/assets/images/city-vermelho.webp)'
                  : SenhaAtual.payload.prioridade
                  ? 'url(/assets/images/city-azul.webp)'
                  : 'url(/assets/images/city-verde.webp)',
            }}
          >
            <Box
              className={styles.titleContainer}
              style={{
                backgroundColor:
                  SenhaAtual.payload.prioridade &&
                  SenhaAtual.payload.prioridade_alta
                    ? '#DC2626'
                    : SenhaAtual.payload.prioridade
                    ? '#2563EB'
                    : '#059669',
              }}
            >
              <TicketIcon />
              <Typography
                variant="button"
                className={isFullHd ? styles.title : styles.titleSmall}
              >
                Senha chamada
              </Typography>
            </Box>

            <Box className={styles.esqurdoConteudoSenha}>
              <Box className={styles.esqurdoSenha}>
                <Typography
                  variant="h6"
                  className={styles.titleSenha}
                  style={{
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                >
                  {extrairSenha(SenhaAtual.payload.senha)}
                </Typography>
                <FiberManualRecordIcon
                  className={styles.titleFiberSenha}
                  style={{
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                />
                <Typography
                  variant="h6"
                  className={styles.titleSenha}
                  style={{
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                >
                  {extrairGuiche(SenhaAtual.payload.guiche)}
                </Typography>
              </Box>
              <Box className={styles.esquerdoUser}>
                <PersonIcon
                  styles={{
                    width: '42px',
                    height: '42px',
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                />
                <Typography
                  variant="body1"
                  className={styles.titleUser}
                  style={{
                    textAlign: 'center',
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                >
                  {abreviarNome(SenhaAtual.payload.nome)}
                </Typography>
              </Box>
              <Typography
                variant="h4"
                style={{
                  color:
                    SenhaAtual.payload.prioridade &&
                    SenhaAtual.payload.prioridade_alta
                      ? '#7F1D1D'
                      : SenhaAtual.payload.prioridade
                      ? '#1E3A8A'
                      : '#064E3B',
                }}
              >
                {SenhaAtual.payload.servico_titulo}
              </Typography>
              <Box
                className={styles.prioridade}
                style={{
                  padding: '2px 12px 2px 8px',
                  borderRadius: '50px',
                  border: `2px solid ${
                    SenhaAtual.payload.prioridade &&
                    SenhaAtual.payload.prioridade_alta
                      ? '#7F1D1D'
                      : SenhaAtual.payload.prioridade
                      ? '#1E3A8A'
                      : '#064E3B'
                  }`,
                }}
              >
                <FiberManualRecordIcon
                  className={styles.FiberTipoSenha}
                  style={{
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                />
                <Typography
                  variant="h4"
                  style={{
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                >
                  {SenhaAtual.payload.prioridade &&
                  SenhaAtual.payload.prioridade_alta
                    ? 'Prioridade Alta'
                    : SenhaAtual.payload.prioridade
                    ? 'Prioridade'
                    : 'Normal'}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : Noticias.length === 0 || !MostrarNoticias ? (
          <Box className={styles.esquerdoTop}>
            <Box
              className={styles.titleContainer}
              style={{
                backgroundColor: '#4B5563',
              }}
            >
              <TicketIcon />
              <Typography
                variant="button"
                className={isFullHd ? styles.title : styles.titleSmall}
              >
                Senha Chamada
              </Typography>
            </Box>
            <PainelSemSenha />
          </Box>
        ) : (
          Noticias.length > 0 &&
          MostrarNoticias && (
            <Box className={styles.esquerdoTop}>
              <PausePanel newsResponse={Noticias} />
            </Box>
          )
        )}

        <Box className={styles.esquerdoBotton}>
          {SenhasChamadas.slice(0, 3).map((senha, index) => (
            <Box
              key={index}
              className={styles.esquerdoBottonItem}
              style={{
                backgroundImage:
                  senha.payload.prioridade && senha.payload.prioridade_alta
                    ? 'url(/assets/images/city-vermelho.webp)'
                    : senha.payload.prioridade_alta
                    ? 'url(/assets/images/city-azul.webp)'
                    : 'url(/assets/images/city-verde.webp)',
              }}
            >
              <Box
                className={styles.titleContainer}
                style={{
                  backgroundColor:
                    senha.payload.prioridade && senha.payload.prioridade_alta
                      ? '#DC2626'
                      : senha.payload.prioridade
                      ? '#2563EB'
                      : '#059669',
                }}
              >
                <TicketIcon />
                <Typography
                  variant="button"
                  className={isFullHd ? styles.title : styles.titleSmall}
                >
                  {index === 0 && 'Última Chamada'}
                  {index === 1 && 'Penúltima Chamada'}
                  {index === 2 && 'Antepenúltima Chamada'}
                </Typography>
              </Box>

              <Box className={styles.esqurdoConteudoSenha}>
                <Box className={styles.esqurdoSenha}>
                  <Typography
                    variant="h4"
                    className={
                      isFullHd
                        ? styles.titleSenhaBottonItem
                        : styles.titleSenhaBottonItemSmall
                    }
                    style={{
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                    }}
                  >
                    {extrairSenha(senha.payload.senha)}
                  </Typography>
                  <FiberManualRecordIcon
                    className={styles.titleFiberSenha}
                    style={{
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                    }}
                  />
                  <Typography
                    variant="h4"
                    className={
                      isFullHd
                        ? styles.titleSenhaBottonItem
                        : styles.titleSenhaBottonItemSmall
                    }
                    style={{
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                    }}
                  >
                    {extrairGuiche(senha.payload.guiche)}
                  </Typography>
                </Box>
                <Box className={styles.esquerdoUser}>
                  <PersonIcon
                    styles={{
                      width: isFullHd ? '22px' : '17px',
                      height: isFullHd ? '22px' : '17px',
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                    }}
                  />
                  <Typography
                    variant="body1"
                    className={
                      isFullHd
                        ? styles.titleUserBottomIcon
                        : styles.titleUserBottomIconSmall
                    }
                    style={{
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                    }}
                  >
                    {abreviarNome(senha.payload.nome)}
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  style={{
                    color:
                      senha.payload.prioridade && senha.payload.prioridade_alta
                        ? '#7F1D1D'
                        : senha.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                    fontSize: isFullHd ? '26px' : '18px',
                  }}
                >
                  {senha.payload.servico_titulo}
                </Typography>
                <Box
                  className={styles.prioridade}
                  style={{
                    padding: '2px 12px 2px 8px',
                    borderRadius: '50px',
                    border: `2px solid ${
                      senha.payload.prioridade && senha.payload.prioridade_alta
                        ? '#7F1D1D'
                        : senha.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B'
                    }`,
                  }}
                >
                  <FiberManualRecordIcon
                    className={styles.FiberTipoSenha}
                    style={{
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                    }}
                  />
                  <Typography
                    variant="h4"
                    style={{
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                      fontSize: isFullHd ? '25px' : '15px',
                    }}
                  >
                    {senha.payload.prioridade && senha.payload.prioridade_alta
                      ? 'Prioridade Alta'
                      : senha.payload.prioridade
                      ? 'Prioridade'
                      : 'Normal'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}

          {!SenhasChamadas[0] && (
            <Box className={styles.esquerdoBottonItem}>
              <Box className={styles.esqurdoConteudoSenha}>
                <Box
                  className={styles.titleContainer}
                  style={{
                    backgroundColor: '#4B5563',
                  }}
                >
                  <TicketIcon />
                  <Typography
                    variant="button"
                    className={isFullHd ? styles.title : styles.titleSmall}
                  >
                    Última Chamada
                  </Typography>
                </Box>
                <Box className={styles.containerSemSenha}>
                  <ClockIcon
                    styles={{
                      width: '70px',
                      height: '70px',
                      fontSize: '70px',
                      color: '#9CA3AF',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{
                      color: '#6B7280',
                      fontWeight: 700,
                    }}
                  >
                    Aguardando chamada anterior.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {!SenhasChamadas[1] && (
            <Box className={styles.esquerdoBottonItem}>
              <Box className={styles.esqurdoConteudoSenha}>
                <Box
                  className={styles.titleContainer}
                  style={{
                    backgroundColor: '#4B5563',
                  }}
                >
                  <TicketIcon />
                  <Typography
                    variant="button"
                    className={isFullHd ? styles.title : styles.titleSmall}
                  >
                    Penúltima Chamada
                  </Typography>
                </Box>
                <Box className={styles.containerSemSenha}>
                  <ClockIcon
                    styles={{
                      width: '70px',
                      height: '70px',
                      fontSize: '70px',
                      color: '#9CA3AF',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{
                      color: '#6B7280',
                      fontWeight: 700,
                    }}
                  >
                    Aguardando chamada anterior.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {!SenhasChamadas[2] && (
            <Box className={styles.esquerdoBottonItem}>
              <Box className={styles.esqurdoConteudoSenha}>
                <Box
                  className={styles.titleContainer}
                  style={{
                    backgroundColor: '#4B5563',
                  }}
                >
                  <TicketIcon />
                  <Typography
                    variant="button"
                    className={isFullHd ? styles.title : styles.titleSmall}
                  >
                    Antepenúltima Chamada
                  </Typography>
                </Box>
                <Box className={styles.containerSemSenha}>
                  <ClockIcon
                    styles={{
                      width: '70px',
                      height: '70px',
                      fontSize: '70px',
                      color: '#9CA3AF',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{
                      color: '#6B7280',
                      fontWeight: 700,
                    }}
                  >
                    Aguardando chamada anterior.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box className={styles.containerDireito}>
        <Box
          className={styles.titleContainerDireito}
          style={{
            backgroundColor: '#4B5563',
          }}
        >
          <TicketIcon />
          <Typography
            variant="button"
            className={isFullHd ? styles.title : styles.titleSmall}
          >
            Senhas Anteriores
          </Typography>
        </Box>

        {senhasParaMostrar && senhasParaMostrar.length > 0 ? (
          senhasParaMostrar.map((senha, index) => (
            <Box
              key={index}
              className={styles.direitoItem}
              style={{
                position: 'relative',
              }}
            >
              <Box className={styles.direitoConteudoSenha}>
                <Box
                  className={
                    isFullHd ? styles.direitoSenha : styles.direitoSenhaSmall
                  }
                >
                  <Typography
                    variant="h4"
                    className={
                      isFullHd
                        ? styles.titleSenhaBottonItem
                        : styles.titleSenhaBottonItemSmall
                    }
                  >
                    {extrairSenha(senha.payload.senha)}
                  </Typography>
                  <FiberManualRecordIcon className={styles.titleFiberSenha} />
                  <Typography
                    variant="h4"
                    className={
                      isFullHd
                        ? styles.titleSenhaBottonItem
                        : styles.titleSenhaBottonItemSmall
                    }
                  >
                    {extrairGuiche(senha.payload.guiche)}
                  </Typography>
                </Box>
                <Box className={styles.direitoUser}>
                  <PersonIcon
                    styles={{
                      height: '16px',
                    }}
                  />
                  <strong>{abreviarNome(senha.payload.nome)}</strong>
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Box
            className={styles.direitoSemItem}
            style={{
              backgroundColor: '#FFFFFF',
            }}
          >
            <Box className={styles.direitoConteudoSemSenha}>
              <Box className={styles.containerSemSenha}>
                <ClockIcon
                  styles={{
                    width: '70px',
                    height: '70px',
                    fontSize: '70px',
                    color: '#9CA3AF',
                  }}
                />
                <Typography
                  variant="h6"
                  style={{
                    color: '#6B7280',
                    fontWeight: 700,
                  }}
                >
                  Nenhuma senha chamada recentemente.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default PainelComSenha
