import { Agendamento } from '../interfaces/agendamento'
import { SchedulerProps } from '../Pages/SearchSchedules/components/SchedulerDialog'
import dateFormat from './dateFormat'

const REACT_APP_FALA_SALVADOR_URL_INTEGRACAO =
  process.env.REACT_APP_FALA_SALVADOR_URL_INTEGRACAO
const REACT_APP_KEY_FALA_SALVADOR = process.env.REACT_APP_KEY_FALA_SALVADOR

export const handlePrintAttendance = async (
  schedule: Agendamento | SchedulerProps,
) => {
  const id = schedule.id
  const state = {
    id,
  }
  const stateString = btoa(JSON.stringify(state))
  const encodedStateString = encodeURIComponent(stateString)

  window.open(`/imprimir_agendamento?state=${encodedStateString}`, '_blank')
}
export const handleOpenPrintAttendance = async (
  cpfAtendente: string,
  dateAt: string,
  cpfCidadao: string,
) => {
  const codUnid =
    JSON.parse(sessionStorage.getItem('gov_ticket_office') || '').unidade
      ?.identificador_externo || '0'
  const dataAge = dateAt
  const date = dataAge && dateFormat(dataAge as string)

  const url = `${REACT_APP_FALA_SALVADOR_URL_INTEGRACAO}&cpfAtendente=${cpfAtendente}&codUnid=${codUnid}&dataAge=${date}&cpfCidadao=${cpfCidadao}&chave=${REACT_APP_KEY_FALA_SALVADOR}`
  window.open(url, '_blank', 'noopener')
}
