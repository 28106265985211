import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import GetApp from '@material-ui/icons/GetApp'
import format from 'date-fns/format'
import React, { Fragment, useEffect, useRef } from 'react'

import { Atendimento } from '../../../../interfaces/atendimento'
import { useStateValue } from '../../../../providers/StateProvider'
import { getInitialsFromName } from '../../../../utils/nameFormat'
import { sanitizeHtml } from '../../../../utils/sanitizeHtml'
import { useStyles } from './style'

interface Props {
  chatService: Atendimento
}

export default function Chat({ chatService }: Props): JSX.Element {
  const classes = useStyles()
  const refElement = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo({
        behavior: 'smooth',
        top: refElement.current.scrollHeight,
      })
    }
  }, [chatService])

  const nameSplitTwo = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0].toLowerCase()} ${name.split(' ')[1][0]}.`
    }
    return name
  }

  const [{ user }] = useStateValue()

  return (
    <Box className={classes.main}>
      <Box
        {...({ ref: refElement } as any)}
        display="flex"
        maxWidth="1052px"
        width="100%"
        marginBottom="20px"
        flexDirection="column"
        height={chatService?.status === 'Em Andamento' ? '240px' : '480px'}
        style={{
          border: '1px solid #DADCE0',
          overflowY: 'auto',
        }}
        padding="20px"
      >
        <Box
          marginTop="30px"
          display="flex"
          flexDirection="column"
          alignItems={
            user?.cpf === chatService?.user?.username
              ? 'flex-end'
              : 'flex-start'
          }
        >
          <Typography
            style={
              user?.cpf === chatService?.user?.username
                ? { marginRight: 58 }
                : { marginLeft: 58 }
            }
          >
            {nameSplitTwo(chatService?.user?.first_name)}
          </Typography>
          <Box
            display="flex"
            flexDirection={
              user?.cpf === chatService?.user?.username ? 'row' : 'row-reverse'
            }
            alignItems="flex-end"
          >
            <Box
              className={
                user?.cpf === chatService?.user?.username
                  ? classes.boxChatSended
                  : classes.boxChatTarget
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(chatService?.conteudo),
                }}
              />
            </Box>
            <Avatar
              style={
                user?.cpf === chatService?.user?.username
                  ? {
                      width: 48,
                      height: 48,
                      marginLeft: 10,
                    }
                  : {
                      width: 48,
                      height: 48,
                      marginRight: 10,
                    }
              }
              title={chatService?.user?.first_name}
            >
              {getInitialsFromName(chatService?.user?.first_name)}
            </Avatar>
          </Box>
          <Typography
            className={classes.schedule}
            style={
              user?.cpf === chatService?.user?.username
                ? { marginRight: 58 }
                : { marginLeft: 58 }
            }
          >
            {/* {format(new Date(chatService?.created_at), "dd/MM/yyyy - HH:mm")} */}
          </Typography>
        </Box>
        {chatService?.anexos &&
          chatService?.anexos.length > 0 &&
          chatService?.anexos.map((anexo) => (
            <Box
              key={anexo?.id}
              marginTop="30px"
              display="flex"
              alignItems={
                user?.cpf === chatService?.user?.username
                  ? 'flex-end'
                  : 'flex-start'
              }
              flexDirection="column"
            >
              <Typography className={classes.name} style={{ marginRight: 58 }}>
                {nameSplitTwo(chatService?.user?.first_name)}
              </Typography>
              <Box display="flex" alignItems="flex-end">
                <Box
                  style={{ cursor: 'pointer' }}
                  onClick={(): void => {
                    ;(window as any).open(anexo.arquivo)
                  }}
                  className={classes.boxDownload}
                >
                  <IconButton className={classes.iconButton}>
                    <GetApp />
                  </IconButton>
                  <span style={{ margin: '0px 10px' }}>{anexo.titulo}</span>
                </Box>
                <Avatar
                  style={{ width: 48, height: 48, marginLeft: 10 }}
                  title={chatService?.user?.first_name}
                >
                  {getInitialsFromName(chatService?.user?.first_name)}
                </Avatar>
              </Box>
              <Typography
                className={classes.schedule}
                style={{ marginRight: 58 }}
              >
                {anexo?.created_at &&
                  format(new Date(anexo?.created_at), 'dd/MM/yyyy - HH:mm')}
              </Typography>
            </Box>
          ))}
        {chatService?.respostas?.length > 0 &&
          chatService?.respostas.map((resposta) => (
            <Fragment key={resposta.id}>
              <Box
                marginTop="30px"
                display="flex"
                flexDirection="column"
                alignItems={
                  user?.cpf === resposta?.user?.username
                    ? 'flex-end'
                    : 'flex-start'
                }
              >
                <Typography
                  className={classes.name}
                  style={
                    user?.cpf === resposta?.user?.username
                      ? { marginRight: 58 }
                      : { marginLeft: 58 }
                  }
                >
                  {nameSplitTwo(resposta?.user?.first_name)}
                </Typography>
                <Box
                  display="flex"
                  flexDirection={
                    user?.cpf === resposta?.user?.username
                      ? 'row'
                      : 'row-reverse'
                  }
                  alignItems="flex-end"
                >
                  <Box
                    className={
                      user?.cpf === resposta?.user?.username
                        ? classes.boxChatSended
                        : classes.boxChatTarget
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(resposta.conteudo),
                      }}
                    />
                  </Box>
                  <Avatar
                    style={
                      user?.cpf === resposta?.user?.username
                        ? {
                            width: 48,
                            height: 48,
                            marginLeft: 10,
                          }
                        : {
                            width: 48,
                            height: 48,
                            marginRight: 10,
                          }
                    }
                    title={resposta?.user?.first_name}
                  >
                    {getInitialsFromName(resposta?.user?.first_name)}
                  </Avatar>
                </Box>
                <Typography
                  className={classes.schedule}
                  style={
                    user?.cpf === resposta?.user?.username
                      ? { marginRight: 58 }
                      : { marginLeft: 58 }
                  }
                >
                  {format(new Date(resposta.created_at), 'dd/MM/yyyy - HH:mm')}
                </Typography>
              </Box>
              {resposta?.anexos &&
                resposta?.anexos?.length > 0 &&
                resposta.anexos.map((anexo) => (
                  <Box
                    key={anexo.id}
                    marginTop="30px"
                    display="flex"
                    alignItems={
                      user?.cpf === resposta?.user?.username
                        ? 'flex-end'
                        : 'flex-start'
                    }
                    flexDirection="column"
                  >
                    <Typography
                      className={classes.name}
                      style={
                        user?.cpf === resposta?.user?.username
                          ? { marginRight: 58 }
                          : { marginLeft: 68 }
                      }
                    >
                      {nameSplitTwo(resposta?.user?.first_name)}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="flex-end"
                      flexDirection={
                        user?.cpf === resposta?.user?.username
                          ? 'row'
                          : 'row-reverse'
                      }
                    >
                      <Box
                        style={{
                          cursor: 'pointer',
                          marginLeft:
                            user?.cpf === resposta?.user?.username ? 0 : 10,
                        }}
                        onClick={(): void => {
                          ;(window as any).open(anexo.arquivo)
                        }}
                        className={classes.boxDownload}
                        flexDirection={
                          user?.cpf === resposta?.user?.username
                            ? 'row'
                            : 'row-reverse'
                        }
                      >
                        <IconButton className={classes.iconButton}>
                          <GetApp />
                        </IconButton>
                        <span style={{ margin: '0px 10px' }}>
                          {anexo.titulo}
                        </span>
                      </Box>
                      <Avatar
                        style={{ width: 48, height: 48, marginLeft: 10 }}
                        title={resposta?.user?.first_name}
                      >
                        {getInitialsFromName(resposta?.user?.first_name)}
                      </Avatar>
                    </Box>
                    <Typography
                      className={classes.schedule}
                      style={{ marginRight: 58 }}
                    >
                      {format(new Date(anexo.created_at), 'dd/MM/yyyy - HH:mm')}
                    </Typography>
                  </Box>
                ))}
            </Fragment>
          ))}

        {chatService?.observacao_atendimento?.length > 0 && (
          <Box
            marginTop="30px"
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <Typography className={classes.name} style={{ marginRight: 58 }}>
              Observações:
            </Typography>
            {chatService?.observacao_atendimento.map((observation) => (
              <Fragment key={observation.id}>
                <Box display="flex" flexDirection="row" alignItems="flex-end">
                  <Box className={classes.boxChatSended}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(observation.conteudo),
                      }}
                    />
                  </Box>
                  <Avatar
                    style={{
                      width: 48,
                      height: 48,
                      marginLeft: 10,
                    }}
                    title={observation.user?.first_name}
                  >
                    {getInitialsFromName(observation.user?.first_name)}
                  </Avatar>
                </Box>
                <Typography
                  className={classes.schedule}
                  style={{ marginRight: 58 }}
                >
                  {format(
                    new Date(observation.created_at),
                    'dd/MM/yyyy - HH:mm',
                  )}
                </Typography>
              </Fragment>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}
