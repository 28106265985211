import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxChatSended: {
    borderRadius: '8px 8px 0px 8px',
    padding: '15px 14px',
    background: theme.palette.primary.main,
    maxWidth: 332,
    color: theme.palette.common.white,
    overflowWrap: 'anywhere',
  },
  boxChatTarget: {
    borderRadius: '8px 8px 8px 0px',
    padding: '15px 14px',
    background: '#DADCE0',
    maxWidth: 332,
    color: '#373F41',
    overflowWrap: 'anywhere',
  },
  boxDownload: {
    borderRadius: '8px 8px 0px 8px',
    background: '#fff',
    maxWidth: 332,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    color: '#373F41',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    textDecoration: 'underline',
    marginBottom: 6,
    textTransform: 'capitalize',
  },
  schedule: {
    color: '#737B7D',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    marginTop: 2,
  },
  iconButton: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

export default useStyles
