import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Pagination, PaginationItem } from '@material-ui/lab'
import React from 'react'

import { ES } from '../../interfaces/es'

interface Props {
  getSearch: (page: number) => void
  listSearch?: ES
  style?: any
}

const useStyles = makeStyles((_theme: Theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    '& .Mui-selected': {
      borderRadius: 0,
    },
  },
  paginationItem: {
    color: '#9E9E9E',
  },
  paginationSelected: {
    color: '#fff',
  },
}))

export default function PaginationService({
  getSearch,
  listSearch,
}: Props): JSX.Element {
  const classes = useStyles()
  return (
    <Box className={classes.main}>
      <Pagination
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{
              root: classes.paginationItem,
              selected: classes.paginationSelected,
            }}
          />
        )}
        shape="rounded"
        color="primary"
        count={listSearch?.total_pages || 1}
        page={listSearch?.current || 1}
        onChange={(_e, page) => getSearch(page)}
      />
    </Box>
  )
}
