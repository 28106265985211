import Badge from '@material-ui/core/Badge'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import { useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AddCircle from '@material-ui/icons/AddCircle'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowRight from '@material-ui/icons/ArrowRight'
import Block from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber'
import Menu from '@material-ui/icons/Menu'
import MoreVert from '@material-ui/icons/MoreVert'
import Print from '@material-ui/icons/Print'
import Today from '@material-ui/icons/Today'
import format from 'date-fns/format'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { TableRowCheckTime } from '../../Components'
import FormFilterAll from '../../Components/FormFilterAll'
import PaginationService from '../../Components/PaginationService'
import { Agendamento } from '../../interfaces/agendamento'
import { ES } from '../../interfaces/es'
import { useStateValue } from '../../providers/StateProvider'
import { searchAgendamentos } from '../../services/agendamento'
import { getUnidadeServices } from '../../services/servicos'
import { cpfMask } from '../../utils/cpfFormat'
import dateFormat, { getLocalDateString } from '../../utils/dateFormat'
import {
  handleOpenPrintAttendance,
  handlePrintAttendance,
} from '../../utils/handlePrintAttendance'
import DialogAntecipate from './components/DialogAnticipate'
import DialogAttend from './components/DialogAttend'
import DialogCancel from './components/DialogCancel'
import DialogNotAttend from './components/DialogNotAttend'
import ReScheduler from './components/ReScheduler'
import SchedulerDialog from './components/SchedulerDialog'
import { useStyles } from './style'
import { StyledTableCell, StyledTableRow } from './style'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: any
  value: any
}

export interface ServiceResults {
  id: number
  requisitos: string
  setor: number
  slug: string
  titulo: string
  agendamento_sem_cpf?: boolean
}
export interface Service extends ES {
  results: ServiceResults[]
}

interface SearchParamsProps {
  hora: string
  data: string
  servico: string
  tipo: string
  cpf: string
  status: string
  data_tipo: string
  data_inicio: string
  data_fim: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export default function SearchSchedules(): JSX.Element {
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const classes = useStyles()
  const theme = useTheme()
  const localDate = getLocalDateString(new Date())
  const [{ user, currentTicketOffice }] = useStateValue()
  const [value, setValue] = useState(0)
  const [loadingSchedule, setLoadingSchedule] = useState<boolean>(false)
  const [ScheduleOrganicShow, setScheduleOrganicShow] = useState<boolean>(false)
  const [servicesData, setServicesData] = useState<ServiceResults[]>()
  const [openNotAttend, setOpenNotAttend] = useState(false)
  const [openAttend, setOpenAttend] = useState(false)
  const [openReScheduleModal, setOpenReScheduleModal] = useState(false)
  const [openAttendanceCancel, setOpenAttendanceCancel] = useState(false)
  const matchesMobile = useMediaQuery('(max-width:555px)')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [scheduleAnticipated, setScheduleAnticipated] = useState<boolean>(false)

  const [currentSchedule, setCurrentSchedule] = useState<
    Agendamento | undefined
  >(undefined)

  const [, setCurrentTypeSchedule] = useState<string>()

  const [schedule, setSchedule] = useState<undefined | Agendamento[]>()
  const [showDetails, setShowDetails] = useState<undefined | string>()
  const [listSearchSchedule, setListSearchSchedule] = useState<ES | undefined>()
  const [allParams, setAllParams] = useState<any>(undefined)
  const matchesMd = useMediaQuery('(min-width:1184px)')
  const handleAgendamentosGetFromPage = async (page: number) => {
    searchParams.set('page', page.toString())
    await agendamentos(page, allParams)
    history.push({ search: searchParams.toString() })
  }
  async function agendamentos(page = 1, params?: any) {
    try {
      setLoadingSchedule(true)
      setSchedule(undefined)
      const token: string | null = sessionStorage.getItem(
        'gov_access_token_sso',
      )
      const guiche_id = JSON.parse(
        sessionStorage.getItem('gov_ticket_office') || '',
      ).id
      const par: any = {
        page,
        guiche_id,
        ...params,
      }
      const { data } = await searchAgendamentos(token || '', par)
      if (data) {
        setSchedule(data.results)
        setListSearchSchedule({
          count: data.count,
          next: data.next,
          previous: data.previous,
          current: data.current,
          total_pages: data.total_pages,
        })
      }
    } catch (error) {
    } finally {
      setLoadingSchedule(false)
    }
  }

  const handleOpenNotAttend = () => {
    setOpenNotAttend(true)
    handleCloseMenuOpton()
  }

  const handleOpenReScheduleModal = () => {
    setOpenReScheduleModal(true)
    handleCloseMenuOpton()
  }

  const handleAttend = () => {
    setOpenAttend(true)
    handleCloseMenuOpton()
  }

  const clearSearchParams = () => {
    searchParams.delete('hora')
    searchParams.delete('data_tipo')
    searchParams.delete('data_inicio')
    searchParams.delete('data_fim')
    searchParams.delete('data')
    searchParams.delete('servico')
    searchParams.delete('tipo')
    searchParams.delete('cpf')
    searchParams.delete('status')
  }

  const fillSearchParams = (params: SearchParamsProps) => {
    params.data_tipo && searchParams.set('data_tipo', params.data_tipo)
    params.data_inicio && searchParams.set('data_inicio', params.data_inicio)
    params.data_fim && searchParams.set('data_fim', params.data_fim)
    params.data && searchParams.set('data', params.data)
    params.hora && searchParams.set('hora', params.hora)
    params.servico && searchParams.set('servico', params.servico)
    params.tipo && searchParams.set('tipo', params.tipo)
    params.cpf && searchParams.set('cpf', params.cpf)
    params.status && searchParams.set('status', params.status)
  }

  const handleUpdateList = async (params?: any) => {
    clearSearchParams()
    setAllParams(params)
    await agendamentos(1, params)
    fillSearchParams(params)
    history.push({ search: searchParams.toString() })
  }

  useEffect(() => {
    if (currentTicketOffice) {
      let data: string | null = format(new Date(), 'yyyy-MM-dd')
      let dateInit, dateEnd
      const dateType = searchParams.get('data_tipo')
      const page = searchParams.get('page') ?? 1
      const hora = searchParams.get('hora')
      const servico = searchParams.get('servico')
      const status = searchParams.get('status')
      const tipo = searchParams.get('tipo')
      const cpf = searchParams.get('cpf')

      if (!dateType && searchParams?.toString()?.length === 0) {
        searchParams.set('data_tipo', 'Data')
        searchParams.set('data', data)
        history.push({ search: searchParams.toString() })
      }

      if (dateType === 'Data') {
        data = searchParams.get('data')
      }

      if (dateType === 'Período') {
        data = null
        dateInit = searchParams.get('data_inicio')
        dateEnd = searchParams.get('data_fim')
      }

      if (searchParams.get('page')) {
        data = null
      }

      setAllParams({
        data_tipo: dateType,
        data_inicio: dateInit,
        data_fim: dateEnd,
        data,
        hora,
        servico,
        status,
        tipo,
        cpf,
      })
      agendamentos(+page, {
        data,
        data_inicio: dateInit,
        data_fim: dateEnd,
        hora,
        servico,
        status,
        tipo,
        cpf,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTicketOffice])

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setValue(index)
    handleCloseMenu()
  }

  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null)

  const [anchorElOption, setAnchorElOption] =
    React.useState<HTMLButtonElement | null>(null)

  const handleCloseMenu = () => {
    setAnchorElMenu(null)
  }

  const handleCloseMenuOpton = () => {
    setAnchorElOption(null)
  }

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: 'menu',
  ) => {
    if (type === 'menu') {
      setAnchorElMenu(event.currentTarget)
    }
  }

  const handleOpenOptions = (
    event: React.MouseEvent<HTMLButtonElement>,
    schedule: any,
    type: string,
  ) => {
    setCurrentTypeSchedule(type)
    setCurrentSchedule(schedule)
    setAnchorElOption(event.currentTarget)
  }

  const getServices = async () => {
    const slug_unidade =
      currentTicketOffice?.setor?.unidade?.slug_unidade ||
      currentTicketOffice?.unidade?.slug_unidade

    if (!currentTicketOffice) return
    const { data } = await getUnidadeServices(slug_unidade)
    setServicesData(data)
  }

  const getHref = () => {
    const baseUrl = process.env.REACT_APP_PORTAL_URL || ''
    if (!!currentSchedule) {
      return `${baseUrl}/comprovante/agendamento/${currentSchedule.ticket}`
    }
    return '#'
  }

  const handleClearFilter = async () => {
    clearSearchParams()
    setAllParams(undefined)
    await agendamentos()
    history.replace({ search: '' })
  }
  useEffect(() => {
    getServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function checkTimeForSchedule(
    currentSchedule: Agendamento,
    timeCheck: 'withinTime' | 'beforeTime' | 'afterTime',
  ) {
    const now = new Date()

    const scheduleHour = parseInt(currentSchedule?.hora.split(':')[0])
    const scheduleMinutes = parseInt(currentSchedule?.hora.split(':')[1])

    const scheduleDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      scheduleHour,
      scheduleMinutes,
    )

    const scheduleDateMinus30 = new Date(scheduleDate.getTime())
    scheduleDateMinus30.setMinutes(scheduleDate.getMinutes() - 30)

    switch (timeCheck) {
      case 'withinTime':
        return now >= scheduleDateMinus30 && now <= scheduleDate
      case 'beforeTime':
        return now < scheduleDateMinus30
      case 'afterTime':
        return now > scheduleDate
      default:
        return false
    }
  }

  const handleWithAnticipateService = () => {
    setOpenModal(true)
  }

  const handleCloseDialogAnticipate = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Box className={classes.main}>
        <Popover
          open={Boolean(anchorElOption)}
          anchorEl={anchorElOption}
          onClose={handleCloseMenuOpton}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box className={classes.BoxMenuOptions}>
            {currentSchedule?.status === 'Aguardando' &&
              currentSchedule?.tipo !== 'Online' && (
                <>
                  {currentSchedule?.data === localDate && (
                    <>
                      {checkTimeForSchedule(currentSchedule, 'withinTime') ||
                      checkTimeForSchedule(currentSchedule, 'afterTime') ? (
                        <>
                          <MenuItem
                            className={classes.itemMenuOption}
                            onClick={handleAttend}
                          >
                            <Typography className={classes.textMenuOption}>
                              <CheckCircleOutlineIcon
                                className={classes.iconMenuOption}
                              />
                              COMPARECEU
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            className={classes.itemMenuOption}
                            onClick={handleOpenNotAttend}
                          >
                            <Typography className={classes.textMenuOption}>
                              <Block className={classes.iconMenuOption} />
                              NÃO COMPARECEU
                            </Typography>
                          </MenuItem>
                        </>
                      ) : (
                        ''
                      )}
                      {checkTimeForSchedule(currentSchedule, 'beforeTime') && (
                        <MenuItem
                          className={classes.itemMenuOption}
                          onClick={handleWithAnticipateService}
                        >
                          <Typography className={classes.textMenuOption}>
                            <AddCircleIcon className={classes.iconMenuOption} />
                            ANTECIPAR ATENDIMENTO
                          </Typography>
                        </MenuItem>
                      )}
                    </>
                  )}
                </>
              )}

            <MenuItem
              component="a"
              className={classes.itemMenuOption}
              target="_blank"
              href={getHref()}
              onClick={() => handleCloseMenuOpton()}
            >
              <Typography className={classes.textMenuOption}>
                <Print className={classes.iconMenuOption} /> COMPROVANTE
              </Typography>
            </MenuItem>
            <Divider />

            {!currentTicketOffice?.unidade?.habilitar_externo &&
              currentSchedule?.status === 'Compareceu' && (
                <>
                  <MenuItem
                    className={classes.itemMenuOption}
                    onClick={() => handlePrintAttendance(currentSchedule)}
                  >
                    <Typography className={classes.textMenuOption}>
                      <ConfirmationNumber className={classes.iconMenuOption} />{' '}
                      IMPRIMIR SENHA
                    </Typography>
                  </MenuItem>
                  <Divider />
                </>
              )}
            {currentTicketOffice?.unidade?.habilitar_externo &&
              currentSchedule?.status === 'Compareceu' && (
                <>
                  <MenuItem
                    className={classes.itemMenuOption}
                    onClick={() =>
                      handleOpenPrintAttendance(
                        user.cpf,
                        currentSchedule.data,
                        currentSchedule.cpf,
                      )
                    }
                  >
                    <Typography className={classes.textMenuOption}>
                      <ConfirmationNumber className={classes.iconMenuOption} />{' '}
                      ABRIR PAINEL SENHA
                    </Typography>
                  </MenuItem>
                  <Divider />
                </>
              )}

            {currentSchedule?.status === 'Aguardando' && (
              <>
                <MenuItem
                  className={classes.itemMenuOption}
                  onClick={handleOpenReScheduleModal}
                >
                  <Typography className={classes.textMenuOption}>
                    <Today className={classes.iconMenuOption} /> REAGENDAR
                  </Typography>
                </MenuItem>
              </>
            )}
          </Box>
        </Popover>

        <Box
          className={matchesMobile ? classes.headerTopAlt : classes.headerTop}
        >
          <Typography className={classes.textSchedules}>
            Lista de agendamentos
          </Typography>
          <Button
            className={classes.buttonAttendance}
            variant="contained"
            color="primary"
            onClick={() => {
              setScheduleOrganicShow(true)
              setScheduleAnticipated(false)
            }}
          >
            <AddCircle />
            <span style={{ marginLeft: 10 }}>Gerar Atendimento</span>
          </Button>
        </Box>
        <Box
          style={{
            overflowX: 'auto',
            overflowY: 'hidden',
            marginBottom: 25,
            width: '100%',
          }}
        >
          <Paper className={classes.root}>
            <FormFilterAll
              updateDataListFunction={handleUpdateList}
              clearDataListFunction={handleClearFilter}
            />
          </Paper>
        </Box>

        <Box
          style={{
            overflowX: 'auto',
            overflowY: 'hidden',
            background: '#FFF',
          }}
        >
          {matchesMd ? (
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="full width tabs example"
              style={{
                borderBottom: '1px solid #F7F8FC',
                borderRadius: 4,
                marginLeft: 24,
              }}
            >
              <Tab
                style={{ padding: 0, marginRight: 10, width: 200 }}
                label={
                  <>
                    <span className={classes.tabLabel}>
                      <span style={{ margin: 25 }}>Agendamentos</span>
                      <Badge
                        badgeContent={listSearchSchedule?.count || 0}
                        color="primary"
                      />
                    </span>
                  </>
                }
                {...a11yProps(0)}
              />
            </Tabs>
          ) : (
            <>
              <IconButton onClick={(e): void => handleClick(e, 'menu')}>
                <Menu />
              </IconButton>
              {value === 0 &&
                `Presenciais (${
                  listSearchSchedule ? listSearchSchedule?.count : 0
                } )`}
              <Popover
                open={Boolean(anchorElMenu)}
                anchorEl={anchorElMenu}
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box>
                  <Divider />

                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleChangeIndex(0)}
                  >
                    <Typography className={classes.textMenuItem}>
                      {`Presenciais (${
                        listSearchSchedule ? listSearchSchedule?.count : 0
                      })`}
                    </Typography>
                  </MenuItem>
                  <Divider />
                </Box>
              </Popover>
            </>
          )}

          <Box>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box
                style={{
                  overflowX: 'auto',
                  overflowY: 'hidden',
                }}
              >
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: '30%' }}>
                        NOME / CPF
                      </StyledTableCell>

                      <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                      <StyledTableCell align="left">TIPO</StyledTableCell>
                      <StyledTableCell align="left">STATUS</StyledTableCell>
                      <StyledTableCell align="left">SERVIÇO</StyledTableCell>
                      <StyledTableCell align="left">SENHA</StyledTableCell>
                      <StyledTableCell align="left">DEPENDENTE</StyledTableCell>
                      <StyledTableCell align="left">AÇÕES</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingSchedule && <CircularProgress />}
                    {schedule &&
                      schedule.map((rowsSchedule) => (
                        <Fragment key={rowsSchedule.ticket}>
                          <TableRowCheckTime
                            data={rowsSchedule.data}
                            hora={rowsSchedule.hora}
                            status={rowsSchedule.status}
                          >
                            <StyledTableCell component="th" scope="row">
                              {showDetails &&
                              showDetails === rowsSchedule.ticket ? (
                                <Button
                                  onClick={() => {
                                    setShowDetails(undefined)
                                  }}
                                >
                                  <ArrowDropDown />
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    setShowDetails(rowsSchedule.ticket)
                                  }}
                                >
                                  <ArrowRight />
                                </Button>
                              )}

                              {rowsSchedule?.user?.first_name ||
                                rowsSchedule?.nome}
                              <Box
                                style={{
                                  paddingLeft: 64,
                                  fontWeight: 700,
                                }}
                              >
                                {cpfMask(
                                  rowsSchedule.cpf ||
                                    rowsSchedule?.user?.username ||
                                    '',
                                )}
                              </Box>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {`${dateFormat(rowsSchedule.data)} ${
                                rowsSchedule.hora
                              }`}
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.status}
                              align="left"
                            >
                              {rowsSchedule.tipo}
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.status}
                              align="left"
                            >
                              {rowsSchedule.status}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rowsSchedule.servico.titulo}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rowsSchedule.senha_formatada || 'Não emitida'}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rowsSchedule.para_dependente ? 'Sim' : 'Não'}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <IconButton
                                onClick={(e): void =>
                                  handleOpenOptions(
                                    e,
                                    rowsSchedule,
                                    'presencial',
                                  )
                                }
                              >
                                <MoreVert color="primary" />
                              </IconButton>
                            </StyledTableCell>
                          </TableRowCheckTime>
                          {showDetails &&
                            showDetails === rowsSchedule.ticket && (
                              <>
                                <StyledTableRow
                                  key={rowsSchedule.ticket}
                                  style={{
                                    borderTopStyle: 'dotted',
                                    borderTopWidth: 1.4,
                                  }}
                                >
                                  {rowsSchedule?.protocolo && (
                                    <StyledTableCell
                                      colSpan={
                                        rowsSchedule?.protocolo_atendimento
                                          ? 4
                                          : 8
                                      }
                                    >
                                      <Typography
                                        className={classes.textTitleDetails}
                                        style={{ paddingLeft: 60 }}
                                      >
                                        PROTOCOLO AGENDAMENTO:
                                        <span
                                          className={classes.textValueDetails}
                                        >
                                          {rowsSchedule?.protocolo}
                                        </span>
                                      </Typography>
                                    </StyledTableCell>
                                  )}

                                  {rowsSchedule?.protocolo_atendimento && (
                                    <StyledTableCell colSpan={4}>
                                      <Typography
                                        className={classes.textTitleDetails}
                                        style={{ paddingLeft: 60 }}
                                      >
                                        PROTOCOLO ATENDIMENTO:
                                        <span
                                          className={classes.textValueDetails}
                                        >
                                          {rowsSchedule?.protocolo_atendimento}
                                        </span>
                                      </Typography>
                                    </StyledTableCell>
                                  )}
                                  {/* <StyledTableCell colSpan={6}>
                                    <Typography
                                      className={classes.textTitleDetails}
                                      style={{ paddingLeft: 60 }}
                                    >
                                      GUICHÊ:
                                      <span className={classes.textValueDetails}>
                                        {`${rowsSchedule?.guiche?.ordem || ""} - `}
                                        {rowsSchedule?.setor?.nome
                                          ? rowsSchedule?.setor?.nome : 'Geral'}
                                      </span>
                                    </Typography>
                                  </StyledTableCell> */}
                                </StyledTableRow>
                                <StyledTableRow
                                  key={rowsSchedule.ticket}
                                  style={{
                                    borderTopStyle: 'dotted',
                                    borderTopWidth: 1.4,
                                  }}
                                >
                                  {rowsSchedule?.para_dependente && (
                                    <StyledTableCell colSpan={6}>
                                      <Typography
                                        className={classes.textTitleDetails}
                                        style={{ paddingLeft: 60 }}
                                      >
                                        DADOS DO DEPENDENTE:
                                        <span
                                          className={classes.textValueDetails}
                                        >
                                          {rowsSchedule?.dependentes?.nome} -{' '}
                                          {cpfMask(
                                            rowsSchedule?.dependentes?.cpf ||
                                              '',
                                          )}
                                        </span>
                                      </Typography>
                                    </StyledTableCell>
                                  )}
                                </StyledTableRow>
                              </>
                            )}
                        </Fragment>
                      ))}

                    {!schedule?.length && !loadingSchedule && (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <h2>Nenhum agendamento encontrado</h2>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
              {!loadingSchedule && (
                <PaginationService
                  getSearch={handleAgendamentosGetFromPage}
                  listSearch={listSearchSchedule}
                />
              )}
            </TabPanel>
          </Box>
        </Box>
      </Box>
      {openModal && (
        <DialogAntecipate
          openDialog={openModal}
          handleClose={handleCloseDialogAnticipate}
          schedule={currentSchedule as Agendamento}
          onActionButton={(value) => {
            setScheduleOrganicShow(value)
            setScheduleAnticipated(value)
          }}
        />
      )}
      {openNotAttend && (
        <DialogNotAttend
          setOpenAttendanceCancel={setOpenNotAttend}
          openAttendanceCancel={openNotAttend}
          schedule={currentSchedule}
        />
      )}
      {openAttendanceCancel && (
        <DialogCancel
          setOpenAttendanceCancel={setOpenAttendanceCancel}
          openAttendanceCancel={openAttendanceCancel}
          schedule={currentSchedule}
        />
      )}
      {openAttend && (
        <DialogAttend
          setOpenAttendanceCancel={setOpenAttend}
          openAttendanceCancel={openAttend}
          schedule={currentSchedule}
        />
      )}
      {openReScheduleModal && (
        <ReScheduler
          openSchedulerDialog={openReScheduleModal}
          setOpenSchedulerDialog={setOpenReScheduleModal}
          schedule={currentSchedule}
        />
      )}
      {ScheduleOrganicShow && (
        <SchedulerDialog
          scheduleAnticipated={scheduleAnticipated}
          currentSchedule={currentSchedule as Agendamento}
          openSchedulerDialog={ScheduleOrganicShow}
          setOpenSchedulerDialog={setScheduleOrganicShow}
          service_data={servicesData as any}
          unidade_slug={
            currentTicketOffice?.setor?.unidade?.slug_unidade ||
            currentTicketOffice?.unidade?.slug_unidade
          }
        />
      )}
    </>
  )
}
