import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useStateValue } from '../../providers/StateProvider'
import dateFormat from '../../utils/dateFormat'
import { useStyles } from './style'

const REACT_APP_FALA_SALVADOR_URL_INTEGRACAO =
  process.env.REACT_APP_FALA_SALVADOR_URL_INTEGRACAO
const REACT_APP_KEY_FALA_SALVADOR = process.env.REACT_APP_KEY_FALA_SALVADOR

export default function FrameSchedule(): JSX.Element {
  const classes = useStyles()
  const history = useHistory()
  const [{ user, currentTicketOffice }] = useStateValue()
  const { dataAge, cpfCidadao } = useParams<{
    dataAge: string
    cpfCidadao: string
  }>()
  const [url, setUrl] = useState<string>('')

  useEffect(() => {
    const codUnid = currentTicketOffice?.unidade?.identificador_externo || '38'
    const cpfAtendente = user?.cpf
    const date = dataAge && dateFormat(dataAge as string)
    if (
      REACT_APP_FALA_SALVADOR_URL_INTEGRACAO &&
      REACT_APP_KEY_FALA_SALVADOR &&
      codUnid &&
      cpfAtendente &&
      date &&
      cpfCidadao
    ) {
      setUrl(
        `${REACT_APP_FALA_SALVADOR_URL_INTEGRACAO}&cpfAtendente=${cpfAtendente}&codUnid=${codUnid}&dataAge=${date}&cpfCidadao=${cpfCidadao}&chave=${REACT_APP_KEY_FALA_SALVADOR}`,
      )
    } else {
      handleBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTicketOffice, dataAge, cpfCidadao])

  const handleBack = useCallback(() => {
    let path = ``
    if (currentTicketOffice?.tipo) {
      switch (currentTicketOffice.tipo) {
        case 'Interno':
          path = '/agendamento_interno'
          break
        case 'Consulta':
          path = '/consulta_agendamento'
          break
        case 'Presencial':
          path = '/agendamento'
          break
        case 'Online':
          path = '/atendimento'
          break
        case 'Diario':
          path = '/atendimento_diario'
          break
        default:
          break
      }
    }
    history.push(path)
  }, [currentTicketOffice, history])

  return (
    <Box className={classes.main}>
      <Box className={classes.headerButton}>
        <Typography className={classes.textSchedules}>Fala Salvador</Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnBack}
          onClick={handleBack}
        >
          <ArrowBackIcon />
          Agendamentos
        </Button>
      </Box>

      <Box
        style={{
          overflow: 'auto',
        }}
      >
        {url.length && (
          <iframe src={url} title="Fala Salvador" className={classes.iframe} />
        )}
      </Box>
    </Box>
  )
}
