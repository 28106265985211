import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AttachFile from '@material-ui/icons/AttachFile'
import Send from '@material-ui/icons/Send'
import React, { useState } from 'react'

interface Props {
  sendMessage: (text: string) => Promise<any>
  showSendArquive: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    width: '100%',
  },
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  multilineColor: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    borderRadius: 0,
    border: '1px solid #DADCE0',
  },
}))

export default function SendMessage({
  sendMessage,
  showSendArquive,
}: Props): JSX.Element {
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:660px)')
  const [message, setMessage] = useState<string>('')

  const sendMessageForGuiche = (): void => {
    sendMessage(message)
    setMessage('')
  }

  return (
    <Box className={classes.main}>
      <Box display="flex" maxWidth="1052px" width="100%" marginBottom="30px">
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexDirection={matches ? 'row' : 'column'}
          padding={matches ? '0px' : '10px'}
        >
          <Box display="flex" width={'100%'}>
            <FormControl
              classes={{ root: classes.root }}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                value={message}
                onChange={(e): void => setMessage(e.target.value)}
                id="outlined-multiline-static"
                label="Digite sua mensagem"
                variant="outlined"
                onKeyDown={(e): void => {
                  if (e.keyCode === 13) sendMessageForGuiche()
                }}
                InputProps={{
                  className: classes.multilineColor,
                }}
              />
            </FormControl>
            <Tooltip title="Anexar arquivos" aria-label="Anexar arquivos">
              <IconButton
                onClick={showSendArquive}
                className={classes.iconButton}
              >
                <AttachFile />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Button
        style={{
          width: '100%',
          borderRadius: 0,
          marginTop: matches ? '0px' : '10px',
        }}
        variant="contained"
        color="primary"
        onClick={sendMessageForGuiche}
        title="Enviar"
      >
        ENVIAR MENSAGEM <Send style={{ marginLeft: '10px' }} />
      </Button>
    </Box>
  )
}
