import differenceInMinutes from 'date-fns/differenceInMinutes'
import parseISO from 'date-fns/parseISO'
import { utcToZonedTime } from 'date-fns-tz'

export const calculateCountDown = (DateTime: string) => {
  const targetDateTime = parseISO(DateTime)
  const targetDateTimeInTimeZone = utcToZonedTime(
    targetDateTime,
    'America/Sao_Paulo',
  )

  const currentDateTime = new Date()
  const currentDateTimeInTimeZone = utcToZonedTime(
    currentDateTime,
    'America/Sao_Paulo',
  )

  // console.log(currentDateTimeInTimeZone);

  const differenceInMinutesValues = differenceInMinutes(
    currentDateTimeInTimeZone,
    targetDateTimeInTimeZone,
  )

  return differenceInMinutesValues
}
