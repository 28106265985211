import React from 'react'

import { Props } from '../../types/icons'

const BankIcon = ({
  styles = { width: '18', height: '18', color: '#6B7280' },
}: Props) => {
  return (
    <svg
      style={styles}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 16.5H16.5M1.5 7.33333H16.5M3.16667 4L9 1.5L14.8333 4M2.33333 7.33333V16.5M15.6667 7.33333V16.5M5.66667 10.6667V13.1667M9 10.6667V13.1667M12.3333 10.6667V13.1667"
        stroke={styles.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BankIcon
